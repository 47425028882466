import Box from '@mui/material/Box';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import ErrorRounded from '@mui/icons-material/ErrorRounded';

import { useTheme } from '@mui/material/styles';

const TextField = (props: TextFieldProps) => {
    const theme = useTheme();
    return (
        <Box component={'div'} sx={{ ...style.box }}>
            <MuiTextField sx={{ ...style.textField }} {...props}></MuiTextField>
            {props.error ? (
                <ErrorRounded
                    sx={{
                        ...style.errorIcon,
                        color: `${theme.palette.error.main}`,
                    }}
                />
            ) : (
                ''
            )}
        </Box>
    );
};

export default TextField;

const style: any = {
    box: {
        display: 'inline',
    },
    textField: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
        },

        '& .MuiInputBase-root': {
            borderRadius: '0px !important',
        },
        '& input': {
            padding: '0.2em',
            margin: '0.2em',
        },
    },
    errorIcon: {
        position: 'relative',
        marginRight: '-1em',
        left: '-1.5em',
        top: '1rem',
    },
};
