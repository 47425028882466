import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// ui
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

// ui
import Button from 'components/base/Button';

// store
import {
    updateRoomThunk,
    selectIsLoading,
} from 'features/projects/state/projectState';

// types
import { AppDispatch } from 'store';

export interface DeleteRoomDialogProps {
    currentRoom: Room | undefined;
    roomId: string;
    isDeleteRoomDialogOpen: boolean;
    onCloseDeleteRoomDialog: () => void;
}

const DeleteRoomDialog = (props: DeleteRoomDialogProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();

    // properties

    const isLoadingProjectState = useSelector(selectIsLoading);

    // features

    const handleDeleteRoom = async () => {
        const updateData: Partial<Room> = { deleted: true };
        await dispatch(updateRoomThunk({ id: props.roomId, updateData })).catch(
            (error) => {
                console.error('Error deleting room in UI:', error);
            }
        );

        props.onCloseDeleteRoomDialog();
    };

    return (
        <Dialog
            open={props.isDeleteRoomDialogOpen}
            onClose={props.onCloseDeleteRoomDialog}
        >
            {/* Right top close button */}
            <IconButton
                aria-label="close"
                onClick={props.onCloseDeleteRoomDialog}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.main,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Title */}
            <Box component="div" sx={styles.titleBox}>
                <DialogTitle>
                    {t(`component.deleteRoomDialog.title`)}
                </DialogTitle>
            </Box>

            {/* Content */}
            <DialogContent>
                <Box component={'div'}>{props.currentRoom?.name}</Box>
            </DialogContent>

            {/* Actions */}
            <DialogActions sx={styles.dialogActions}>
                <Button
                    sx={styles.buttonCancel}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => props.onCloseDeleteRoomDialog()}
                >
                    {t(`cancel`)}
                </Button>
                <Button
                    sx={styles.buttonSubmit}
                    variant={'contained'}
                    type="submit"
                    onClick={handleDeleteRoom}
                    disabled={isLoadingProjectState}
                >
                    {isLoadingProjectState ? (
                        <CircularProgress size={20} />
                    ) : (
                        t(`submit`)
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteRoomDialog;

const styles = {
    titleBox: {
        marginRight: '2rem',
    },
    dialogActions: {
        justifyContent: 'flex-end',
        gap: '0.4rem',
        margin: '0px 0.5rem 0.5rem 0px',
    },
    buttonCancel: {
        height: '2.8rem',
    },
    buttonSubmit: {
        height: '2.8rem',
    },
};
