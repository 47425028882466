// Start of Selection
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PLACEHOLDER_IMAGE_URL } from 'config';

// mui
import { Box, Typography } from '@mui/material';

// ui
import ProductItemDetails from './ProductItemDetails';
import WarningBox from 'components/WarningBox';
import { colors } from 'theming/colors';

// types
import {
    ProductListViewCategorizationType,
    ProductListViewVariation,
} from './ProductListView';
import { CategorizationTypeConst } from './ProductListView';

interface ProductDisplayDetailedProps {
    allPopulatedVariations: ProductListViewVariation[] | undefined;
    pimProducts: PIMProduct[];
    categorizationType: ProductListViewCategorizationType;
}

const ProductDisplayDetailed = (props: ProductDisplayDetailedProps) => {
    const { t } = useTranslation();

    // properties

    const baseUrl = window.location.origin;
    const { allPopulatedVariations, pimProducts, categorizationType } = props;
    const isCategorizationByProduct =
        categorizationType.id === CategorizationTypeConst.BY_PRODUCT;

    if (isCategorizationByProduct) {
        return (
            <Box component="div" sx={styles.container}>
                <Box component="div" sx={styles.productItemBox}>
                    {/* Product item details - PIM product info */}
                    {pimProducts.length &&
                        (() => {
                            const seen = new Set<string>();
                            return (
                                pimProducts
                                    // filter out duplicates although BE also takes care of it
                                    .filter((product: PIMProduct) => {
                                        if (seen.has(product.id)) {
                                            return false;
                                        }
                                        seen.add(product.id);
                                        return true;
                                    })
                                    // sort by manufacturer a to z
                                    .sort((a: PIMProduct, b: PIMProduct) =>
                                        a.category?.value?.factory?.value?.name?.localeCompare(
                                            b.category?.value?.factory?.value
                                                ?.name
                                        )
                                    )
                                    .map(
                                        (
                                            product: PIMProduct,
                                            index: number,
                                            array: PIMProduct[]
                                        ) => {
                                            const isProductOffFromActiveProduction =
                                                product.inActiveProduction !==
                                                    undefined &&
                                                !product.inActiveProduction;
                                            const isProductOffFromActiveSale =
                                                product.inActiveSale !==
                                                    undefined &&
                                                !product.inActiveSale;
                                            return (
                                                <>
                                                    {(isProductOffFromActiveSale ||
                                                        isProductOffFromActiveProduction) && (
                                                        <WarningBox
                                                            text={
                                                                isProductOffFromActiveSale
                                                                    ? t(
                                                                          'views.productList.notInActiveSales'
                                                                      )
                                                                    : isProductOffFromActiveProduction
                                                                    ? t(
                                                                          'views.productList.notInActiveProduction'
                                                                      )
                                                                    : ''
                                                            }
                                                        />
                                                    )}
                                                    <ProductItemDetails
                                                        key={product.id}
                                                        product={product}
                                                        index={index}
                                                        array={array}
                                                        translate={t}
                                                        categorizationType={
                                                            categorizationType
                                                        }
                                                    />
                                                </>
                                            );
                                        }
                                    )
                            );
                        })()}
                </Box>
            </Box>
        );
    }

    return (
        <Box component="div" sx={styles.container}>
            {allPopulatedVariations
                // sort by room name a to z
                ?.sort((a, b) => a.roomName.localeCompare(b.roomName))
                ?.map((variation: ProductListViewVariation, index: number) => {
                    if (!variation || !variation.variationId) return null;

                    const snapshotURL =
                        variation.variationSnapshot?.sizes?.thumbnail?.url ||
                        variation.variationSnapshot?.sizes?.medium?.url ||
                        variation.variationSnapshot?.sizes?.large?.url ||
                        variation.variationSnapshot?.url ||
                        PLACEHOLDER_IMAGE_URL;

                    /* Product item */
                    return (
                        <Box
                            component="div"
                            key={variation.variationId}
                            sx={{
                                ...styles.productItemBox,
                                borderTop:
                                    index > 0
                                        ? '1px solid ' + colors.borderGrey
                                        : 'none',
                            }}
                        >
                            {/* Product item header - Room / variation info */}

                            <Box component="div" sx={styles.roomGroup}>
                                <Box
                                    component="div"
                                    sx={styles.roomGroupImageContainer}
                                >
                                    <Box
                                        component="div"
                                        sx={styles.roomGroupImage}
                                    >
                                        <img
                                            src={snapshotURL}
                                            alt={'thumbnail'}
                                            onError={(
                                                e: React.SyntheticEvent<
                                                    HTMLImageElement,
                                                    Event
                                                >
                                            ) => {
                                                e.currentTarget.onerror = null;
                                                e.currentTarget.src = `${baseUrl}${PLACEHOLDER_IMAGE_URL}`;
                                            }}
                                            style={{
                                                width: '6rem',
                                                height: '6rem',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box component="div" sx={styles.roomGroupTitle}>
                                    <Typography
                                        sx={{
                                            ...styles.roomGroupTitleText,
                                            fontWeight: '600',
                                        }}
                                    >
                                        {variation.roomName}
                                    </Typography>
                                    <Box component="div">
                                        <Typography
                                            sx={{
                                                ...styles.roomGroupTitleSubText,
                                            }}
                                        >
                                            {t(
                                                'views.productList.variationName'
                                            ).toUpperCase()}
                                            {': '}
                                            {variation.variationName}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            {/* Product item details - PIM product info */}
                            {pimProducts.length &&
                                pimProducts
                                    .filter((product: PIMProduct) =>
                                        variation.variationData.plates.some(
                                            (plate: any) =>
                                                plate.pimProductId ===
                                                product.id
                                        )
                                    )
                                    // sort by product name a to z
                                    .sort((a: PIMProduct, b: PIMProduct) =>
                                        a.name.localeCompare(b.name)
                                    )
                                    .map(
                                        (
                                            product: PIMProduct,
                                            index: number,
                                            array: PIMProduct[]
                                        ) => {
                                            const isProductOffFromActiveProduction =
                                                product.inActiveProduction !==
                                                    undefined &&
                                                !product.inActiveProduction;
                                            const isProductOffFromActiveSale =
                                                product.inActiveSale !==
                                                    undefined &&
                                                !product.inActiveSale;

                                            return (
                                                <>
                                                    {(isProductOffFromActiveSale ||
                                                        isProductOffFromActiveProduction) && (
                                                        <WarningBox
                                                            text={
                                                                isProductOffFromActiveSale
                                                                    ? t(
                                                                          'views.productList.notInActiveSales'
                                                                      )
                                                                    : isProductOffFromActiveProduction
                                                                    ? t(
                                                                          'views.productList.notInActiveProduction'
                                                                      )
                                                                    : ''
                                                            }
                                                        />
                                                    )}
                                                    <ProductItemDetails
                                                        key={product.id}
                                                        product={product}
                                                        index={index}
                                                        array={array}
                                                        translate={t}
                                                        categorizationType={
                                                            categorizationType
                                                        }
                                                    />
                                                </>
                                            );
                                        }
                                    )}
                        </Box>
                    );
                })}
        </Box>
    );
};

export default ProductDisplayDetailed;

const styles = {
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '0.5rem',

        boxSizing: 'border-box',
    },

    // Product item box which groups all the rest
    // about the variation and it's product
    productItemBox: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '1rem',

        boxSizing: 'border-box',
        padding: '2rem 5rem 0.5rem 5rem',
    },

    // -- Product item section header --
    roomGroup: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',

        gap: '1rem',
    },
    roomGroupImageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        maxWidth: '8rem',
        maxHeight: '8rem',
        aspectRatio: '1/ 1',
        borderRadius: '50%',
        overflow: 'hidden',
    },
    roomGroupImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    roomGroupTitle: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '0',
    },
    roomGroupTitleText: {
        fontSize: '1.2rem',
    },
    roomGroupTitleSubText: {
        fontSize: '0.8rem',
    },
};
