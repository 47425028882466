import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

/**
 * Tooltip Component
 *
 * This component customizes the MUI Tooltip by applying a styled wrapper.
 *
 * @param {TooltipProps} props - Additional properties passed to the Tooltip component like:
 * @param {string} title - The title of the tooltip.
 * @param {string} placement - The placement of the tooltip.
 *
 * @returns {JSX.Element} A styled Tooltip component with customized arrow and background colors.
 */
const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.secondary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: '0.8rem',
        padding: '0.6rem',
    },
}));

export default TooltipStyled;
