import { useTranslation } from 'react-i18next';

import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export interface ItemOptionsMenuProps extends MenuProps {
    variation: Variation | null;
    variationOpen: boolean;
    template: Variation | null;
    onClose: () => void;
    onDeleteVariation: (variationId: string) => void;
    onDeleteTemplate: (templateId: string) => void;
    onOpenRenameVariationDialog: () => void;
}

const ItemOptionsMenu = (props: ItemOptionsMenuProps) => {
    const { t } = useTranslation();

    const onRename = () => {
        // template data type is same as variation
        props.onClose();
        props.onOpenRenameVariationDialog();
    };

    const onDelete = () => {
        if (props.variationOpen) {
            // variation delete
            if (!props.variation) return;
            props.onClose();
            props.onDeleteVariation(props.variation.id);
        } else {
            // template delete
            if (!props.template) return;
            props.onClose();
            props.onDeleteTemplate(props.template.id);
        }
    };

    // pass on only properties recognized by Menu component
    const {
        variationOpen,
        onDeleteVariation,
        onDeleteTemplate,
        onOpenRenameVariationDialog,
        ...strippedProps
    } = props;

    return (
        <Menu {...strippedProps}>
            <MenuItem onClick={onRename}>{t(`rename`)}</MenuItem>
            <MenuItem onClick={onDelete}>{t(`delete`)}</MenuItem>
        </Menu>
    );
};

export default ItemOptionsMenu;
