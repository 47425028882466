const VispakUsersTransformer = {
    transformAuthResponse(data: any): AuthResponse | null {
        let loginResponse: AuthResponse | null = null;

        if (
            !data ||
            !data?.data ||
            !data?.data?.exp ||
            !data?.data?.message ||
            !data?.data?.token ||
            !data?.data?.user?.id
        )
            return null;

        loginResponse = {
            exp: data.data.exp,
            message: data.data.message,
            token: data.data.token,
            user: data.data.user,
        };

        return loginResponse;
    },

    transformUserDetailsResponse(data: any): VispakUser | null {
        let userDetails: VispakUser | null = null;

        if (!data || !data?.data || !data?.data?.user?.id) return null;

        userDetails = data.data.user;

        return userDetails;
    },
};

export default VispakUsersTransformer;
