import { SxProps, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Tag from './base/Tag';
import {
    VispakOfferStatus,
    VispakOfferStatusConst,
    VispakOfferStatusTagConst,
} from 'types/StatusTypes';

const statusMessages = {
    inProgress: 'component.statusTag.inProgress',
    published: 'component.statusTag.published',
    archived: 'component.statusTag.archived',
    deleted: 'component.statusTag.deleted',
};

export interface StatusTagProps {
    status: Partial<VispakOfferStatus>;
    sx?: SxProps;
}

const StatusTag = (props: StatusTagProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const getTagStatus = () => {
        if (props.status === VispakOfferStatusConst.PUBLIC)
            return VispakOfferStatusTagConst.PUBLISHED;

        return props.status;
    };

    const color = `${theme.extra.tags.background.color[getTagStatus()]}`;
    const textColor = `${theme.extra.tags.foreground.color[getTagStatus()]}`;
    const text = t(statusMessages[getTagStatus()]);

    return (
        <Tag
            color={color}
            textColor={textColor}
            sx={{ ...props.sx, fontWeight: 700, maxHeight: '25px' }}
        >
            {text}
        </Tag>
    );
};

export default StatusTag;
