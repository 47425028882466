import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';

const Alert = (props: MuiAlertProps) => {
    return (
        <MuiAlert variant="outlined" {...props}>
            {props.children}
        </MuiAlert>
    );
};

export default Alert;
