

const typography: object = {
    htmlFontSize: 15,
    fontFamily: 'Inter, sans-serif',
    fontSize: 15,
    lineHeight: 26,
    h1: {
        fontSize: '24px',
        lineHeight: 'auto',
    },
};

export default typography;
