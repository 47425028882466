import axios, { AxiosResponse } from 'axios';
import { API_URL, AUTH_COOKIE_NAME } from 'config';
import { CookieService } from 'services/cookie/CookieService';
import qs from 'qs';
export class PIMService {
    public url: string;
    public token: string | null;
    public locale: string;
    public config: {
        headers: { Authorization: string };
    };

    constructor() {
        this.url = `${API_URL}/api/flproducts`;
        this.token = CookieService.getCookieByName(AUTH_COOKIE_NAME);
        this.locale = 'et'; // BE supports 'et' atm.
        this.config = {
            headers: {
                Authorization: `Bearer ${this.token}`,
            },
        };
    }

    public async getProductById(id: string, depth = 0): Promise<PIMProduct> {
        try {
            const response: AxiosResponse<PIMProduct> = await axios.get(
                `${this.url}/${id}`,
                {
                    ...this.config,
                    params: { depth, locale: this.locale },
                }
            );
            return response.data;
        } catch (error: any) {
            throw new Error(`Product fetch failed: ${error?.message}`);
        }
    }

    public async getAllProducts({
        limit,
        page = 1,
        depth = 1,
    }: {
        limit: number;
        page?: number;
        depth?: number;
    }): Promise<PIMProductsResponse> {
        try {
            const response: AxiosResponse<PIMProductsResponse> =
                await axios.get(this.url, {
                    ...this.config,
                    params: {
                        limit,
                        page,
                        depth,
                        where: {
                            _status: {
                                equals: 'published',
                            },
                        },
                    },
                });
            return response.data;
        } catch (error: any) {
            throw new Error(`Products fetch failed: ${error?.message}`);
        }
    }

    public async getProductsByIds(ids: string[]): Promise<PIMProductsResponse> {
        try {
            // filter products by the given IDs
            const where = {
                _status: {
                    equals: 'published',
                },
                id: {
                    in: ids,
                },
            };

            const queryString = qs.stringify(
                {
                    where,
                    limit: ids.length,
                    depth: 2,
                },
                { encode: false }
            );

            const response: AxiosResponse<PIMProductsResponse> =
                await axios.get(`${this.url}?${queryString}`, this.config);

            return response.data;
        } catch (error: any) {
            throw new Error(
                `Fetch products by list of IDs failed: ${error?.message}`
            );
        }
    }

    public async getAllProductsBySearchTerm({
        limit,
        page = 1,
        depth = 2,
        searchTerm,
        collections,
        groups,
        subGroups,
        manufacturers,
        features,
    }: {
        limit: number;
        page?: number;
        depth?: number;
        searchTerm?: string;
        collections?: string[];
        groups?: string[];
        subGroups?: string[];
        manufacturers?: string[];
        features?: string[];
    }): Promise<PIMProductsResponse> {
        try {
            const where: any = {
                _status: {
                    equals: 'published',
                },
            };

            if (searchTerm) {
                where.name = {
                    contains: searchTerm,
                };
            }

            if (collections && collections.length > 0) {
                where.category = {
                    value: {
                        id: {
                            in: collections,
                        },
                    },
                };
            }

            if (groups && groups.length > 0) {
                where['category.value.group'] = {
                    value: {
                        id: {
                            in: groups,
                        },
                    },
                };
            }

            if (subGroups && subGroups.length > 0) {
                where['category.value.subGroup'] = {
                    value: {
                        id: {
                            in: subGroups,
                        },
                    },
                };
            }

            if (manufacturers && manufacturers.length > 0) {
                where.manufacturer = {
                    value: {
                        id: {
                            in: manufacturers,
                        },
                    },
                };
            }

            if (features && features.length > 0) {
                where.features = {
                    value: {
                        id: { in: features },
                    },
                };
            }

            // qs for readable url queries in browser
            // JSON.stringify gives url encoded (%) strings
            const queryString = qs.stringify(
                {
                    limit,
                    page,
                    depth,
                    where,
                },
                { encode: false }
            );

            const response: AxiosResponse<PIMProductsResponse> =
                await axios.get(`${this.url}?${queryString}`, this.config);
            return response.data;
        } catch (error: any) {
            throw new Error(`Products fetch failed: ${error?.message}`);
        }
    }

    public async getImage(url: string): Promise<Blob | null> {
        try {
            const response: AxiosResponse<Blob> = await axios.get(url, {
                headers: this.config.headers,
                responseType: 'blob', // Ensure the response is treated as a binary Blob
            });

            return response.data; // The image will be returned as a Blob
        } catch (error: any) {
            return null;
        }
    }

    // PIM categories (UI collections)
    public async getFilterCollections({
        limit,
        depth,
    }: {
        limit: number;
        depth: number;
    }): Promise<FilterCollectionsResponse> {
        try {
            const response: AxiosResponse<FilterCollectionsResponse> =
                await axios.get(`${API_URL}/api/flcategories`, {
                    ...this.config,
                    params: {
                        limit,
                        depth,
                        where: {
                            _status: {
                                equals: 'published',
                            },
                        },
                    },
                });
            return response.data;
        } catch (error: any) {
            throw new Error('Categories fetch failed:', error?.message);
        }
    }

    // PIM groups (UI categories)
    public async getFilterGroups({
        limit,
    }: {
        limit: number;
    }): Promise<FilterGroupsResponse> {
        try {
            const response: AxiosResponse<FilterGroupsResponse> =
                await axios.get(`${API_URL}/api/flgroups`, {
                    ...this.config,
                    params: { limit },
                });
            return response.data;
        } catch (error: any) {
            throw new Error('Categories fetch failed:', error?.message);
        }
    }

    // PIM subgroups (UI sub-categories)
    public async getFilterSubgroups({
        limit,
    }: {
        limit: number;
    }): Promise<FilterSubgroupsResponse> {
        try {
            const response: AxiosResponse<FilterSubgroupsResponse> =
                await axios.get(`${API_URL}/api/flsubgroups`, {
                    ...this.config,
                    params: { limit },
                });
            return response.data;
        } catch (error: any) {
            throw new Error('Subgroups fetch failed:', error?.message);
        }
    }

    public async getFilterManufacturers({
        limit,
    }: {
        limit: number;
    }): Promise<FilterManufacturersResponse> {
        try {
            const response: AxiosResponse<FilterManufacturersResponse> =
                await axios.get(`${API_URL}/api/flFactories`, {
                    ...this.config,
                    params: { limit },
                });
            return response.data;
        } catch (error: any) {
            throw new Error('Manufacturers fetch failed:', error?.message);
        }
    }

    public async getFilterAllFeatures({
        limit,
    }: {
        limit: number;
    }): Promise<FilterFeaturesResponse> {
        try {
            const response: AxiosResponse<FilterFeaturesResponse> =
                await axios.get(`${API_URL}/api/flfeatures`, {
                    ...this.config,
                    params: { limit },
                });
            return response.data;
        } catch (error: any) {
            throw new Error('Feature color fetch failed:', error?.message);
        }
    }
}
