import React from 'react';
import { colors } from 'theming/colors';
import { Box } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

interface WarningBoxProps {
    text: string;
}

const WarningBox = ({ text }: WarningBoxProps) => {
    return (
        <Box component="div" sx={styles.warningBox}>
            <Box component="div" sx={styles.warningIconBox}>
                <WarningAmberOutlinedIcon />
            </Box>
            <Box component="div" sx={styles.warningText}>
                {text}
            </Box>
        </Box>
    );
};

export default WarningBox;

const styles = {
    warningBox: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        gap: '1rem',

        boxSizing: 'border-box',
        border: `1px solid ${colors.fGrey}`,
        borderLeft: `4px solid ${colors.orange}`,

        backgroundColor: colors.lGrey,
        padding: '0.675rem',
    },
    warningIconBox: {
        color: colors.orange,
    },
    warningText: {
        fontSize: '1rem',
    },
};
