import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';

const Checkbox = (props: CheckboxProps) => {
    return <MuiCheckbox sx={{ ...style }} {...props} />;
};

export default Checkbox;

const style: any = {
    color: '#000000',
    '&.Mui-checked': { color: '#000000' },
};
