import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// ui
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// store
import { selectDesignCodeProject } from '../state/projectState';

const ITEM_HEIGHT = 48;

type RoomMenuOption = {
    id: number;
    name: string;
    category: string;
};

enum RoomMenuCategory {
    open = 'open',
    modify = 'modify',
    delete = 'delete',
    compare = 'compare',
    toggleStatus = 'toggleStatus',
}

interface RoomMenuProps {
    handleDeleteRoom: () => void;
    handleModifyRoom: () => void;
    handleCompareRoom: () => void;
    handleToggleRoomStatus: () => void;
}

export default function RoomMenu(props: RoomMenuProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // local state

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    // properties

    const designCodeProject = useSelector(selectDesignCodeProject);

    const open = Boolean(anchorEl);

    const options: RoomMenuOption[] = [
        {
            id: 2,
            name: t(`component.roomOptionsMenu.modify`),
            category: RoomMenuCategory.modify,
        },
        {
            id: 3,
            name: t(`component.roomOptionsMenu.delete`),
            category: RoomMenuCategory.delete,
        },
        {
            id: 4,
            name: t(`component.roomOptionsMenu.compare`),
            category: RoomMenuCategory.compare,
        },
        {
            id: 5,
            name: t(`component.roomOptionsMenu.toggleStatus`),
            category: RoomMenuCategory.toggleStatus,
        },
    ]
        // if design code session, show only compare option
        .filter((option) =>
            !designCodeProject
                ? true
                : option.category === RoomMenuCategory.compare
        )
        .sort((a, b) => a.id - b.id);

    // features

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (option?: RoomMenuOption | undefined) => {
        if (option?.category === RoomMenuCategory.modify)
            props.handleModifyRoom();
        else if (option?.category === RoomMenuCategory.delete)
            props.handleDeleteRoom();
        else if (option?.category === RoomMenuCategory.toggleStatus)
            props.handleToggleRoomStatus();
        else if (option?.category === RoomMenuCategory.compare)
            props.handleCompareRoom();

        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.id}
                        onClick={() => handleClose(option)}
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
