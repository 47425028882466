import typography from './typography';
import { colors } from './colors';
import { PaletteMode } from '@mui/material';

export const lightThemeProperties = {
    extra: {
        menu: {
            primary: colors.white,
            secondary: colors.lGrey,
        },
        text: {
            color: {
                primary: '#282d3b',
                secondary: colors.fGrey,
            },
        },
        tags: {
            background: {
                color: {
                    default: '#f5f5f5',
                    inProgress: '#faf3e6',
                    published: '#edf2e8',
                    archived: '#e6e6e6',
                    deleted: '#f5f5f5',
                },
            },
            foreground: {
                color: {
                    normal: '#282d3b',
                    inProgress: '#C58200',
                    published: '#447d12',
                    archived: colors.black,
                    deleted: '#282d3b',
                },
            },
        },
        toggle: {
            foreground: {
                color: {
                    off: colors.white,
                    on: colors.white,
                    disabled: '#999999',
                },
            },
            background: {
                color: {
                    off: '#8d8d8d',
                    on: '#198038',
                    disabled: '#c6c6c6',
                },
            },
        },
    },
    palette: {
        mode: 'light' as PaletteMode,
        error: {
            main: '#341203',
            contrastText: '#fff',
        },
        primary: {
            main: colors.orange,
            light: '#fe9057',
            dark: '#ce5017',
            contrastText: colors.white,
        },
        secondary: {
            main: '#341203',
            light: '#543233',
            dark: '#140000',
            contrastText: '#fff',
        },
    },
    typography: typography,
};
