import React, {
    forwardRef,
    useState,
    useImperativeHandle,
    useEffect,
} from 'react';
import { Grid, OrbitControls, Plane } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useSelector, useDispatch } from 'react-redux';
import {
    generateCurrentTimestampString,
    generateHashFromTimestamp,
} from 'utils';
import DirectionalLight from './DirectionalLight';
import { selectVariationSelected } from './moodboardState';
import Plate from './Plate';
import { PlateType } from './types';
import { MediaService } from 'services/MediaService';
import { updateVariation } from 'services/projectServices';
import { setProjectLoadingState } from 'features/projects/state/projectState';
import { AppDispatch } from 'store';

const TopDownScene = forwardRef<
    {
        takeScreenshot: () => void;
        uploadScreenshot: () => Promise<Variation | null>;
    },
    {
        gridActive?: boolean;
        plates: PlateType[];
        deletePlate: (index: string) => void;
    }
>(({ gridActive = true, plates, deletePlate }, ref) => {
    const [focusedPlate, setFocusedPlate] = useState<number | string | null>(
        null
    );
    const dispatch: AppDispatch = useDispatch();
    // const mediaService = new MediaService();
    const [userTookScreenshot, setUserTookScreenshot] =
        useState<boolean>(false);

    const handleFocus = (index: number | string | null) => {
        setFocusedPlate(index);
    };

    const selectedVariation: Variation | null = useSelector(
        selectVariationSelected
    );

    const takeScreenshot = () => {
        setFocusedPlate(null);

        // Take the screenshot
        gl.render(scene, camera);
        const screenshot = gl.domElement.toDataURL('image/png');

        // Create a link element, set its href to the cropped screenshot data URL, and trigger a download
        const link = document.createElement('a');
        link.href = screenshot;
        link.download =
            'moodboard_screenshot_' + generateHashFromTimestamp() + '.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const uploadScreenshot = async (): Promise<Variation | null> => {
        const cropWidth = 1400;
        const cropHeight = 1200;
        setFocusedPlate(null);

        // Take the screenshot
        gl?.render(scene, camera);
        const screenshot = gl?.domElement.toDataURL('image/jpeg', 0.9);

        if (!screenshot || !selectedVariation) {
            return null;
        }

        // create and load image synchronously
        const img = new Image();
        await new Promise((resolve) => {
            img.onload = resolve;
            img.src = screenshot;
        });

        const mediaService = new MediaService();

        // create a canvas to crop the image
        const canvas = document.createElement('canvas');

        // get original dimensions
        const originalWidth = img.width;
        const originalHeight = img.height;

        // calculate aspect ratios
        const originalAspectRatio = originalWidth / originalHeight;
        const cropAspectRatio = cropWidth / cropHeight;

        let drawWidth, drawHeight, offsetX, offsetY;

        if (originalAspectRatio > cropAspectRatio) {
            drawHeight = cropHeight;
            drawWidth = drawHeight * originalAspectRatio;
            offsetX = (drawWidth - cropWidth) / 2;
            offsetY = 0;
        } else {
            drawWidth = cropWidth;
            drawHeight = drawWidth / originalAspectRatio;
            offsetX = 0;
            offsetY = (drawHeight - cropHeight) / 2;
        }

        canvas.width = cropWidth;
        canvas.height = cropHeight;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, -offsetX, -offsetY, drawWidth, drawHeight);

        const currentDate = generateCurrentTimestampString();

        // convert the cropped canvas to a data URL
        const croppedScreenshot = canvas.toDataURL('image/jpeg', 0.9);
        const blob = await (await fetch(croppedScreenshot))?.blob();
        const file = new File(
            [blob],
            'moodboard_screenshot_' +
                generateHashFromTimestamp() +
                '_' +
                currentDate +
                '.jpg',
            { type: 'image/jpeg' }
        );

        const formData = new FormData();
        formData.append('file', file);

        dispatch(setProjectLoadingState(true));

        try {
            // upload
            const savedScreenshot: MediaDoc = await mediaService.saveScreenshot(
                formData
            );

            if (savedScreenshot && selectedVariation?.id) {
                const previousSnapshotsIds =
                    selectedVariation?.snapshots?.map(
                        (snapshot: Snapshot) => snapshot.id
                    ) || [];

                // add snapshot to current variation
                const updatedVariation = await updateVariation(
                    selectedVariation.id,
                    {
                        snapshots: [
                            ...previousSnapshotsIds,
                            savedScreenshot.id,
                        ],
                    }
                );

                return updatedVariation;
            }
        } finally {
            dispatch(setProjectLoadingState(false));
        }

        return null;
    };

    useEffect(() => {
        if (userTookScreenshot && focusedPlate === null) {
            takeScreenshot();
            setUserTookScreenshot(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedPlate, userTookScreenshot]);

    useEffect(() => {
        // Reset the focused plate when new variation is changed.
        handleFocus(null);
    }, [selectedVariation]);

    const { scene, camera, gl } = useThree();

    // 'ref' is the ref from the parent component (Moodboard.tsx)
    // here in the 'useImperativeHandle' new functions are declared
    // and forwarded to the parent
    useImperativeHandle(ref, () => ({
        takeScreenshot() {
            setUserTookScreenshot(true);
            setFocusedPlate(null);
        },

        async uploadScreenshot() {
            setFocusedPlate(null);
            // Wait for next frame to ensure state update has been processed
            await new Promise((resolve) => requestAnimationFrame(resolve));
            return uploadScreenshot();
        },
    }));

    return (
        <>
            <OrbitControls enablePan enableZoom enableRotate={false} />

            {gridActive && (
                <Grid
                    position={[0, -0.01, 0]}
                    args={[100, 100]}
                    cellSize={0}
                    sectionSize={0.1}
                    sectionColor="#d3d3d3"
                    cellColor="#d3d3d3"
                    infiniteGrid
                />
            )}

            <ambientLight intensity={1} />
            <DirectionalLight />

            {plates.map((plate) => (
                <Plate
                    key={plate.id}
                    focused={focusedPlate === plate.id}
                    onPress={() => handleFocus(plate.id)}
                    plateData={plate}
                    onDelete={() => {
                        deletePlate(plate.id);
                        handleFocus(null);
                    }}
                />
            ))}

            <Plane
                args={[1000, 1000]}
                rotation={[-Math.PI / 2, 0, 0]}
                position={[0, -0.1, 0]}
                receiveShadow
                onDoubleClick={() => handleFocus(null)}
            >
                <meshStandardMaterial color="white" />
            </Plane>
        </>
    );
});

export default TopDownScene;
