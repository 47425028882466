import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

// ui
import TextField from 'components/base/TextField';
import Button from 'components/base/Button';

// store
import {
    createSpacialSolutionThunk,
    selectIsLoading,
} from 'features/projects/state/projectState';
import { AppDispatch } from 'store';

export interface NewSolutionDialogProps {
    open: boolean;
    onClose: () => void;
}

const NewSolutionDialog = (props: NewSolutionDialogProps) => {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const theme = useTheme();

    // local state

    const [solutionName, setSolutionName] = useState('');

    // properties

    const isLoadingProjectState = useSelector(selectIsLoading);

    // features

    const close = () => {
        props.onClose();
        setSolutionName('');
    };

    const onCreateNewSolution = async () => {
        if (solutionName !== '') {
            await dispatch(createSpacialSolutionThunk(solutionName)).catch(
                (error) => {
                    console.error('Error creating new solution in UI:', error);
                }
            );
            close();
        }
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            {/* Right top close button */}
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.main,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Title */}
            <Box component="div" sx={styles.titleBox}>
                <DialogTitle>
                    {t(`component.newSolutionDialog.title`)}
                </DialogTitle>
            </Box>

            {/* Content */}
            <DialogContent>
                <TextField
                    placeholder={t(`component.newSolutionDialog.solutionName`)}
                    fullWidth
                    variant="standard"
                    onChange={(event) => {
                        setSolutionName(event.target.value);
                    }}
                ></TextField>
            </DialogContent>

            {/* Actions */}
            <DialogActions sx={styles.dialogActions}>
                <Button
                    sx={styles.buttonCancel}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={close}
                >
                    {t(`cancel`)}
                </Button>
                <Button
                    sx={styles.buttonSubmit}
                    variant={'contained'}
                    type="submit"
                    onClick={onCreateNewSolution}
                    disabled={solutionName === '' || isLoadingProjectState}
                >
                    {isLoadingProjectState ? (
                        <CircularProgress size={20} />
                    ) : (
                        t(`submit`)
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewSolutionDialog;

const styles = {
    titleBox: {
        marginRight: '2rem',
    },
    dialogActions: {
        justifyContent: 'flex-end',
        gap: '0.4rem',
        margin: '0px 0.5rem 0.5rem 0px',
    },
    buttonCancel: {
        height: '2.8rem',
    },
    buttonSubmit: {
        height: '2.8rem',
    },
};
