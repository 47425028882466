import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentLanguage, setCurrentLanguage } from '../features/localization/state/localizationState';
import { AppDispatch } from 'store';
import i18next from 'i18next';

export interface LanguageSelectorProps {
    languageCode: string;
    languages: any;
}

const LanguageSelector = (props: LanguageSelectorProps) => {
    const lang = useSelector(selectCurrentLanguage);
    const dispatch: AppDispatch = useDispatch();

    const handleChange = (event: SelectChangeEvent) => {
        dispatch(setCurrentLanguage(event.target.value));
        i18next.changeLanguage(event.target.value);
    };
    return (
        <Select
            sx={{ ...style.select }}
            id="lang-select"
            value={lang}
            onChange={handleChange}
        >
            <MenuItem value={'et-EE'}>EST</MenuItem>
            <MenuItem value={'en-US'}>ENG</MenuItem>
        </Select>
    );
};

export default LanguageSelector;

const style: any = {
    select: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important',
        },
    },
};
