import {
    createAsyncThunk,
    createSlice,
    createSelector,
    current,
    PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from 'store';
import { AxiosError } from 'axios';
import { DEFAULT_PAGINATION_LIMIT } from 'config';

// services
import {
    createRoom,
    createSpacialSolution,
    createVariation,
    deleteRoom,
    updateRoom,
    updateSpacialSolution,
    updateSpacialSolutionRooms,
    updateRoomVariations,
    updateVariation,
} from 'services/projectServices';
import { VisualOfferingsService } from 'services/visualOfferings/VisualOfferingsService';

// types
import { MoodboardState } from 'features/moodboard/moodboardState';
import { AppDispatch } from 'store';
import {
    VispakOfferStatusConst,
    VispakRoomStatusConst,
} from 'types/StatusTypes';

export interface ProjectState {
    projects: PaginatedVisualOfferings;
    selectedProject: VisualOffering | undefined;
    globalModals: GlobalModalState;
    compareWithVariation: string | null;
    designCodeProject: VisualOffering | undefined;
    isLoading: boolean;
}

export interface GlobalModalData {
    open: boolean;
    id: string | null;
}

export interface GlobalModalState {
    offer: {
        sendOffer: GlobalModalData;
    };
}

const initialState: ProjectState = {
    projects: {
        docs: [],
        totalDocs: 0,
        limit: 0,
        totalPages: 0,
        page: 0,
        pagingCounter: 0,
        hasPrevPage: false,
        hasNextPage: false,
        prevPage: null,
        nextPage: null,
    },
    selectedProject: undefined,
    globalModals: {
        offer: {
            sendOffer: { open: false, id: null },
        },
    },
    compareWithVariation: null,
    designCodeProject: undefined,
    isLoading: false,
};

const refetchProjects = async (dispatch: AppDispatch) => {
    await dispatch(fetchProjects({ page: 1, limit: DEFAULT_PAGINATION_LIMIT }));
};

export const createSpacialSolutionThunk = createAsyncThunk<
    SpacialSolution, // Return type of the payload
    string, // Type of the argument (none in this case)
    { rejectValue: string } // Error type
>(
    'projects/createSpacialSolutionThunk',
    async (name, { rejectWithValue, getState }) => {
        try {
            // first create spacial solution
            const response = await createSpacialSolution(name);

            // then update visual offer with the newly created solution
            if (response) {
                const state = getState() as RootState;

                if (state?.project?.selectedProject?.id) {
                    const spacialSolutions = state.project.projects.docs.find(
                        (project) =>
                            project.id === state.project.selectedProject?.id
                    )?.spacialSolutions;

                    const updatedSpacialSolutionIds = spacialSolutions
                        ? [
                              ...spacialSolutions.map(
                                  (solution) => solution.id
                              ),
                              response.id,
                          ]
                        : [response.id];

                    // update visual offer (parent) to add new spacial solution

                    // TODO BE: if it fails then the previously created spacial solution
                    // hangs without relation in db and no UI is put to use
                    const visualOfferingsService = new VisualOfferingsService();
                    const visualOfferId = state.project.selectedProject.id;
                    const updateData = {
                        spacialSolutions: updatedSpacialSolutionIds,
                    };

                    await visualOfferingsService.updateVisualOffering(
                        visualOfferId,
                        updateData
                    );
                }
            }
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error?.message);
        }
    }
);

export const updateSpacialSolutionThunk = createAsyncThunk<
    SpacialSolution, // Return type
    { id: string; updateData: Partial<SpacialSolution> }, // Argument type (id and updateData)
    { rejectValue: string } // Error type
>(
    'projects/updateSpacialSolutionThunk',
    async ({ id, updateData }, { rejectWithValue }) => {
        try {
            const response = await updateSpacialSolution(id, updateData);
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.message);
        }
    }
);

export const createVariationThunk = createAsyncThunk<
    Variation, // Return type
    {
        name: string;
        roomId: string;
        isTemplate: boolean;
        data: Partial<MoodboardState>;
        products?: string[]; // PIM product ids
    }, // Argument type (name and roomId)
    { rejectValue: string } // Error type
>(
    'variations/createVariationThunk',
    async (
        { name, roomId, isTemplate, data, products },
        { rejectWithValue, getState }
    ) => {
        try {
            const state = getState() as RootState;

            if (!state?.project?.projects?.docs?.length) {
                // start requests only if store is populated, i.e. page is not refreshed
                throw new Error('Store is empty');
            }

            // First create variation
            const response = await createVariation(
                name,
                isTemplate,
                data,
                products ?? []
            );

            // Then update room to add the new variation
            if (response) {
                if (state?.project?.selectedProject?.id) {
                    const spacialSolutions = state.project.projects.docs.find(
                        (project) =>
                            project.id === state.project.selectedProject?.id
                    )?.spacialSolutions;

                    const foundRoom = spacialSolutions
                        ?.flatMap((solution) => solution.rooms)
                        .find((room) => room?.id === roomId);

                    const roomVariationIds = foundRoom?.variations
                        ? [
                              ...foundRoom.variations.map(
                                  (variation) => variation.id
                              ),
                              response.id,
                          ]
                        : [response.id];

                    // Sync with room in db
                    if (foundRoom?.id) {
                        await updateRoomVariations(
                            foundRoom.id,
                            roomVariationIds
                        );
                    }
                }
            }
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.message);
        }
    }
);

export const updateVariationThunk = createAsyncThunk<
    Variation, // Return type
    { id: string; updateData: Partial<Variation> }, // Argument type (id and updateData)
    { rejectValue: string } // Error type
>(
    'variations/updateVariationThunk',
    async ({ id, updateData }, { rejectWithValue }) => {
        try {
            const response = await updateVariation(id, updateData);
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.message);
        }
    }
);

export const createRoomThunk = createAsyncThunk<
    Room, // Return type
    { name: string; solutionId: string }, // Argument type (name)
    { rejectValue: string } // Error type
>(
    'rooms/createRoomThunk',
    async ({ name, solutionId }, { rejectWithValue, getState }) => {
        try {
            // first create room
            const response = await createRoom(name);

            // then update spacial solution to add the new room
            if (response) {
                const state = getState() as RootState;

                if (state?.project?.selectedProject?.id) {
                    const spacialSolutions = state.project.projects.docs.find(
                        (project) =>
                            project.id === state.project.selectedProject?.id
                    )?.spacialSolutions;

                    const foundSpacialSolution = spacialSolutions?.find(
                        (solution) => solution.id === solutionId
                    );

                    const spacialSolutionRoomIds = foundSpacialSolution?.rooms
                        ? [
                              ...foundSpacialSolution?.rooms.map((room) =>
                                  typeof room === 'string' ? room : room.id
                              ),
                              response.id,
                          ]
                        : [response.id];

                    // sync with spacial solution in db also
                    if (foundSpacialSolution?.id) {
                        await updateSpacialSolutionRooms(
                            foundSpacialSolution.id,
                            spacialSolutionRoomIds
                        );
                    }
                }
            }
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.message);
        }
    }
);

export const deleteRoomThunk = createAsyncThunk<
    Room, // Return type
    string, // Argument type (id)
    { rejectValue: string } // Error type
>('rooms/deleteRoomThunk', async (id, { rejectWithValue }) => {
    try {
        const response = await deleteRoom(id);
        return response;
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error.message);
    }
});

export const updateRoomThunk = createAsyncThunk<
    Room, // Return type
    { id: string; updateData: Partial<Room> }, // Argument type (id and updateData)
    { rejectValue: string } // Error type
>('rooms/updateRoomThunk', async ({ id, updateData }, { rejectWithValue }) => {
    try {
        const response = await updateRoom(id, updateData);
        return response;
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error.message);
    }
});

export interface FetchProjectsParams {
    page: number;
    limit: number;
    status?: VispakOfferStatusConst;
    hasReminderDate?: boolean;
}

export const fetchProjects = createAsyncThunk<
    PaginatedVisualOfferings, // Return type of the payload
    FetchProjectsParams,
    { rejectValue: string } // Error type
>(
    'projects/fetchProjects',
    async (params: FetchProjectsParams, { rejectWithValue }) => {
        try {
            const visualOfferingsService = new VisualOfferingsService();
            const response =
                await visualOfferingsService.getVisualOfferingsWithPaginationLimit(
                    params
                );
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.message);
        }
    }
);

export const fetchProjectById = createAsyncThunk<
    VisualOffering,
    string,
    { rejectValue: string }
>('projects/fetchProjectById', async (id, { rejectWithValue }) => {
    try {
        const visualOfferingsService = new VisualOfferingsService();
        const response = await visualOfferingsService.getVisualOfferingById(id);
        return response;
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error.message);
    }
});

export const createProject = createAsyncThunk<
    VisualOffering, // Return type of the payload
    { data: VisualOfferingSaveData; comment?: string }, // Type of the argument
    { rejectValue: string } // Error type
>(
    'projects/createProject',
    async ({ data, comment }, { rejectWithValue, dispatch }) => {
        try {
            const visualOfferingsService = new VisualOfferingsService();
            const response = await visualOfferingsService.createVisualOffering(
                data,
                comment
            );

            await refetchProjects(dispatch as AppDispatch);

            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.message);
        }
    }
);

export const deleteProject = createAsyncThunk<
    VisualOffering, // Return type of the payload (ID of the deleted project)
    { id: string }, // Type of the argument
    { rejectValue: string } // Error type
>('projects/deleteProject', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const visualOfferingsService = new VisualOfferingsService();
        const response = await visualOfferingsService.updateVisualOffering(id, {
            status: VispakOfferStatusConst.DELETED,
        });

        await refetchProjects(dispatch as AppDispatch);

        return response; // Return the ID of the deleted project
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error.message);
    }
});

export const updateProject = createAsyncThunk<
    VisualOffering, // Return type of the payload
    { id: string; updateData: Partial<VisualOffering> }, // Type of the argument
    { rejectValue: string } // Error type
>('projects/updateProject', async ({ id, updateData }, { rejectWithValue }) => {
    try {
        const visualOfferingsService = new VisualOfferingsService();
        const response = await visualOfferingsService.updateVisualOffering(
            id,
            updateData
        );
        return response;
    } catch (err) {
        const error = err as AxiosError;
        return rejectWithValue(error.message);
    }
});

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setSelectedProject(
            state,
            action: PayloadAction<VisualOffering | undefined>
        ) {
            if (action.payload === undefined) {
                state.selectedProject = undefined;
            } else {
                state.selectedProject = action.payload;
            }
        },
        // login with design code
        setDesignCodeProject(
            state,
            action: PayloadAction<VisualOffering | null>
        ) {
            if (action.payload) {
                state.designCodeProject = action.payload;
            } else {
                state.designCodeProject = undefined;
            }
        },
        setGlobalModalOfferSend(state, action: PayloadAction<GlobalModalData>) {
            state.globalModals.offer.sendOffer = action.payload;
        },
        setProjectLoadingState(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setCompareWithVariation(
            state,
            action: PayloadAction<string | null> // variation id
        ) {
            state.compareWithVariation = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            // Fetch Projects
            .addCase(fetchProjects.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchProjects.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                fetchProjects.fulfilled,
                (state, action: PayloadAction<PaginatedVisualOfferings>) => {
                    state.projects = action.payload;
                    state.isLoading = false;
                }
            )

            // Fetch Project by ID
            .addCase(fetchProjectById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchProjectById.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                fetchProjectById.fulfilled,
                (state, action: PayloadAction<VisualOffering>) => {
                    state.selectedProject = action.payload;

                    // also update the project in the projects list
                    const projectIndex = state.projects.docs.findIndex(
                        (project) => project.id === action.payload.id
                    );
                    if (projectIndex !== -1) {
                        state.projects.docs[projectIndex] = action.payload;
                    }

                    state.isLoading = false;
                }
            )

            // Create Project
            .addCase(createProject.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createProject.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                createProject.fulfilled,
                (state, action: PayloadAction<VisualOffering>) => {
                    // after creation, projects are refetched
                    state.isLoading = false;
                }
            )

            // Delete Project
            .addCase(deleteProject.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteProject.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                deleteProject.fulfilled,
                (state, action: PayloadAction<VisualOffering>) => {
                    // state.projects = state.projects.filter(
                    //     (project) => project.id !== action.payload.id
                    // );
                    // after deletion, projects are refetched
                    state.isLoading = false;
                }
            )

            // Update Project
            .addCase(updateProject.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateProject.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                updateProject.fulfilled,
                (state, action: PayloadAction<VisualOffering>) => {
                    const index = state.projects.docs.findIndex(
                        (project) => project.id === action.payload.id
                    );
                    if (index !== -1) {
                        state.projects.docs[index] = action.payload;
                    }
                    state.isLoading = false;
                }
            )

            // Create Spacial Solution
            .addCase(createSpacialSolutionThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createSpacialSolutionThunk.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                createSpacialSolutionThunk.fulfilled,
                (state, action: PayloadAction<SpacialSolution>) => {
                    if (state.selectedProject && state.projects) {
                        // sync with projects also
                        // TODO: selectedProject source should be projects not selectedProject
                        const foundProject = state.projects.docs.find(
                            (project) =>
                                project.id === state.selectedProject?.id
                        );
                        if (foundProject) {
                            foundProject.spacialSolutions =
                                foundProject.spacialSolutions || [];
                            foundProject.spacialSolutions.push(action.payload);
                        }

                        // Ensure spacialSolutions array exists before pushing
                        if (!state.selectedProject.spacialSolutions) {
                            state.selectedProject.spacialSolutions = [];
                        }
                        // add new spacial solution to store
                        state.selectedProject.spacialSolutions.push(
                            action.payload
                        );
                    }

                    state.isLoading = false;
                }
            )

            // Update Spacial Solution
            .addCase(updateSpacialSolutionThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateSpacialSolutionThunk.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                updateSpacialSolutionThunk.fulfilled,
                (state, action: PayloadAction<SpacialSolution>) => {
                    const index =
                        state.selectedProject?.spacialSolutions?.findIndex(
                            (solution) => solution.id === action.payload.id
                        );
                    if (
                        index !== -1 &&
                        state.selectedProject?.spacialSolutions &&
                        index !== undefined
                    ) {
                        state.selectedProject.spacialSolutions[index] =
                            action.payload;
                    }

                    const currentProject = state.projects.docs.find(
                        (project) => project.id === state.selectedProject?.id
                    );

                    if (currentProject) {
                        currentProject.spacialSolutions?.every(
                            (solution, index) => {
                                if (
                                    solution.id === action.payload.id &&
                                    currentProject.spacialSolutions
                                ) {
                                    currentProject.spacialSolutions[index] =
                                        action.payload;
                                    return false;
                                }
                                return true;
                            }
                        );
                    }

                    state.isLoading = false;
                }
            )

            // Create Room
            .addCase(createRoomThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createRoomThunk.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                createRoomThunk.fulfilled,
                (state, action: PayloadAction<Room>) => {
                    // find redux store spacial solution to push the new room into
                    const foundProject = state.projects.docs.find(
                        (project) => project.id === state.selectedProject?.id
                    );
                    if (foundProject) {
                        let foundSpacialSolution: SpacialSolution | undefined;
                        foundProject.spacialSolutions?.every((solution) => {
                            if (
                                solution.id ===
                                (action as any)?.meta?.arg?.solutionId
                            ) {
                                foundSpacialSolution = solution;
                                foundSpacialSolution.rooms =
                                    foundSpacialSolution.rooms || [];
                                foundSpacialSolution.rooms.push(action.payload);
                                // break out of every() loop
                                return false;
                            }
                            return true;
                        });
                    }

                    state.isLoading = false;
                }
            )

            // Delete Room
            .addCase(deleteRoomThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteRoomThunk.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                deleteRoomThunk.fulfilled,
                (state, action: PayloadAction<Room>) => {
                    state.selectedProject?.spacialSolutions?.forEach(
                        (solution) => {
                            solution.rooms = solution.rooms?.filter(
                                (room) => room.id !== action.payload.id
                            );
                        }
                    );

                    state.isLoading = false;
                }
            )

            // Update Room
            .addCase(updateRoomThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateRoomThunk.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                updateRoomThunk.fulfilled,
                (state, action: PayloadAction<Room>) => {
                    state.selectedProject?.spacialSolutions?.forEach(
                        (solution) => {
                            const index = solution.rooms?.findIndex(
                                (room) => room.id === action.payload.id
                            );
                            if (
                                index !== undefined &&
                                index !== -1 &&
                                solution.rooms
                            ) {
                                solution.rooms[index] = action.payload;
                            }
                        }
                    );

                    // sync projects also
                    const currentProject = state.projects.docs.find(
                        (project) => project.id === state.selectedProject?.id
                    );

                    if (currentProject) {
                        currentProject.spacialSolutions?.every((solution) => {
                            const foundRoomIndex = solution.rooms?.findIndex(
                                (room) => room.id === action.payload.id
                            );

                            if (
                                foundRoomIndex !== undefined &&
                                foundRoomIndex >= 0
                            ) {
                                solution.rooms![foundRoomIndex] =
                                    action.payload;
                                return false;
                            }

                            return true;
                        });
                    }

                    state.isLoading = false;
                }
            )

            // Create Variation
            .addCase(createVariationThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createVariationThunk.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                createVariationThunk.fulfilled,
                (state, action: PayloadAction<Variation>) => {
                    // find redux store room to push the new variation into
                    const foundProject = state.projects.docs.find(
                        (project) => project.id === state.selectedProject?.id
                    );
                    if (foundProject) {
                        let foundRoom: Room | undefined;
                        foundProject.spacialSolutions?.every((solution) => {
                            solution.rooms?.every((room) => {
                                if (
                                    room.id ===
                                    (action as any)?.meta?.arg?.roomId
                                ) {
                                    foundRoom = room;
                                    foundRoom.variations =
                                        foundRoom.variations || [];
                                    foundRoom.variations.push(action.payload);
                                    return false;
                                }
                                return true;
                            });

                            // break out of every() if room is found i.e. return false
                            return foundRoom === undefined;
                        });
                    }

                    state.isLoading = false;
                }
            )

            // Update Variation
            .addCase(updateVariationThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateVariationThunk.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(
                updateVariationThunk.fulfilled,
                (state, action: PayloadAction<Variation>) => {
                    const foundProject = state.projects.docs.find(
                        (project) => project.id === state.selectedProject?.id
                    );

                    if (foundProject) {
                        foundProject.spacialSolutions?.forEach((solution) => {
                            solution.rooms?.forEach((room) => {
                                const variationIndex =
                                    room.variations?.findIndex(
                                        (variation) =>
                                            variation.id === action.payload.id
                                    );
                                if (
                                    variationIndex !== undefined &&
                                    variationIndex !== -1 &&
                                    room.variations
                                ) {
                                    room.variations[variationIndex] =
                                        action.payload;
                                }
                            });
                        });
                    }

                    state.isLoading = false;
                }
            );
    },
});

// export actions
export const {
    setSelectedProject,
    setDesignCodeProject,
    setGlobalModalOfferSend,
    setProjectLoadingState,
    setCompareWithVariation,
} = projectSlice.actions;

// selectors
export const selectProjects = createSelector(
    // Input selector: Selects the entire projects state (state.project.projects)
    (state: RootState) => state.project.projects,

    // Output selector: Filters out projects with status 'DELETED'
    (projects) => ({
        ...projects,
        docs: projects.docs.filter(
            (project) => project.status !== VispakOfferStatusConst.DELETED
        ),
    })
);

export const selectSelectedProject = (
    state: RootState
): VisualOffering | undefined => {
    const selectedProjectFromStore = state.project.selectedProject;

    if (!selectedProjectFromStore) {
        return undefined;
    }

    const projects = state.project.projects;
    const foundProject = projects.docs.find(
        (project) => project.id === selectedProjectFromStore.id
    );

    return foundProject;
};

export const selectDesignCodeProject = (state: RootState) =>
    // design code project has only read access
    // so no need to consider modifications
    state.project.designCodeProject;

export const selectGlobalModals = (state: RootState) =>
    state.project.globalModals;

export const selectIsLoading = (state: RootState) => state.project.isLoading;

export const selectRoomById = createSelector(
    [
        (state: RootState) => state.project.projects.docs || [],
        (_state: RootState, roomId: string | undefined) => roomId,
        (state: RootState) => state.project.selectedProject?.id,
    ],
    (projects, roomId, selectedProjectId) => {
        if (!roomId || !selectedProjectId) {
            return null;
        }

        const foundSolution =
            projects
                .filter(
                    (project: VisualOffering) =>
                        project.status !== VispakOfferStatusConst.DELETED
                )
                .find((project) => project.id === selectedProjectId)
                ?.spacialSolutions?.filter((solution) => !solution.deleted) ||
            [];

        const solutionWithRoom = foundSolution.find((solution) =>
            solution.rooms?.find((room) => room.id === roomId)
        );

        return (
            solutionWithRoom?.rooms?.find((room) => room.id === roomId) || null
        );
    }
);

export const selectDesignCodeRoomById = createSelector(
    [
        (state: RootState) =>
            state.project.designCodeProject?.spacialSolutions?.filter(
                (solution) => !solution.deleted
            ) || [],
        (_state: RootState, roomId: string | undefined) => roomId,
    ],
    (spacialSolutions, roomId) => {
        if (!roomId) {
            return null;
        }

        const solutionWithRoom = spacialSolutions.find((solution) =>
            solution.rooms?.find((room) => room.id === roomId)
        );

        return (
            solutionWithRoom?.rooms?.find((room) => room.id === roomId) || null
        );
    }
);

export const selectVariationById = (
    state: RootState,
    variationId: string | undefined,
    roomId: string | undefined
) => {
    if (!variationId || !roomId) {
        return null;
    }
    const foundRoom = selectRoomById(state, roomId);

    return (
        foundRoom?.variations?.find(
            (variation) => variation.id === variationId
        ) || null
    );
};

// memoized selector
export const selectSpacialSolutions = createSelector(
    // Input selector for the 'selectedProjectId'
    (state: RootState) => state.project.selectedProject?.id,

    // Input selector for all 'projects'
    (state: RootState) => state.project.projects.docs,

    // Output selector that computes the spacial solutions
    (selectedProjectId, projects) => {
        if (!selectedProjectId) {
            return [];
        }

        const project = projects.find(
            (project) => project.id === selectedProjectId
        );
        if (!project) {
            return [];
        }

        return (
            project.spacialSolutions?.filter((solution) => !solution.deleted) ||
            []
        );
    }
);

// memoized selector for design code spacial solutions
export const selectDesignCodeSpacialSolutions = createSelector(
    // Input selector for the design code project
    (state: RootState) => state.project.designCodeProject,

    // Output selector that computes the spacial solutions
    (designCodeProject) => {
        if (!designCodeProject) {
            return [];
        }

        return (
            designCodeProject.spacialSolutions
                ?.filter((solution) => {
                    // Filter out deleted solutions
                    if (solution.deleted) return false;

                    // Filter out solutions with no public rooms
                    const hasPublicRooms = solution.rooms?.some(
                        (room) =>
                            !room?.deleted &&
                            room?.status === VispakRoomStatusConst.PUBLIC
                    );

                    return hasPublicRooms ?? false;
                })
                ?.map((solution) => ({
                    ...solution,
                    // Filter out rooms that are deleted or do not have a public status
                    rooms:
                        solution.rooms?.filter(
                            (room) =>
                                !room?.deleted &&
                                room?.status === VispakRoomStatusConst.PUBLIC
                        ) ?? [],
                })) || []
        );
    }
);

export const selectCompareWithVariation = (state: RootState) =>
    state.project.compareWithVariation;

export default projectSlice.reducer;
