import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// mui
import { Box, Drawer, Typography, Link, CircularProgress } from '@mui/material';

// ui
import { colors } from 'theming/colors';
import Header from 'components/base/text/Header';

// store
import { setOpenedPlate } from 'features/moodboard/moodboardState';

// services
import { PIMService } from 'services/PIMService';

// types
import { PlateType } from 'features/moodboard/types';

interface ProductInfoDrawerProps {
    openedPlate: PlateType | null;
    isPlateInfoDrawerOpen: boolean;
    setIsPlateInfoDrawerOpen: (isOpen: boolean) => void;
}

const ProductInfoDrawer = ({
    openedPlate,
    isPlateInfoDrawerOpen,
    setIsPlateInfoDrawerOpen,
}: ProductInfoDrawerProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // local state

    const [productInfo, setProductInfo] = useState<PIMProduct | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // properties

    const productName = productInfo?.name;
    const productCode = productInfo?.externalId;
    const productUrl =
        productInfo?.overviewUrl && productInfo.overviewUrl + '&locale=et';

    // features

    const closeDrawer = () => {
        dispatch(setOpenedPlate(null));
        setIsPlateInfoDrawerOpen(false);
    };

    // side effects

    useEffect(() => {
        (async function () {
            if (openedPlate?.pimProductId) {
                const pimService = new PIMService();
                const depth = 5;

                setIsLoading(true);

                const product = await pimService.getProductById(
                    openedPlate.pimProductId,
                    depth
                );

                if (product) {
                    setProductInfo(product);
                }

                setIsLoading(false);
            }
        })();
    }, [openedPlate]);

    return (
        <Drawer
            variant="temporary"
            open={isPlateInfoDrawerOpen}
            onClose={closeDrawer}
            sx={styles.productInfoDrawer}
        >
            <Box component="div" sx={styles.productInfoContainer}>
                {isLoading && (
                    <Box component="div" sx={styles.loadingContainer}>
                        <CircularProgress size={24} />
                    </Box>
                )}
                {!isLoading && (
                    <>
                        <Header>
                            <Typography>
                                {t(`views.configurator.productInfo`)}
                            </Typography>
                        </Header>
                        <Box component="div" sx={styles.productInfoList}>
                            <Typography sx={styles.productInfoTitle}>
                                {t(`views.productList.productName`)}
                            </Typography>
                            <Typography sx={styles.productInfoData}>
                                {productName}
                            </Typography>
                            <Typography sx={styles.productInfoTitle}>
                                {t(`views.productList.productCode`)}
                            </Typography>
                            <Typography sx={styles.productInfoData}>
                                {productCode}
                            </Typography>
                            <Typography sx={styles.productInfoTitle}>
                                {t(`views.productList.overviewUrl`)}
                            </Typography>
                            <Typography sx={styles.productInfoData}>
                                <Link
                                    href={productUrl ?? '#'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={styles.productInfoLink}
                                >
                                    {productUrl}
                                </Link>
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
        </Drawer>
    );
};

export default ProductInfoDrawer;

const styles = {
    productInfoDrawer: {
        '& .MuiDrawer-paper': {
            width: '20rem',
        },
    },
    productInfoContainer: {
        padding: '1rem',
        maxWidth: '20rem',
        whiteSpace: 'wrap',
        wordWrap: 'break-word',
    },
    productInfoList: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '0.5rem',
    },
    productInfoTitle: {
        fontWeight: 'bold',
        fontSize: '1rem',
        color: colors.black,
    },
    productInfoData: {
        fontSize: '0.875rem',
        color: colors.fGrey,
    },
    productInfoLink: {
        color: colors.fGrey,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
    },
};
