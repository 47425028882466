import { createTheme } from '@mui/material/styles';

// Theme declarations
import { lightThemeProperties } from './lightThemeProperties';
import typography from './typography';

// types
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Palette, PaletteOptions } from '@mui/material';

const themeOptions = { ...lightThemeProperties } as const;

const lightTheme = createTheme(themeOptions);

type CustomTheme = {
    [Key in keyof typeof themeOptions]: (typeof themeOptions)[Key];
};

declare module '@mui/material/styles' {
    interface Theme extends CustomTheme {
        // 1:1 structure with imported theme properties
        extra: {
            menu: {
                primary: string;
                secondary: string;
            };
            text: {
                color: {
                    primary: string;
                    secondary: string;
                };
            };
            tags: {
                background: {
                    color: {
                        default: string;
                        inProgress: string;
                        published: string;
                        archived: string;
                        deleted: string;
                    };
                };
                foreground: {
                    color: {
                        normal: string;
                        inProgress: string;
                        published: string;
                        archived: string;
                        deleted: string;
                    };
                };
            };
            toggle: {
                foreground: {
                    color: {
                        off: string;
                        on: string;
                        disabled: string;
                    };
                };
                background: {
                    color: {
                        off: string;
                        on: string;
                        disabled: string;
                    };
                };
            };
        };
        palette: {
            mode: 'light';
            error: {
                main: string,
                contrastText: string,
            },
            primary: {
                main: string;
                light: string;
                dark: string;
                contrastText: string;
            };
            secondary: {
                main: string;
                light: string;
                dark: string;
                contrastText: string;
            };
        };
        typography: typeof typography;
    }

    // allow configuration using `createTheme`
    interface ThemeOptions extends CustomTheme {
        extra?: {
            menu: {
                primary: string;
                secondary: string;
            };
            text: {
                color: {
                    primary: string;
                    secondary: string;
                };
            };
            tags: {
                background: {
                    color: {
                        default: string;
                        inProgress: string;
                        published: string;
                        archived: string;
                        deleted: string;
                    };
                };
                foreground: {
                    color: {
                        normal: string;
                        inProgress: string;
                        published: string;
                        archived: string;
                        deleted: string;
                    };
                };
            };
            toggle: {
                foreground: {
                    color: {
                        off: string;
                        on: string;
                        disabled: string;
                    };
                };
                background: {
                    color: {
                        off: string;
                        on: string;
                        disabled: string;
                    };
                };
            };
        };
        palette?: PaletteOptions;
        typography?:
            | TypographyOptions
            | ((palette: Palette) => TypographyOptions);
    }
}

// reference: https://mui.com/material-ui/customization/default-theme/
// how-to: https://mui.com/material-ui/customization/theming/
// https://github.com/mui/material-ui/issues/17544
export default lightTheme;
