import axios, { AxiosResponse } from 'axios';
import { API_URL, AUTH_COOKIE_NAME } from 'config';
import { CookieService } from 'services/cookie/CookieService';

interface GetPreviousVersionOptions {
    visualOfferingVersionId: string;
    versionConstructId: string;
}

export class VersionsService {
    public url;
    public token;
    public config;

    constructor() {
        this.url = `${API_URL}/v0/versions`;
        this.token = CookieService.getCookieByName(AUTH_COOKIE_NAME);
        this.config = {
            headers: {
                Authorization: `Bearer ${this.token}`,
            },
        };
    }

    public async getPreviousVersion(
        options: GetPreviousVersionOptions
    ): Promise<VisualOffering> {
        try {
            const response: AxiosResponse<VisualOffering> = await axios.get(
                `${this.url}/getVersion`,
                {
                    ...this.config,
                    params: {
                        ...options,
                    },
                }
            );
            return response.data;
        } catch (error: any) {
            throw new Error('Getting previous version failed:', error?.message);
        }
    }

    public async rollBackToPreviousVersion(
        versionConstructId: string
    ): Promise<number> {
        try {
            // returns empty string & status = 200 on success
            const response: AxiosResponse<string> = await axios.post(
                `${this.url}/rollbackVersion/${versionConstructId}`,
                {},
                this.config
            );

            return response.status;
        } catch (error: any) {
            throw new Error(
                'Rolling back to previous version failed:',
                error?.message
            );
        }
    }
}
