import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// ui
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from 'components/base/Button';
import { Box, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

// store
import {
    updateRoomThunk,
    selectIsLoading,
} from 'features/projects/state/projectState';

// types
import { AppDispatch } from 'store';

export interface ModifyRoomDialogProps {
    currentRoom: Room | undefined;
    roomId: string;
    isModifyRoomDialogOpen: boolean;
    onCloseModifyRoomDialog: () => void;
}

const ModifyRoomDialog = (props: ModifyRoomDialogProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();

    // local state

    const [roomName, setRoomName] = useState('');

    // properties

    const isLoadingProjectState = useSelector(selectIsLoading);

    // features

    const handleModifyRoom = async () => {
        const updateData: Partial<Room> = { name: roomName };
        await dispatch(updateRoomThunk({ id: props.roomId, updateData })).catch(
            (error) => {
                console.error('Error modifying room in UI:', error);
            }
        );

        props.onCloseModifyRoomDialog();
    };

    // side effects

    useEffect(() => {
        if (props.currentRoom) {
            setRoomName(props.currentRoom.name);
        }
    }, [props.currentRoom, props.isModifyRoomDialogOpen]);

    return (
        <Dialog
            open={props.isModifyRoomDialogOpen}
            onClose={props.onCloseModifyRoomDialog}
        >
            {/* Right top close button */}
            <IconButton
                aria-label="close"
                onClick={props.onCloseModifyRoomDialog}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.main,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Title */}
            <Box component="div" sx={styles.titleBox}>
                <DialogTitle>
                    {t(`component.modifyRoomDialog.title`)}
                </DialogTitle>
            </Box>

            {/* Content */}
            <DialogContent>
                <TextField
                    variant="standard"
                    placeholder={t(`component.modifyRoomDialog.changeName`)}
                    fullWidth
                    rows={4}
                    sx={{ marginTop: '2rem' }}
                    onChange={(event) => {
                        setRoomName(event.target.value);
                    }}
                    value={roomName}
                ></TextField>
            </DialogContent>

            {/* Actions */}
            <DialogActions sx={styles.dialogActions}>
                <Button
                    sx={styles.buttonCancel}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => props.onCloseModifyRoomDialog()}
                >
                    {t(`cancel`)}
                </Button>
                <Button
                    sx={styles.buttonSubmit}
                    variant={'contained'}
                    type="submit"
                    onClick={handleModifyRoom}
                    disabled={isLoadingProjectState}
                >
                    {isLoadingProjectState ? (
                        <CircularProgress size={20} />
                    ) : (
                        t(`submit`)
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModifyRoomDialog;

const styles = {
    titleBox: {
        marginRight: '2rem',
    },
    dialogActions: {
        justifyContent: 'flex-end',
        gap: '0.4rem',
        margin: '0px 0.5rem 0.5rem 0px',
    },
    buttonCancel: {
        height: '2.8rem',
    },
    buttonSubmit: {
        height: '2.8rem',
    },
};
