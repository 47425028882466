import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSetProjectStatus } from 'utils';

// mui
import {
    Box,
    IconButton,
    DialogActions,
    Stack,
    Button,
    Typography,
    Checkbox,
    FormControlLabel,
    TextField,
    CircularProgress,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Close as CloseIcon } from '@mui/icons-material';

// ui
import { useTheme } from '@mui/material/styles';

// store
import { selectGlobalModals } from 'features/projects/state/projectState';
import { selectUserDetails } from 'features/authentication/state/authState';

// services
import { VisualOfferingsService } from 'services/visualOfferings/VisualOfferingsService';
import { EmailService } from 'services/EmailService';

// types
import { VispakOfferStatusConst } from 'types/StatusTypes';

export interface SendOfferDialogProps {
    isSendOfferDialogOpen: boolean;
    onCloseSendOfferDialog: () => void;
}

const SendOfferDialog = (props: SendOfferDialogProps) => {
    const { t: translate } = useTranslation();
    const location = useLocation();
    const theme = useTheme();
    const setProjectStatus = useSetProjectStatus();

    // local state

    const [visualOfferingWithOdooEmails, setVisualOfferingWithOdooEmails] =
        useState<VisualOffering | undefined>();
    const [checkedEmails, setCheckedEmails] = useState<string[]>([]);
    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
    const [message, setMessage] = useState('');

    const [isLoadingVisualOffer, setIsLoadingVisualOffer] =
        useState<boolean>(false);
    const [isSendingEmails, setIsSendingEmails] = useState<boolean>(false);

    // properties

    const userDetails = useSelector(selectUserDetails);
    const globalModals = useSelector(selectGlobalModals);

    const combinedEmails = (): OdooEmail[] => {
        return [
            ...(visualOfferingWithOdooEmails?.odooLead?.additionalEmails || []),
            ...(visualOfferingWithOdooEmails?.odooLead?.leadEmail
                ? [visualOfferingWithOdooEmails.odooLead.leadEmail]
                : []),
        ];
    };

    const hasAnyRecipientsBeenSelected = checkedEmails.length;

    const hasAnyEmailProvided =
        !!combinedEmails()?.length &&
        !!combinedEmails()?.some((odooEmail: OdooEmail) => odooEmail?.email);

    // features

    const onSendVisualOffer = async () => {
        if (
            visualOfferingWithOdooEmails &&
            hasAnyRecipientsBeenSelected &&
            globalModals?.offer?.sendOffer?.id
        ) {
            // for loading state
            setIsSendingEmails(true);

            // 1. set VO status to VispakOfferStatusConst.PUBLIC
            await setProjectStatus(
                globalModals.offer.sendOffer.id,
                VispakOfferStatusConst.PUBLIC
            );

            // 2. send emails
            const emailSendingResponse = await sendVisualOfferEmails(
                globalModals.offer.sendOffer.id,
                checkedEmails,
                message
            );

            // 3. email sending failed set VO status back to VispakOfferStatusConst.IN_PROGRESS
            if (emailSendingResponse?.status !== 204) {
                await setProjectStatus(
                    globalModals.offer.sendOffer.id,
                    VispakOfferStatusConst.IN_PROGRESS
                );
            }

            setIsSendingEmails(false);

            props.onCloseSendOfferDialog();
        }
    };

    const sendVisualOfferEmails = async (
        visualOfferingId: string,
        toEmails: string[],
        customMessage: string
    ) => {
        const emailService = new EmailService();
        const emailingResponse = await emailService.postVisualOfferingEmails(
            visualOfferingId,
            toEmails,
            customMessage
        );

        return emailingResponse;
    };

    const getVisualOfferingById = async (id: string) => {
        setIsLoadingVisualOffer(true); // Start loading
        const visualOfferingsService = new VisualOfferingsService();
        try {
            const visualOffering =
                await visualOfferingsService.getVisualOfferingById(id);
            setVisualOfferingWithOdooEmails(visualOffering);
        } catch (error) {
            console.error('Error fetching visual offering:', error);
        } finally {
            setIsLoadingVisualOffer(false); // End loading
        }
    };

    const onCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        index: number,
        combinedEmails: OdooEmail[]
    ) => {
        if (!visualOfferingWithOdooEmails) return;

        const updatedEmails = [...combinedEmails];

        setCheckedEmails([
            ...checkedEmails.filter(
                (email) => email !== updatedEmails[index]?.email
            ),
            ...(event.target.checked
                ? [updatedEmails[index]?.email ?? '']
                : []),
        ]);
    };

    const onMessageChange = (value: string) => {
        setMessage(value);
    };

    const getRoleLabel = (role: string) => {
        let roleLabel = '';

        if (!role) return roleLabel;

        switch (true) {
            case role.toLowerCase().includes('arch'):
                roleLabel = translate(
                    'component.sendOfferDialog.roleArchitect'
                );
                break;
            case role.toLowerCase().includes('inter'):
                roleLabel = translate('component.sendOfferDialog.roleDesigner');
                break;
            case role.toLowerCase().includes('build'):
                roleLabel = translate('component.sendOfferDialog.roleBuilder');
                break;
            default:
                roleLabel = translate('component.sendOfferDialog.roleClient');
        }

        return `(${roleLabel})`;
    };

    // side effects

    useEffect(() => {
        if (globalModals?.offer?.sendOffer?.id) {
            getVisualOfferingById(globalModals.offer.sendOffer.id);
        }

        return () => {
            setVisualOfferingWithOdooEmails(undefined);
            setCheckedEmails([]);
        };
    }, [globalModals]);

    useEffect(() => {
        setIsSendButtonDisabled(
            !hasAnyRecipientsBeenSelected || message.length < 5
        );
    }, [visualOfferingWithOdooEmails, hasAnyRecipientsBeenSelected, message]);

    return (
        <Dialog
            open={props.isSendOfferDialogOpen}
            onClose={props.onCloseSendOfferDialog}
        >
            <IconButton
                aria-label="close"
                onClick={props.onCloseSendOfferDialog}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.main,
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogTitle sx={style.dialogTitle}>
                {translate(`component.sendOfferDialog.title`)}
            </DialogTitle>

            <DialogContent sx={style.dialogContent}>
                <Stack direction="row">
                    <Stack
                        sx={{
                            width: {
                                xs: '25rem', // 0 - 599 px
                                sm: '30rem', // 600 - 899 px
                                md: '35rem', // 900 - 1199px
                                lg: '36rem', // 1200 - 1535px
                                xl: '36rem', // 1536+ px
                            },
                        }}
                        direction="column"
                    >
                        <Box component="div" sx={style.contentBox}>
                            {/* Visual offer name i.e. Odoo lead */}
                            <Box component="div" sx={style.contentSection}>
                                <Typography sx={style.contentSectionTitle}>
                                    {translate(
                                        `component.sendOfferDialog.leadName`
                                    )}
                                </Typography>
                                {isLoadingVisualOffer ? (
                                    <Box component="div" sx={style.fetchLoader}>
                                        <CircularProgress size={20} />
                                    </Box>
                                ) : (
                                    <Typography sx={style.contentSectionText}>
                                        {visualOfferingWithOdooEmails?.name}
                                    </Typography>
                                )}
                            </Box>

                            {/* Recipients */}
                            <Box component="div" sx={style.contentSection}>
                                <Typography sx={style.contentSectionTitle}>
                                    {translate(
                                        `component.sendOfferDialog.chooseRecipients`
                                    )}
                                </Typography>
                                <Box
                                    component="div"
                                    sx={style.contentSectionCheckboxContainer}
                                >
                                    {isLoadingVisualOffer ? (
                                        <Box
                                            component="div"
                                            sx={style.fetchLoader}
                                        >
                                            <CircularProgress size={20} />
                                        </Box>
                                    ) : hasAnyEmailProvided ? (
                                        combinedEmails()?.map(
                                            (OdooEmail, index) => {
                                                if (!OdooEmail?.email)
                                                    return null;

                                                return (
                                                    <FormControlLabel
                                                        key={index}
                                                        control={
                                                            <Checkbox
                                                                checked={checkedEmails.includes(
                                                                    OdooEmail.email ??
                                                                        ''
                                                                )}
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    onCheckboxChange(
                                                                        event,
                                                                        index,
                                                                        combinedEmails() ??
                                                                            []
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label={`${
                                                            OdooEmail?.email
                                                        } ${getRoleLabel(
                                                            OdooEmail?.role ??
                                                                ''
                                                        )}`}
                                                    />
                                                );
                                            }
                                        )
                                    ) : (
                                        <Typography
                                            sx={style.contentSectionText}
                                        >
                                            {translate(
                                                `component.sendOfferDialog.noRecipients`
                                            )}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>

                            {/* Design code*/}
                            <Box component="div" sx={style.contentSection}>
                                <Typography sx={style.contentSectionTitle}>
                                    {translate(
                                        `component.sendOfferDialog.designCode`
                                    )}
                                </Typography>
                                {isLoadingVisualOffer ? (
                                    <Box component="div" sx={style.fetchLoader}>
                                        <CircularProgress size={20} />
                                    </Box>
                                ) : (
                                    <Typography sx={style.contentSectionText}>
                                        {
                                            visualOfferingWithOdooEmails?.designCode
                                        }
                                    </Typography>
                                )}
                            </Box>

                            {/* Message */}
                            <TextField
                                variant="standard"
                                label={translate(
                                    `component.sendOfferDialog.messageLabel`
                                )}
                                placeholder={translate(
                                    `component.sendOfferDialog.messagePlaceholder`
                                )}
                                fullWidth
                                multiline
                                rows={4}
                                sx={{ marginTop: '0' }}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    onMessageChange(event.target.value);
                                }}
                                value={message}
                            ></TextField>

                            {/* Logged in user e-mail*/}
                            <Box component="div" sx={style.contentSection}>
                                <Typography sx={style.contentSectionTitle}>
                                    {translate(
                                        `component.sendOfferDialog.sendEmailCC`
                                    )}
                                </Typography>
                                <Typography
                                    sx={{
                                        ...style.contentSectionText,
                                        fontSize: '0.9rem',
                                    }}
                                >
                                    {userDetails?.email}
                                </Typography>
                            </Box>
                        </Box>
                    </Stack>
                </Stack>
            </DialogContent>

            <DialogActions sx={style.dialogActions}>
                <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => props.onCloseSendOfferDialog()}
                >
                    {translate(`cancel`)}
                </Button>
                <Button
                    variant={'contained'}
                    type="submit"
                    disabled={isSendButtonDisabled || isSendingEmails}
                    onClick={onSendVisualOffer}
                    sx={{
                        height: '2.5rem',
                    }}
                >
                    {isSendingEmails ? (
                        <CircularProgress size={20} />
                    ) : (
                        translate(`send`)
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SendOfferDialog;

const style = {
    dialogTitle: {
        fontSize: '1.8rem',
        mb: '1rem',
    },
    dialogContent: {
        padding: '1rem 1.8rem',
    },
    dialogActions: {
        justifyContent: 'flex-end',
        gap: '0.4rem',
        margin: '0px 24px 20px 24px',
    },

    contentBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    contentSection: {
        display: 'flex',
        flexDirection: 'column',
        mb: '',
    },
    contentSectionTitle: {
        fontWeight: 'bold',
        fontSize: '1rem',
        mb: '0.5rem',
    },
    contentSectionText: {
        fontWeight: 'normal',
        fontSize: '1.2rem',
        mb: '0.5rem',
    },
    contentSectionCheckboxContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0',
        maxHeight: '14rem',
        overflowY: 'auto',
    },
    fetchLoader: {
        display: 'flex',
        justifyContent: 'center',
        mt: 2,
        height: '2rem',
    },
};
