import React, {
    SyntheticEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// r3f
import { useFrame, useLoader, ThreeEvent } from '@react-three/fiber';
import { Html, Outlines, TransformControls } from '@react-three/drei';
import * as THREE from 'three';
import { DragControls } from 'three/examples/jsm/controls/DragControls';

// config
import { colors } from 'theming/colors';
import { pivotConfigs } from './configs';

// mui
import { Stack } from '@mui/system';
import { CircularProgress, Slider } from '@mui/material';

// ui
import ToolButtons from './PlateTools';

// services
import { PIMService } from 'services/PIMService';

// store
import { AppDispatch } from 'store';
import { setOpenedPlate, updatePlateById } from './moodboardState';
import { selectDesignCodeProject } from 'features/projects/state/projectState';

// types
import { PlateInterface, ToolType } from './types';

const Plate: React.FC<PlateInterface> = ({
    plateData,
    focused,
    onPress,
    onDelete,
}) => {
    // if set it's a design code session (store is populated with designCodeProject)
    const designCodeProject = useSelector(selectDesignCodeProject);

    const transformRef = useRef(null);
    const htmlRef = useRef(null);
    const meshRef = useRef<THREE.Mesh>(null);
    const groupRef = useRef<THREE.Group>(null);
    const dragControlsRef = useRef<DragControls | null>(null);
    const [selectedTool, setSelectedTool] = useState<ToolType>('translate');
    const [isRotating, setIsRotating] = useState(false);
    const [targetRotation, setTargetRotation] = useState(0);
    const [locked, setLocked] = useState(plateData.locked);
    const dispatch: AppDispatch = useDispatch();
    const initialPosition = plateData.position;
    const initialScale = plateData.scale;
    const initialRotation = plateData.rotation;
    const [height, setHeight] = useState(plateData.height);
    const [currentImgIndex, setCurrentImgIndex] = useState(0);
    const pimService = new PIMService();
    const [textureURL, setTextureURL] = useState('/placeholder_plate.png');
    const texture = useLoader(THREE.TextureLoader, textureURL);
    const [loadingTexture, setLoadingTexture] = useState(false);
    const i18n = useTranslation();
    const boxRef = useRef<THREE.BoxGeometry>(null);

    const handleObjectChange = (data: {
        newLock?: boolean;
        newRotation?: number;
        newHeight?: number;
    }) => {
        if (!transformRef.current) return;
        if (!meshRef.current) return;

        const posX = (transformRef.current as any).worldPosition.x;
        const posY = (transformRef.current as any).worldPosition.y;
        const posZ = (transformRef.current as any).worldPosition.z;
        const rotX = meshRef.current.rotation.x;
        const rotY = meshRef.current.rotation.y;
        const scaleX = meshRef.current?.scale.x;
        const scaleY = meshRef.current?.scale.y;
        const scaleZ = meshRef.current?.scale.z;
        const newHeight = data.newHeight || plateData.height;
        const newRotation = data.newRotation || plateData.rotation[2];
        const newLock = data.newLock || plateData.locked;

        const id = plateData.id;
        const updatedPlate = {
            ...plateData,
            position: [posX, posY, posZ],
            transformScale: [scaleX, scaleY, scaleZ],
            height: newHeight,
            rotation: [rotX, rotY, newRotation],
            locked: newLock,
        };

        dispatch(updatePlateById({ id, updatedPlate }));
    };

    const handleNextImage = useCallback(() => {
        setCurrentImgIndex((prevIndex) =>
            prevIndex === plateData.textures.length - 1 ? 0 : prevIndex + 1
        );
    }, [plateData.textures.length]);

    const handlePreviousImage = useCallback(() => {
        setCurrentImgIndex((prevIndex) =>
            prevIndex === 0 ? plateData.textures.length - 1 : prevIndex - 1
        );
    }, [plateData.textures.length]);

    const handlePlateClick = (event: ThreeEvent<MouseEvent>) => {
        // Stop event propagation to prevent clicking through to lower plates
        event.stopPropagation();

        if (selectedTool === 'inactive') setSelectedTool('translate');
        onPress && onPress();
        if (locked) setSelectedTool('inactive');
    };

    const resetTransformations = () => {
        setHeight(0);
        meshRef.current?.rotation.set(-Math.PI / 2, 0, 0);
        meshRef.current?.position.set(0, 0, 0);
        (transformRef.current as any)?.worldPosition.set(0, 0, 0);
        (transformRef.current as any)?.worldScale.set(0, 0, 0);
        meshRef.current?.scale.set(
            initialScale[0],
            initialScale[1],
            initialScale[2]
        );
        handleObjectChange({ newRotation: 0, newHeight: 0 });
    };

    const lock = () => {
        setLocked(!locked);
        !locked ? setSelectedTool('inactive') : setSelectedTool('translate');
        handleObjectChange({ newLock: !locked });
    };

    const deletePlate = () => {
        if (onDelete) onDelete();
    };

    const uniformScale = initialScale;
    const transformScale = plateData.transformScale;
    const [initialSpawning, setInitialSpawning] = useState(true);

    const mode = () => {
        switch (selectedTool) {
            case 'translate':
                return 'translate';
            case 'rotate':
                return 'rotate';
            case 'scale':
                return 'scale';
            case 'crop':
                return 'scale';
            default:
                return 'translate';
        }
    };

    const fetchTexture = async () => {
        setLoadingTexture(true);
        if (plateData.textures.length === 0) {
            setLoadingTexture(false);
            alert(i18n.t('moodboard.noMaterialsFound'));
            if (onDelete) onDelete();
            return;
        }

        const fetchedTexture = await pimService.getImage(
            plateData.textures[currentImgIndex].src
        );

        if (fetchedTexture !== null) {
            const objectURL = URL.createObjectURL(fetchedTexture);
            setTextureURL(objectURL);
        } else {
            alert(i18n.t('moodboard.noMaterialsFound'));
            if (onDelete) onDelete();
        }

        setLoadingTexture(false);

        if (texture) {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.repeat.set(1, 1);
            texture.needsUpdate = true;
        }
        texture.needsUpdate = true;
    };

    // Apply initial texture settings
    useEffect(() => {
        fetchTexture();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentImgIndex]);

    useEffect(() => {
        if (dragControlsRef.current) {
            (dragControlsRef.current as any).enabled = focused; // Enable or disable DragControls based on focus
        }
    }, [focused]);

    useFrame(() => {
        if (initialSpawning) {
            meshRef.current?.scale.set(
                transformScale[0],
                transformScale[1],
                transformScale[2]
            );
            setInitialSpawning(false);
        }

        if (selectedTool === 'scale' && meshRef.current) {
            const scale = meshRef.current.scale;
            const uniformScale = scale.x;
            meshRef.current.scale.set(uniformScale, uniformScale, uniformScale);
        }
    });

    useFrame(() => {
        if (isRotating && meshRef.current) {
            const currentRotation = meshRef.current.rotation.z;
            meshRef.current.rotation.z = THREE.MathUtils.lerp(
                currentRotation,
                targetRotation,
                0.1
            );
            if (Math.abs(currentRotation - targetRotation) < 0.01) {
                setIsRotating(false);
            }
        }
    });

    const handleRotateClick = () => {
        if (meshRef.current) {
            const currentRotation = meshRef.current.rotation.z;
            const newRotation = currentRotation + -Math.PI / 2;
            const roundedRotation =
                Math.round(newRotation / (Math.PI / 2)) * (Math.PI / 2);
            setTargetRotation(roundedRotation);
            handleObjectChange({ newRotation: roundedRotation });
            setIsRotating(true);
        }
    };

    const handleSelectSelectedTool = (tool: ToolType) => {
        setSelectedTool(tool);
    };

    const openPlate = () => {
        if (plateData?.pimProductId) {
            dispatch(setOpenedPlate(plateData));
        }
    };

    useEffect(() => {
        const x = initialRotation[0];
        const y = initialRotation[1];
        const z = initialRotation[2];

        if (transformRef.current) {
            (transformRef.current as any).position.y = height;
        }

        if (meshRef.current) {
            (meshRef.current as any).rotation.set(x, y, z);
            (meshRef.current as any).position.y = height;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useFrame(() => {
        // Ensure uniform scaling during each frame if the tool is in scale mode
        if (selectedTool === 'scale' && meshRef.current) {
            const { x, y, z } = meshRef.current.scale;
            const uniform = (x + y + z) / 3;
            meshRef.current.scale.set(uniform, uniform, uniform);
        }
    });

    return (
        <TransformControls
            onMouseUp={() => handleObjectChange({})}
            position={[
                initialPosition[0],
                initialPosition[1],
                initialPosition[2],
            ]}
            ref={transformRef}
            enabled={focused}
            mode={mode()}
            showX={focused && pivotConfigs[selectedTool].activeAxes[0]}
            showY={focused && pivotConfigs[selectedTool].activeAxes[1]}
            showZ={focused && pivotConfigs[selectedTool].activeAxes[2]}
            object={
                selectedTool === 'scale'
                    ? meshRef.current || undefined
                    : undefined
            }
        >
            <group
                ref={groupRef}
                castShadow
                receiveShadow
                onClick={(e) => {
                    e.stopPropagation();
                    handlePlateClick(e);
                }}
            >
                <mesh
                    // scale={[
                    //     plateData.textures[currentImgIndex].scale[0],
                    //     plateData.textures[currentImgIndex].scale[1],
                    //     plateData.textures[currentImgIndex].scale[2],
                    // ]}
                    ref={meshRef}
                    castShadow
                    receiveShadow
                    onClick={(e) => {
                        e.stopPropagation();
                        handlePlateClick(e);
                    }}
                >
                    {!loadingTexture && (
                        <boxGeometry
                            args={[
                                uniformScale[0],
                                uniformScale[1],
                                uniformScale[2],
                            ]}
                            ref={boxRef}
                        />
                    )}
                    <meshStandardMaterial map={texture} />
                    {focused && (
                        <Outlines
                            thickness={0.01}
                            color={colors.orange}
                            screenspace
                        />
                    )}
                </mesh>

                {/* {loadingError && (
                    <Html zIndexRange={[1, 2]} center>
                        <Image sx={{ transform: 'scale(2)' }} color="warning" />
                    </Html>
                )} */}

                {loadingTexture && (
                    <Html zIndexRange={[1, 2]} center>
                        <CircularProgress />
                    </Html>
                )}

                {!loadingTexture && focused && !designCodeProject && (
                    <Html
                        zIndexRange={[1, 2]}
                        center
                        onMouseUp={() => handleObjectChange({})}
                        position={[0, 0, initialScale[1]]}
                        ref={htmlRef}
                    >
                        <Stack
                            direction="column"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                            bgcolor={'white'}
                            padding={2}
                            borderRadius={3}
                            sx={{
                                boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Stack direction="row" spacing={1}>
                                <ToolButtons
                                    setSelectedTool={handleSelectSelectedTool}
                                    handleRotateClick={handleRotateClick}
                                    resetTransformations={resetTransformations}
                                    locked={locked}
                                    lock={lock}
                                    deletePlate={deletePlate}
                                    selectedTool={selectedTool}
                                    handleNextImage={handleNextImage}
                                    handlePreviousImage={handlePreviousImage}
                                    currentImageIndex={currentImgIndex + 1}
                                    totalImages={plateData.textures.length}
                                    handleOpenPlate={openPlate}
                                />
                            </Stack>
                            <Slider
                                disabled={locked}
                                color="warning"
                                value={height}
                                min={0}
                                max={4}
                                step={0.1}
                                onChangeCommitted={(
                                    _: Event | SyntheticEvent<Element, Event>,
                                    newValue: number | number[]
                                ) => {
                                    if (typeof newValue === 'number') {
                                        handleObjectChange({
                                            newHeight: newValue,
                                        });
                                    }
                                }}
                                onChange={(
                                    _: Event | SyntheticEvent<Element, Event>,
                                    newValue: number | number[]
                                ) => {
                                    if (typeof newValue === 'number') {
                                        setHeight(newValue);
                                        if (transformRef.current) {
                                            (
                                                transformRef.current as any
                                            ).position.y = newValue;
                                        }
                                        if (meshRef.current) {
                                            (
                                                meshRef.current as any
                                            ).position.y = newValue;
                                        }
                                    }
                                }}
                                aria-labelledby="height-slider"
                            />
                        </Stack>
                    </Html>
                )}
            </group>
        </TransformControls>
    );
};

export default Plate;
