import React, { useState, useEffect } from 'react';

// mui
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export interface PaginationProps {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

export default function PaginationBar(props: PaginationProps) {
    const [page, setPage] = useState(1);

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        props.onPageChange(value);
    };

    useEffect(() => {
        setPage(props.currentPage);
    }, [props.currentPage]);

    return (
        <>
            <Stack spacing={2}>
                <Pagination
                    count={props.totalPages}
                    page={page}
                    onChange={handlePageChange}
                    variant="text"
                    color="primary"
                    shape="rounded"
                />
            </Stack>
        </>
    );
}
