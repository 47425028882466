import { useTranslation } from 'react-i18next';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CSSObject } from '@mui/material';

export interface ItemOptionsProps extends IconButtonProps { }

const ItemOptions = (props: ItemOptionsProps) => {
    const { t } = useTranslation();
    return (
        <IconButton sx={style} {...props} aria-label={t(`edit`)}>
            <MoreVertIcon />
        </IconButton>
    );
};

export default ItemOptions;

const style: CSSObject = {
    marginLeft: 'auto',
};
