import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { appPath } from 'routes';

// ui
import { Breadcrumbs, Box } from '@mui/material';
import Link from 'components/base/text/Link';

// store
import {
    selectDesignCodeProject,
    selectDesignCodeRoomById,
    selectRoomById,
    selectSelectedProject,
} from 'features/projects/state/projectState';

// types
import { RootState } from 'store';

const BreadcrumbNavigation = (): React.JSX.Element => {
    const { t } = useTranslation();
    const location = useLocation();
    const params = useParams();

    // Properties

    // If set, it's a design code session (store is populated with designCodeProject)
    const designCodeProject = useSelector(selectDesignCodeProject);

    const selectedProjectFromRegularSession = useSelector(
        selectSelectedProject
    );

    // Selected project
    const selectedProject = !designCodeProject
        ? selectedProjectFromRegularSession
        : designCodeProject;

    const selectedRoomFromRegularSession = useSelector((state: RootState) =>
        selectRoomById(state, params?.roomId)
    );
    const selectedRoomFromDesignCodeProject = useSelector((state: RootState) =>
        selectDesignCodeRoomById(state, params?.roomId)
    );

    // Selected room
    const selectedRoom = designCodeProject
        ? selectedRoomFromDesignCodeProject
        : selectedRoomFromRegularSession;

    // Project path
    const isProjectPath = !designCodeProject
        ? selectedProject?.id &&
          location.pathname.includes(
              appPath.project.replace(':projectId', selectedProject.id)
          )
        : designCodeProject?.id &&
          location.pathname.includes(
              appPath.project.replace(':projectId', designCodeProject.id)
          );

    // Room path
    const isRoomPath =
        params?.roomId &&
        location.pathname.includes(
            appPath.room.replace(':roomId', params?.roomId)
        );

    // Product list path
    const isProductListPath = location.pathname === appPath.productList;

    // Comparison path
    const isComparisonPath = location.pathname === appPath.comparison;

    const roomName = selectedRoom?.name ?? '';

    // Features

    const getBreadcrumbs = () => {
        const breadcrumbs = [
            // Include 'visualOffers' only if not in a design code session
            !designCodeProject && {
                label: t('breadcrumbs.visualOffers') ?? '',
                path: appPath.projects ?? '',
            },
            // Include 'designCodeStart' if in a design code session
            designCodeProject && {
                label: t('breadcrumbs.designCodeStart') ?? '',
                path: `${appPath.designCode}?designCode=${designCodeProject?.designCode ?? ''}`,
            },
        ].filter(Boolean); // Removes any false or undefined entries

        // /project/:projectId
        if (isProjectPath && selectedProject) {
            breadcrumbs.push({
                label: selectedProject.name ?? '',
                path: appPath.project.replace(':projectId', selectedProject.id ?? ''),
            });
        }

        // /room/:roomId
        if (selectedProject?.id && isRoomPath && roomName) {
            breadcrumbs.push(
                {
                    label: selectedProject.name ?? '',
                    path: appPath.project.replace(':projectId', selectedProject.id ?? ''),
                },
                {
                    label: roomName ?? '',
                    path: appPath.room.replace(':roomId', params?.roomId ?? ''),
                }
            );
        }

        // /productList
        if (selectedProject?.id && isProductListPath) {
            breadcrumbs.push(
                {
                    label: selectedProject.name ?? '',
                    path: appPath.project.replace(':projectId', selectedProject.id ?? ''),
                },
                {
                    label: t('breadcrumbs.productList') ?? '',
                    path: appPath.productList ?? '',
                }
            );
        }

        // /comparison
        if (selectedProject?.id && isComparisonPath) {
            breadcrumbs.push(
                {
                    label: selectedProject.name ?? '',
                    path: appPath.project.replace(':projectId', selectedProject.id ?? ''),
                },
                {
                    label: t('breadcrumbs.comparison') ?? '',
                    path: appPath.comparison ?? '',
                }
            );
        }

        return breadcrumbs;
    };

    const breadcrumbs = getBreadcrumbs();

    return (
        <Box
            component="div"
            sx={{
                marginLeft: isProductListPath ? '0' : '5rem',
                marginTop: '0',
                // marginBottom: '0.2rem',
            }}
        >
            <Breadcrumbs
                aria-label="breadcrumb"
                separator="›"
                sx={{
                    '& .MuiBreadcrumbs-ol .MuiBreadcrumbs-li a': {
                        textDecoration: 'none',
                    },
                }}
            >
                {breadcrumbs?.map((breadcrumb) => (
                    breadcrumb && (
                        <Link key={breadcrumb.path} to={breadcrumb.path}>
                            {breadcrumb.label}
                        </Link>
                    )
                ))}
            </Breadcrumbs>
        </Box>
    );
};

export default BreadcrumbNavigation;
