import axios, { AxiosResponse } from 'axios';
import { API_URL, AUTH_COOKIE_NAME } from 'config';
import { CookieService } from 'services/cookie/CookieService';

export class MediaService {
    public url: string;
    public token: string | null;
    public config: object;

    constructor() {
        this.url = `${API_URL}/api/configureMedia`;
        this.token = CookieService.getCookieByName(AUTH_COOKIE_NAME);
        this.config = {
            headers: {
                Authorization: `Bearer ${this.token}`,
                'Content-Type': 'multipart/form-data', // Required for file uploads
            },
        };
    }

    // Method to save screenshot
    public async saveScreenshot(formData: FormData): Promise<MediaDoc> {
        try {
            // Send POST request with the file
            const response: AxiosResponse<MediaResponse> = await axios.post(
                this.url,
                formData, // Pass FormData in the request body
                this.config // Include the headers and authorization token
            );

            return response.data.doc;
        } catch (error: any) {
            throw new Error(`Media upload failed: ${error?.message}`);
        }
    }
}
