import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';

const Button = (props: MuiButtonProps) => {
    const variant: any = props.variant ? props.variant : 'contained';
    return (
        <MuiButton variant={variant} {...props} sx={{ ...style, ...props.sx }}>
            {props.children}
        </MuiButton>
    );
};

export default Button;

const style: any = {
    borderRadius: '0px',
    boxShadow: 'none',
    textTransform: 'none',
    paddingBottom: '8px',
    paddingTop: '8px',
    whiteSpace: 'nowrap',
};
