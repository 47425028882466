import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appPath, appPathConst } from 'routes';

// config
import {
    DESIGN_CODE_URL_PARAM,
    AUTH_COOKIE_NAME,
    LOGIN_IMAGE_URL,
} from 'config';

// mui
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// ui
import Button from 'components/base/Button';
import TextField from 'components/base/TextField';
import Link from 'components/base/text/Link';
import Alert from 'components/base/Alert';

// services
import { CookieService } from 'services/cookie/CookieService';
import { VisualOfferingsService } from 'services/visualOfferings/VisualOfferingsService';

// store
import { AppDispatch } from 'store';
import { setDesignCodeProject } from 'features/projects/state/projectState';

export interface EnterWithDesignCodeViewProps {
    error?: boolean;
}

const EnterWithDesignCodeView = (props: EnterWithDesignCodeViewProps) => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    // local state
    const [designCodeDataLoading, setDesignCodeDataLoading] = useState(false);
    const [inputDesignCode, setInputDesignCode] = useState('');
    const [designCodeError, setDesignCodeError] = useState(false);

    // properties

    // url parameter
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const designCodeFromUrl =
        urlParams.get(DESIGN_CODE_URL_PARAM) ||
        urlParams.get(DESIGN_CODE_URL_PARAM.toLowerCase());

    const authCookie = CookieService.getCookieByName(AUTH_COOKIE_NAME);

    // features
    const onDesignCodeLogin = async () => {
        if (!inputDesignCode) return;
        setDesignCodeDataLoading(true);
        setDesignCodeError(false);

        const visualOfferingsService = new VisualOfferingsService();

        try {
            const response =
                await visualOfferingsService.getVisualOfferingByDesignCode(
                    inputDesignCode
                );

            if (response?.docs?.length > 0) {
                dispatch(setDesignCodeProject(response.docs[0])); // synchronous
                navigate(`${appPathConst.project}${response.docs[0]?.id}`);
            } else {
                setDesignCodeError(true);
            }
        } catch (error) {
            setDesignCodeError(true);
        } finally {
            setDesignCodeDataLoading(false);
        }
    };

    const redirectIfUserLoggedIn = useCallback(async () => {
        // user is possibly logged in,
        // redirect to login to validate further
        if (authCookie) {
            navigate(appPath.login);
        }
    }, [authCookie, navigate]);

    // side effects

    // redirect if auth cookie found
    useEffect(() => {
        (async function () {
            await redirectIfUserLoggedIn();
        })();
    }, [redirectIfUserLoggedIn]);

    // set design code for an input field
    useEffect(() => {
        if (designCodeFromUrl) {
            setInputDesignCode(designCodeFromUrl);
        }
    }, [designCodeFromUrl]);

    return (
        <Box component={'div'} sx={style.parentBox}>
            <Box component={'div'} sx={style.left}>
                <img
                    src={LOGIN_IMAGE_URL}
                    alt="floorin"
                    style={{
                        objectFit: 'cover',
                        objectPosition: 'left',
                        width: '100%',
                        height: '100%',
                    }}
                />
            </Box>
            <Box component={'div'} sx={style.right}>
                <h2>{translate(`views.login.header`)}</h2>
                <Alert
                    sx={{
                        ...style.alertBox,
                        visibility: !designCodeError ? 'hidden' : 'unset',
                    }}
                    severity="error"
                >
                    {translate(`views.login.designCodeFormError`)}
                </Alert>
                <Box component={'div'} sx={style.loginFields}>
                    <TextField
                        id="design-code-textfield"
                        error={designCodeError}
                        sx={style.loginTextField}
                        label={translate(`views.login.designCode`)}
                        variant="standard"
                        value={inputDesignCode ?? designCodeFromUrl}
                        onChange={(e) => setInputDesignCode(e.target.value)}
                    ></TextField>
                    <Button
                        onClick={onDesignCodeLogin}
                        sx={style.loginButton}
                        disabled={designCodeDataLoading}
                    >
                        {designCodeDataLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            translate(`views.login.enter`)
                        )}
                    </Button>
                </Box>
                <Link to="/login">
                    {translate(`views.login.enterWithAccount`)}
                </Link>
            </Box>
        </Box>
    );
};

export default EnterWithDesignCodeView;

const style: any = {
    parentBox: {
        display: 'flex',
        height: '91.65%',
    },
    left: {
        display: 'flex',
        flex: 2,
    },
    right: {
        display: 'flex',
        flex: 1,
        gap: '3rem',
        align: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    loginFields: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        width: '70%',
    },
    alertBox: {
        width: '70%',
    },

    loginTextField: {
        width: '100%',
    },
    loginButton: {
        height: '2.5rem',
    },
};
