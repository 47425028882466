import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { SxProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';

export interface CommentButtonProps {
    comments: UserComment[];
    sx: SxProps;
}

const CommentButton = (props: CommentButtonProps) => {
    const [open, setOpen] = useState(false);

    function handleClick() {
        setOpen(!open);
    }

    return (
        <IconButton
            color={open ? 'primary' : 'secondary'}
            sx={{ ...style.button, ...props.sx }}
            onClick={handleClick}
        >
            {props.comments?.length === 0 ? (
                <ModeCommentOutlinedIcon />
            ) : (
                <CommentOutlinedIcon />
            )}
        </IconButton>
    );
};

export default CommentButton;

const style: any = {
    button: {},
};
