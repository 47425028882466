import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from 'components/base/Button';
import { Box, DialogContentText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

// store
import {
    updateProject,
    selectProjects,
    selectIsLoading,
} from 'features/projects/state/projectState';

// types
import { VispakOfferStatusConst } from 'types/StatusTypes';
import { AppDispatch } from 'store';

export interface ModifyProjectDialogProps {
    projectId: string;
    isArchiveProjectDialogOpen: boolean;
    onCloseArchiveProjectDialog: () => void;
}

const ArchiveProjectDialog = (props: ModifyProjectDialogProps) => {
    // hooks

    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();

    // local state

    const [projectName, setProjectName] = useState('');

    // properties

    const isLoadingProjectState = useSelector(selectIsLoading);
    const projects = useSelector(selectProjects);
    const currentVisualOffering = projects.docs.find(
        (project) => project.id === props.projectId
    );
    const isPublicStatus =
        currentVisualOffering?.status === VispakOfferStatusConst.PUBLIC;
    const isArchivedStatus =
        currentVisualOffering?.status === VispakOfferStatusConst.ARCHIVED;
    const isArchivingDisabled = !isPublicStatus && !isArchivedStatus;

    // features

    const handleArchivingProject = async () => {
        if (!isArchivingDisabled) {
            if (isPublicStatus) {
                await dispatch(
                    updateProject({
                        id: props.projectId,
                        updateData: { status: VispakOfferStatusConst.ARCHIVED },
                    })
                ).catch((error) => {
                    console.error(
                        'Error archiving Visual Offering in UI:',
                        error
                    );
                });
            } else if (isArchivedStatus) {
                await dispatch(
                    updateProject({
                        id: props.projectId,
                        updateData: {
                            status: VispakOfferStatusConst.IN_PROGRESS,
                        },
                    })
                ).catch((error) => {
                    console.error(
                        'Error dearchiving Visual Offering in UI:',
                        error
                    );
                });
            }
        }
        props.onCloseArchiveProjectDialog();
    };

    // side effects

    useEffect(() => {
        if (currentVisualOffering) {
            setProjectName(currentVisualOffering.name);
        }
    }, [currentVisualOffering]);

    return (
        <Dialog
            open={props.isArchiveProjectDialogOpen}
            onClose={props.onCloseArchiveProjectDialog}
        >
            {/* Right top close button */}
            <IconButton
                aria-label="close"
                onClick={props.onCloseArchiveProjectDialog}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.main,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Title */}
            <Box component="div" sx={styles.titleBox}>
                <DialogTitle>
                    {isPublicStatus
                        ? t(`component.archiveProjectDialog.title`)
                        : t(`component.unarchiveProjectDialog.title`)}
                </DialogTitle>
            </Box>

            {/* Content */}
            <DialogContent>
                <Box style={{ margin: '1rem 0 1rem 0' }} component={'div'}>
                    {projectName}
                </Box>
                <DialogContentText>
                    {isPublicStatus
                        ? t(`component.archiveProjectDialog.description`)
                        : t(`component.unarchiveProjectDialog.description`)}
                </DialogContentText>
            </DialogContent>

            {/* Actions */}
            <DialogActions sx={styles.dialogActions}>
                <Button
                    sx={styles.buttonCancel}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => props.onCloseArchiveProjectDialog()}
                >
                    {t(`cancel`)}
                </Button>
                <Button
                    sx={styles.buttonSubmit}
                    variant={'contained'}
                    type="submit"
                    onClick={handleArchivingProject}
                    disabled={
                        projectName === '' ||
                        !currentVisualOffering ||
                        isArchivingDisabled ||
                        isLoadingProjectState
                    }
                >
                    {isLoadingProjectState ? (
                        <CircularProgress size={20} />
                    ) : (
                        t(`submit`)
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ArchiveProjectDialog;

const styles = {
    titleBox: {
        marginRight: '2rem',
    },
    dialogActions: {
        justifyContent: 'flex-end',
        gap: '0.4rem',
        margin: '0px 0.5rem 0.5rem 0px',
    },
    buttonCancel: {
        height: '2.8rem',
    },
    buttonSubmit: {
        height: '2.8rem',
    },
};
