export const colors = {
    orange: "#EE7037",
    black: "#000000",
    fGrey: "#8A8D8F",
    lGrey: "#F5F5F5",
    white: "#FFFFFF",
    borderGrey: "#d7d7d7",
    red: "#FF0000",
    green: "#09c309",
    rgba: {
        lightOrange: 'rgba(238, 112, 55, 0.05)',
    }
};