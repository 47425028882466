import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface AuthState {
    userDetails: VispakUser | null;
}

const initialState: AuthState = {
    userDetails: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserDetails(state, action: PayloadAction<VispakUser>) {
            state.userDetails = action.payload;
        },
        clearUserDetails(state, action: PayloadAction<void>) {
            state.userDetails = null;
        },
    },
});

// actions
export const { setUserDetails, clearUserDetails } = authSlice.actions;

// selectors

export const selectUserDetails = (state: RootState) => state.auth.userDetails;


export default authSlice.reducer;
