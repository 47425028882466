import { useRef } from 'react';
import * as THREE from 'three';
import { Vector3 } from 'three';

const DirectionalLight: React.FC = () => {
    const lightRef = useRef<THREE.DirectionalLight>(null);
    const lightPosition = new Vector3(1, 10, 1);
    const lightIntensity = 3;

    // Uncomment to use light helper for debugging
    // useHelper(lightRef.current, DirectionalLightHelper, 5, new THREE.Color('red'));

    return (
        <directionalLight
            ref={lightRef}
            position={lightPosition}
            intensity={lightIntensity}
            shadow-mapSize-width={4096}
            shadow-mapSize-height={4096}
            shadow-camera-far={100}
            shadow-camera-near={1}
            shadow-camera-left={-50}
            shadow-camera-right={50}
            shadow-camera-top={50}
            shadow-camera-bottom={-50}
            shadow-camera-bias={-0.005}
            castShadow
        />
    );
};

export default DirectionalLight;
