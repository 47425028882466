import React, {
    forwardRef,
    Suspense,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { Canvas, extend } from '@react-three/fiber';
import { OrthographicCamera, TransformControls } from '@react-three/drei';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import { GridOff, GridOn } from '@mui/icons-material';
import TopDownScene from './TopDownScene';
import { PlateType } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlates, updatePlates } from './moodboardState';
import { AppDispatch } from 'store';
extend({ TransformControls });

interface MoodboardProps {
    isDisabled: boolean;
}

const Moodboard = forwardRef<{ takeScreenshot: () => void }, MoodboardProps>(
    (props, ref) => {
    const [gridActive, setGridActive] = useState<boolean>(false);
    const plates: PlateType[] = useSelector(selectPlates);
    const dispatch: AppDispatch = useDispatch();
    const topDownSceneRef = useRef<{
        takeScreenshot: () => void;
        uploadScreenshot: () => Promise<Variation | null>;
    }>(null);

    // 'ref' is reference to the parent component (RoomView.tsx)
    // in the 'useImperativeHandle' new function 'takeScreenshot'
    // is declared and forwarded to the parent
    useImperativeHandle(ref, () => ({
        takeScreenshot() {
            if (topDownSceneRef.current) {
                setGridActive(false);
                // get the takeScreenshot function from the TopDownScene component
                topDownSceneRef.current.takeScreenshot();
            }
        },
        uploadScreenshot() {
            if (topDownSceneRef.current) {
                setGridActive(false);
                return topDownSceneRef.current.uploadScreenshot();
            }
        },
    }));

    const handleGridActive = () => {
        setGridActive(!gridActive);
    };

    const deletePlate = (id: string) => {
        const newPlates = plates.filter((plate) => plate.id !== id);
        dispatch(updatePlates(newPlates));
    };

    return (
        <Box component={'div'} width="100%" height="100%" position="relative">
            {props.isDisabled && (
                <Box
                    component={'div'}
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    bgcolor="rgba(50, 50, 50, 0.1)"
                    zIndex={10}
                >
                    <Box
                        component={'div'}
                        sx={styles.loadingOverlay}
                    >
                        <CircularProgress />
                    </Box>
                </Box>
            )}
            <Canvas shadows>
                <Suspense
                    fallback={
                        <Box
                            zIndex={1000}
                            component={'div'}
                            sx={styles.loadingOverlay}
                        >
                            <CircularProgress />
                        </Box>
                    }
                />
                <OrthographicCamera
                    makeDefault
                    position={[0, 50, 0]}
                    zoom={250}
                    rotation={[-Math.PI / 2, 0, 0]}
                />
                <TopDownScene
                    ref={topDownSceneRef}
                    gridActive={gridActive}
                    plates={plates}
                    deletePlate={deletePlate}
                />
            </Canvas>
            <Stack sx={{ position: 'absolute', bottom: '1%' }}>
                <IconButton onClick={handleGridActive}>
                    {gridActive ? <GridOn /> : <GridOff />}
                </IconButton>
            </Stack>
        </Box>
    );
});

export default Moodboard;

const styles = {
    loadingOverlay: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
};
