import { useTheme } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/material/styles';

export interface HeaderProps {
    children?: any;
    sx?: SxProps<Theme>;
}

const Header = (props: HeaderProps) => {
    const theme = useTheme();

    return (
        <h1
            style={{
                ...style,
                ...props.sx,
                color: `${theme.extra.text.color.primary}`,
            }}
        >
            {props.children}
        </h1>
    );
};

export default Header;

const style: any = {
    textAlign: 'left',
};
