// Start of Selection
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PLACEHOLDER_IMAGE_URL } from 'config';

// mui
import { Box, Typography, Grid } from '@mui/material';

// ui
import ProductItemOverview from './ProductItemOverview';
import { colors } from 'theming/colors';

// types
import {
    ProductListViewCategorizationType,
    ProductListViewVariation,
} from './ProductListView';
import { CategorizationTypeConst } from './ProductListView';

interface ProductDisplayOverviewProps {
    allPopulatedVariations: ProductListViewVariation[] | undefined;
    pimProducts: PIMProduct[];
    categorizationType: ProductListViewCategorizationType;
}

const ProductDisplayOverview = (props: ProductDisplayOverviewProps) => {
    const { t: translate } = useTranslation();

    // properties

    const baseUrl = window.location.origin;
    const { allPopulatedVariations, pimProducts, categorizationType } = props;

    const isCategorizationByProduct =
        categorizationType.id === CategorizationTypeConst.BY_PRODUCT;

    // categorized by product
    if (isCategorizationByProduct) {
        return (
            <Box component="div" sx={styles.container}>
                <Box component="div" sx={styles.productItemBox}>
                    {/* Product overview grid table */}
                    <Grid container spacing={0} columns={10}>
                        {pimProducts.length &&
                            pimProducts
                                // sort by product name a to z
                                .sort((a: PIMProduct, b: PIMProduct) =>
                                    a.name.localeCompare(b.name)
                                )
                                .map((product, index, array) => {
                                    return (
                                        // PIM product info overview
                                        <ProductItemOverview
                                            key={product.id}
                                            product={product}
                                            translate={translate}
                                            index={index}
                                            array={array}
                                        />
                                    );
                                })}
                    </Grid>
                </Box>
            </Box>
        );
    }

    // categorized by room
    return (
        <Box component="div" sx={styles.container}>
            {allPopulatedVariations
                // sort by room name a to z
                ?.sort((a, b) => a.roomName.localeCompare(b.roomName))
                ?.map((variation) => {
                    if (!variation || !variation.variationId) return null;

                    const snapshotURL =
                        variation?.variationSnapshot?.sizes?.thumbnail?.url ||
                        variation?.variationSnapshot?.sizes?.medium?.url ||
                        variation?.variationSnapshot?.sizes?.large?.url ||
                        variation?.variationSnapshot?.url ||
                        PLACEHOLDER_IMAGE_URL;

                    /* Product item */

                    return (
                        <React.Fragment key={variation.variationId}>
                            <Box
                                component="div"
                                key={variation.variationId}
                                sx={{
                                    ...styles.productItemBox,
                                }}
                            >
                                {/* Product item header */}

                                <Box component="div" sx={styles.roomGroup}>
                                    <Box
                                        component="div"
                                        sx={styles.roomGroupImageContainer}
                                    >
                                        <Box
                                            component="div"
                                            sx={styles.roomGroupImage}
                                        >
                                            <img
                                                src={snapshotURL}
                                                alt={'thumbnail'}
                                                onError={(
                                                    e: React.SyntheticEvent<
                                                        HTMLImageElement,
                                                        Event
                                                    >
                                                ) => {
                                                    e.currentTarget.onerror =
                                                        null;
                                                    e.currentTarget.src = `${baseUrl}${PLACEHOLDER_IMAGE_URL}`;
                                                }}
                                                style={{
                                                    width: '6rem',
                                                    height: '6rem',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        component="div"
                                        sx={styles.roomGroupTitle}
                                    >
                                        <Typography
                                            sx={{
                                                ...styles.roomGroupTitleText,
                                                fontWeight: '600',
                                            }}
                                        >
                                            {variation.roomName}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                ...styles.roomGroupTitleSubText,
                                            }}
                                        >
                                            {translate(
                                                'views.productList.variationName'
                                            ).toUpperCase()}
                                            {': '}
                                            {variation.variationName}
                                        </Typography>
                                    </Box>
                                </Box>

                                {/* Product item details - PIM product info */}
                                {/* Product overview grid table */}
                                <Grid container spacing={0} columns={10}>
                                    {pimProducts.length &&
                                        pimProducts
                                            .filter((product: PIMProduct) =>
                                                variation.variationData.plates.some(
                                                    (plate: any) =>
                                                        plate.pimProductId ===
                                                        product.id
                                                )
                                            )
                                            // sort by product name a to z
                                            .sort(
                                                (
                                                    a: PIMProduct,
                                                    b: PIMProduct
                                                ) =>
                                                    a.name.localeCompare(b.name)
                                            )
                                            .map(
                                                (
                                                    product: PIMProduct,
                                                    index: number,
                                                    array: PIMProduct[]
                                                ) => {
                                                    return (
                                                        // PIM product info overview
                                                        <ProductItemOverview
                                                            key={product.id}
                                                            product={product}
                                                            translate={
                                                                translate
                                                            }
                                                            index={index}
                                                            array={array}
                                                        />
                                                    );
                                                }
                                            )}
                                </Grid>
                            </Box>
                        </React.Fragment>
                    );
                })}
        </Box>
    );
};

export default ProductDisplayOverview;

const styles = {
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '0.5rem',

        boxSizing: 'border-box',
    },

    // -- Product item box --
    productItemBox: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '1rem',

        boxSizing: 'border-box',
        padding: '0.5rem 5rem',
    },

    // -- Product item header --
    roomGroup: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '1rem',

        paddingBottom: '1rem',
        borderBottom: '1px solid ' + colors.lGrey,
    },
    roomGroupImageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        maxWidth: '8rem',
        maxHeight: '8rem',
        aspectRatio: '1/ 1',
        borderRadius: '50%',
        overflow: 'hidden',
    },
    roomGroupImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    roomGroupTitle: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '0',
    },
    roomGroupTitleText: {
        fontSize: '1.2rem',
    },
    roomGroupTitleSubText: {
        fontSize: '0.8rem',
    },
};
