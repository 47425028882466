import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

// ui
import TextField from 'components/base/TextField';
import Button from 'components/base/Button';

// store
import {
    updateProject,
    selectProjects,
    selectIsLoading,
} from 'features/projects/state/projectState';

// types
import { AppDispatch } from 'store';

export interface ModifyProjectDialogProps {
    projectId: string;
    isModifyProjectDialogOpen: boolean;
    handleModifyProjectDialogClose: () => void;
}

const ModifyProjectDialog = (props: ModifyProjectDialogProps) => {
    // hooks

    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();

    // properties

    const isLoadingProjectState = useSelector(selectIsLoading);
    const projects = useSelector(selectProjects);
    const foundModifiableProject: VisualOffering | undefined =
        projects.docs.find((project) => project.id === props.projectId);

    // local state

    const [projectName, setProjectName] = useState('');
    const [partnerName, setPartnerName] = useState('');
    const [projectComment, setProjectComment] = useState('');

    // features

    const handleSubmit = async () => {
        if (projectComment && foundModifiableProject) {
            // add optional comments array with id if comment is previously
            // entered or just a 'comment' when no previous comments present
            const updateData = {
                name: projectName,
                ...(foundModifiableProject?.comments && {
                    comments: [
                        {
                            comment: projectComment,
                            ...(foundModifiableProject.comments[0]?.id && {
                                id: foundModifiableProject.comments[0].id,
                            }),
                        },
                    ],
                }),
            };

            // any update to the VO will reset the status back to 'in progress'
            await dispatch(
                updateProject({
                    id: props.projectId,
                    updateData,
                })
            ).catch((error) => {
                console.error('Error updating Visual Offering in UI:', error);
            });
        }

        props.handleModifyProjectDialogClose();
    };

    const handleClose = () => {
        props.handleModifyProjectDialogClose();
    };

    // side effects

    useEffect(() => {
        if (foundModifiableProject) {
            const comment: UserComment | undefined =
                foundModifiableProject.comments
                    ? foundModifiableProject.comments[0]
                    : undefined;
            setProjectName(foundModifiableProject.name);
            if (foundModifiableProject?.odooLead?.leadCustomerName) {
                setPartnerName(
                    foundModifiableProject.odooLead.leadCustomerName
                );
            }
            comment
                ? setProjectComment(comment.comment)
                : setProjectComment('');
        }
        // Make sure to rerender the component after opening modal!
        // When deleting name from input and closing without saving
        // it and without using 'isModifyProjectDialogOpen' dependency
        // then next time when opening modal the input is still empty
    }, [foundModifiableProject, props.isModifyProjectDialogOpen]);

    return (
        <Dialog open={props.isModifyProjectDialogOpen} onClose={handleClose}>
            {/* Right top close button */}
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.main,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Title */}
            <Box component="div" sx={styles.titleBox}>
                <DialogTitle>
                    {t(`component.modifyProjectDialog.title`)}
                </DialogTitle>
            </Box>

            {/* Content */}
            <DialogContent>
                <TextField
                    disabled={true}
                    variant="standard"
                    label={t(`component.modifyProjectDialog.changeName`)}
                    fullWidth
                    rows={4}
                    sx={{ marginTop: '1rem' }}
                    value={projectName}
                ></TextField>
                <TextField
                    disabled={true}
                    variant="standard"
                    label={t(`component.modifyProjectDialog.client`)}
                    fullWidth
                    rows={4}
                    sx={{ marginTop: '1rem' }}
                    value={partnerName}
                ></TextField>
                <TextField
                    variant="standard"
                    label={t(`component.newProjectDialog.commentLabel`)}
                    placeholder={t(`component.newProjectDialog.comment`)}
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ marginTop: '2rem' }}
                    onChange={(event) => {
                        setProjectComment(event.target.value);
                    }}
                    value={projectComment}
                ></TextField>
            </DialogContent>

            {/* Actions */}
            <DialogActions sx={styles.dialogActions}>
                <Button
                    sx={styles.buttonCancel}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => handleClose()}
                >
                    {t(`cancel`)}
                </Button>
                <Button
                    sx={styles.buttonSubmit}
                    variant={'contained'}
                    type="submit"
                    onClick={handleSubmit}
                    disabled={
                        !projectName ||
                        !foundModifiableProject ||
                        !projectComment ||
                        isLoadingProjectState
                    }
                >
                    {isLoadingProjectState ? (
                        <CircularProgress size={20} />
                    ) : (
                        t(`submit`)
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModifyProjectDialog;

const styles = {
    titleBox: {
        marginRight: '2rem',
    },
    dialogActions: {
        justifyContent: 'flex-end',
        gap: '0.4rem',
        margin: '0px 0.5rem 0.5rem 0px',
    },
    buttonCancel: {
        height: '2.8rem',
    },
    buttonSubmit: {
        height: '2.8rem',
    },
};
