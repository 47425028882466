import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useRedirectIfNoId } from 'utils';

// mui
import {
    Box,
    Button,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material';
import {
    DownloadOutlined,
    ExitToAppOutlined,
    MenuOutlined,
    RectangleOutlined,
} from '@mui/icons-material';

// ui
import { colors } from 'theming/colors';
import BreadcrumbNavigation from 'components/BreadcrumbNavigation';
import ProductDisplayDetailed from './ProductDisplayDetailed';
import ProductDisplayOverview from './ProductDisplayOverview';

// store
import {
    selectSelectedProject,
    selectSpacialSolutions,
} from 'features/projects/state/projectState';

// services
import { PIMService } from 'services/PIMService';

// types

enum DisplayTypeConst {
    DETAILED = 'detailed',
    OVERVIEW = 'overview',
}

export enum CategorizationTypeConst {
    BY_ROOM = 'byRoom',
    BY_PRODUCT = 'byProduct',
}

// These values are meant to be used like:
// string.toLowerCase().includes(ProductFeatureName.XX.toLowerCase())
// because the values might not be exact with BE values.
// It's for a bit of future proofing for PIM changes.
// In NOV 2024 the ID field was not recommended to be used that's why
// the feature names are defined here.
//
// TODO: verify this for future possible PIM changes.
export enum ProductFeatureName {
    PLATE_FORMAT = 'plaadi formaat',
    WIDTH_MM = 'laius (mm)',
    LENGTH_MM = 'pikkus (mm)',
    WIDTH_CM = 'laius (cm)',
    LENGTH_CM = 'pikkus (cm)',
    THICKNESS = 'paksus (mm)', // 'Lõnga paksus (mm)' & 'Kulumiskihi paksus (mm)' also exists
    REFINEMENT = 'pinnaviimistlus',
    CO2 = 'co2',
    COLOR = 'värvitoon',
}

export interface ProductListViewVariation {
    roomName: string;
    roomId: string;
    variationId: string;
    variationName: string;
    variationData: any; // Plates object from variation.data
    variationSnapshot: Snapshot | null;
    solutionName: string;
    solutionId: string;
}

export interface ProductListViewCategorizationType {
    id: CategorizationTypeConst.BY_ROOM | CategorizationTypeConst.BY_PRODUCT;
    name: string; // translated text
}

const ProductListView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const categorizationTypes = [
        {
            id: CategorizationTypeConst.BY_ROOM,
            name: t('views.productList.byRoom'),
        },
        {
            id: CategorizationTypeConst.BY_PRODUCT,
            name: t('views.productList.byProduct'),
        },
    ];

    // TODO: tooltip for selected display type
    const displayTypes = [
        {
            id: DisplayTypeConst.DETAILED,
            name: t('views.productList.displayDetailed'),
        },
        {
            id: DisplayTypeConst.OVERVIEW,
            name: t('views.productList.displayOverview'),
        },
    ];

    // local state

    const [selectedCategorizationType, setSelectedCategorizationType] =
        useState<(typeof categorizationTypes)[0]>(categorizationTypes[0]);

    const [activeDisplay, setActiveDisplay] = useState<DisplayTypeConst>(
        DisplayTypeConst.DETAILED
    );

    const [pimProducts, setPimProducts] = useState<PIMProduct[]>([]);

    // properties

    const selectedProject = useSelector(selectSelectedProject);

    const spacialSolutions = useSelector(selectSpacialSolutions, shallowEqual);

    const redirectIfNoId = useRedirectIfNoId(
        selectedProject ? [selectedProject] : []
    );

    // get all variations
    // also filter out deleted rooms and variations
    const allPopulatedVariations: ProductListViewVariation[] | undefined =
        useMemo(() => {
            const processedVariations =
                spacialSolutions
                    .flatMap((solution) =>
                        solution.rooms
                            ?.filter((room) => !room.deleted)
                            ?.flatMap((room) =>
                                room.variations
                                    ?.filter((variation) => !variation.deleted)
                                    ?.map((variation) => ({
                                        roomName: room.name,
                                        roomId: room.id,
                                        variationId: variation.id,
                                        variationName: variation.name,
                                        variationData: variation.data
                                            ? JSON.parse(variation.data)
                                            : null,
                                        // get the latest snapshot if any
                                        variationSnapshot: variation?.snapshots
                                            ? variation.snapshots.reduce(
                                                  (latest, current) =>
                                                      new Date(
                                                          current.createdAt
                                                      ) >
                                                      new Date(latest.createdAt)
                                                          ? current
                                                          : latest,
                                                  variation.snapshots[0]
                                              )
                                            : null,
                                        solutionName: solution.name,
                                        solutionId: solution.id,
                                    }))
                            )
                    )
                    ?.filter(
                        (variation): variation is ProductListViewVariation =>
                            variation?.variationData?.plates.length
                    ) || [];

            return processedVariations;
        }, [spacialSolutions]);

    // features

    const handleDetailedDisplay = () => {
        setActiveDisplay(DisplayTypeConst.DETAILED);
    };

    const handleOverviewDisplay = () => {
        setActiveDisplay(DisplayTypeConst.OVERVIEW);
    };

    // side effects

    // auth check
    useEffect(() => {
        redirectIfNoId();
    }, [redirectIfNoId]);

    // request PIM data when variations change
    useEffect(() => {
        if (allPopulatedVariations) {
            (async () => {
                const pimIds = allPopulatedVariations.flatMap(
                    (variation: ProductListViewVariation) =>
                        variation.variationData.plates.flatMap(
                            (plate: any) => plate.pimProductId
                        )
                );

                // remove duplicates
                const uniquePimIds = Array.from(new Set(pimIds));
                const pimService = new PIMService();
                const products: PIMProductsResponse =
                    await pimService.getProductsByIds(uniquePimIds);

                if (products.docs.length) {
                    setPimProducts(products.docs);
                }
            })();
        }
    }, [allPopulatedVariations]);

    return (
        <>
            <Divider />

            {/* Product list header */}
            <Stack sx={styles.productListHeader} id="product-list-header">
                <Box component="div" sx={styles.headerNavigation}>
                    <BreadcrumbNavigation />
                </Box>

                <Box component="div" sx={styles.headerMainInfo}>
                    <Box component="div" sx={styles.headerMainInfoLeftSide}>
                        <Stack>
                            <Typography
                                sx={{ fontSize: '12px', color: colors.fGrey }}
                            >
                                {t('visualOffer').toUpperCase() + ':'}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    color: colors.black,
                                    fontWeight: 600,
                                }}
                            >
                                {selectedProject?.name}
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography
                                sx={{ fontSize: '12px', color: colors.fGrey }}
                            >
                                {t(
                                    'views.projectList.thDesigner'
                                ).toUpperCase() + ':'}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    color: colors.black,
                                    fontWeight: 400,
                                }}
                            >
                                {selectedProject?.author.name}
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography
                                sx={{ fontSize: '12px', color: colors.fGrey }}
                            >
                                {t(
                                    'views.projectList.thAddress'
                                ).toUpperCase() + ':'}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    color: colors.black,
                                    fontWeight: 400,
                                }}
                            >
                                {selectedProject?.odooLead?.address}
                            </Typography>
                        </Stack>
                    </Box>

                    <Box component="div" sx={styles.headerMainInfoRightSide}>
                        <IconButton
                            onClick={() => {
                                navigate(-1);
                            }}
                            sx={getIconButtonStyle(false)}
                            disabled={false}
                        >
                            <ExitToAppOutlined />
                            <Typography sx={styles.headerButtonText}>
                                {t(`back`)}
                            </Typography>
                        </IconButton>
                    </Box>
                </Box>
            </Stack>

            {/* Product list actions */}
            <Box component="div">
                <Typography sx={styles.productListActionsTitle}>
                    {t('views.productList.categorizeBy')}
                </Typography>
            </Box>
            <Box component="div" sx={styles.productListActions}>
                <Box component="div" sx={styles.productListActionsLeftSide}>
                    {/* Input Select for categorization */}
                    <Box
                        component="div"
                        sx={styles.productListActionsInputSelect}
                    >
                        <FormControl
                            variant="standard"
                            fullWidth
                            sx={styles.actionInputSelectFormControl}
                        >
                            <Select
                                labelId="product-list-select-label"
                                id="product-list-select"
                                value={selectedCategorizationType.id}
                                onChange={(e) => {
                                    setSelectedCategorizationType(
                                        categorizationTypes.find(
                                            (type) => type.id === e.target.value
                                        ) || categorizationTypes[0]
                                    );
                                }}
                                size="small"
                                sx={{
                                    minHeight: '30px',
                                    paddingY: '4px',
                                    '& .MuiSelect-select': {
                                        paddingY: '4px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                }}
                            >
                                {categorizationTypes.map((type) => (
                                    <MenuItem
                                        key={type.id}
                                        value={type.id}
                                        sx={{
                                            paddingY: '4px',
                                            fontSize: '0.875rem',
                                        }}
                                    >
                                        {type.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {/* Detailed view IconButton*/}
                    <Box
                        component="div"
                        sx={{
                            ...styles.productListActionsIcon,
                            marginLeft: '0.4rem',
                        }}
                    >
                        <IconButton
                            onClick={handleDetailedDisplay}
                            sx={{
                                ...getIconButtonStyle(false),
                                color:
                                    activeDisplay === DisplayTypeConst.DETAILED
                                        ? colors.orange
                                        : colors.black,
                            }}
                        >
                            <RectangleOutlined />
                        </IconButton>
                    </Box>

                    {/* Overview IconButton*/}
                    <Box component="div" sx={styles.productListActionsIcon}>
                        <IconButton
                            onClick={handleOverviewDisplay}
                            sx={{
                                ...getIconButtonStyle(false),
                                color:
                                    activeDisplay === DisplayTypeConst.OVERVIEW
                                        ? colors.orange
                                        : colors.black,
                            }}
                        >
                            <MenuOutlined />
                        </IconButton>
                    </Box>
                </Box>
                <Box component="div" sx={styles.productListActionsRightSide}>
                    <IconButton
                        onClick={() => {}}
                        disabled={false}
                        sx={getIconButtonStyle(false)}
                    >
                        <DownloadOutlined />
                        <Typography sx={styles.headerButtonText}>
                            {t(`downloadPDF`)}
                        </Typography>
                    </IconButton>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            padding: '6px 10px',
                            fontSize: '0.80rem',
                            textTransform: 'none',
                            borderRadius: '2px',
                        }}
                    >
                        {t('views.productList.requestDemoProduct')}
                    </Button>
                </Box>
            </Box>

            {/* <Divider sx={{ borderColor: colors.lGrey }} /> */}

            {/* Product list */}
            {activeDisplay === DisplayTypeConst.DETAILED && (
                <ProductDisplayDetailed
                    allPopulatedVariations={allPopulatedVariations}
                    pimProducts={pimProducts}
                    categorizationType={selectedCategorizationType}
                />
            )}
            {activeDisplay === DisplayTypeConst.OVERVIEW && (
                <ProductDisplayOverview
                    allPopulatedVariations={allPopulatedVariations}
                    pimProducts={pimProducts}
                    categorizationType={selectedCategorizationType}
                />
            )}
        </>
    );
};

export default ProductListView;

const styles = {
    // Product list header
    productListHeader: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',

        marginLeft: 0,
        marginRight: 0,
        width: '100%',
        boxSizing: 'border-box',
    },
    headerNavigation: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5rem 5rem',
        boxSizing: 'border-box',
        width: '100%',
    },
    headerMainInfo: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,

        width: '100%',
        padding: '1rem 5rem 1rem 5rem',

        backgroundColor: colors.lGrey,
        marginBottom: 1,
        boxSizing: 'border-box',
    },
    headerMainInfoLeftSide: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: { xs: '0 1rem', md: '0 2rem', lg: '0 4rem' },
    },
    headerMainInfoRightSide: {
        display: 'flex',
        alignItems: 'center',
        gap: 2,
    },
    headerButtonText: {
        marginLeft: '0.5rem',
        fontSize: '1rem',
        color: colors.black,
    },

    // Product list actions
    productListActionsTitle: {
        fontSize: '0.6rem',
        color: colors.black,
        padding: '0.5rem 5rem 0rem 5rem',
    },
    productListActions: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 5rem 1rem 5rem',
    },
    productListActionsLeftSide: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        gap: 0,
    },
    productListActionsRightSide: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        gap: 2,
    },
    productListActionsInputSelect: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',

        width: '10rem',
    },
    actionInputSelectFormControl: {
        marginY: 0,
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${colors.borderGrey}`,
        },
        '& .MuiInput-underline:after': {
            borderBottom: `1px solid ${colors.borderGrey}`,
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: `1px solid ${colors.borderGrey}`,
        },
        '& .MuiSelect-select': {
            paddingY: '4px',
            display: 'flex',
            alignItems: 'center',
        },
    },
    productListActionsIcon: {
        display: 'flex',
        alignItems: 'center',
    },
};

const getIconButtonStyle = (isDisabled: boolean) => ({
    opacity: isDisabled ? 0.2 : 1,
    color: colors.black,
    '&:hover': {
        backgroundColor: 'transparent',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
    },
    pointerEvents: isDisabled ? 'none' : 'auto',
});
