import axios, { AxiosResponse } from 'axios';
import { API_URL, AUTH_COOKIE_NAME } from 'config';
import { CookieService } from 'services/cookie/CookieService';

export class OdooService {
    public url;
    public token;
    public config;

    constructor() {
        this.url = `${API_URL}/v0/leads`;
        this.token = CookieService.getCookieByName(AUTH_COOKIE_NAME);
        this.config = {
            headers: {
                Authorization: `Bearer ${this.token}`,
            },
        };
    }

    public async getLeadsByName(name: string): Promise<OdooLead[]> {
        try {
            const response: AxiosResponse<OdooLead[]> = await axios.get(this.url, {
                ...this.config,
                params: {
                    name,
                },
            });
            return response.data;
        } catch (error: any) {
            throw new Error('Getting leads failed:', error?.message);
        }
    }
}
