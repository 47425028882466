import React, { ReactNode, useEffect, useRef, useState } from 'react';

import Drawer from '@mui/material/Drawer';
import { AddOutlined } from '@mui/icons-material';

import Button from 'components/base/Button';
import { getElementDistanceFromTop } from 'utils';

export interface ConfiguratorPanelProps {
    children: ReactNode;
    isClosed: boolean;
    setPanelClose: (value: boolean) => void;
}

const ConfiguratorPanel = (props: ConfiguratorPanelProps) => {
    const canvasRef = useRef<HTMLElement | null>(null);

    const [distanceFromTop, setDistanceFromTop] = useState<number>(0);

    // features

    const calculateDistanceFromTop = () => {
        if (canvasRef.current) {
            const distance = getElementDistanceFromTop(canvasRef);
            setDistanceFromTop(distance);
        }
    };

    // side effects

    // for menu Button positioning
    useEffect(() => {
        calculateDistanceFromTop();
        window.addEventListener('resize', () => {
            calculateDistanceFromTop();
        });

        return () => {
            window.removeEventListener('resize', () => {
                calculateDistanceFromTop();
            });
        };
    }, [props]);

    // for menu Button positioning
    useEffect(() => {
        canvasRef.current = document.getElementsByTagName('canvas')[0];
    }, []);

    return (
        <>
            <Button
                onClick={() => props.setPanelClose(!props.isClosed)}
                sx={{
                    ...style.button,
                    top: distanceFromTop
                        ? `${distanceFromTop + 32}px`
                        : '12rem',
                }}
            >
                <AddOutlined />
            </Button>
            <Drawer
                slotProps={{
                    backdrop: {
                        style: { opacity: 0 },
                    },
                }}
                PaperProps={{ sx: style.drawer }}
                variant="temporary"
                anchor="left"
                open={!props.isClosed}
                onClose={() => props.setPanelClose(!props.isClosed)}
                // hideBackdrop
            >
                {props.children}
            </Drawer>
        </>
    );
};

export default ConfiguratorPanel;

const style = {
    drawer: {
        // position: 'absolute',
        // top: '5rem',
        '& .MuiDrawer-modal': {
            opacity: '0%',
        },
    },
    button: {
        position: 'absolute',
        top: '12rem',
        left: '3rem',
    },
};
