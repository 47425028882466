import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import initI18next from './l10n/i18n.js';
import { RouterProvider } from 'react-router-dom';
import router from './routes';

import light from './theming/light';

initI18next();

function App() {
    return (
        <ThemeProvider theme={light}>
            <RouterProvider router={router}></RouterProvider>
        </ThemeProvider>
    );
}

export default App;
