import React from 'react';

import { Box, Typography } from '@mui/material';
import { colors } from 'theming/colors';

const AnimatedToggle = ({
    isOn,
    text,
    onClick,
    disabled = false,
}: {
    isOn: boolean;
    text: string;
    onClick: () => void;
    disabled?: boolean;
}) => {
    return (
        <Box
            component="div"
            onClick={disabled ? undefined : onClick}
            sx={{
                cursor: disabled ? 'not-allowed' : 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                opacity: disabled ? 0.5 : 1,
            }}
        >
            <Typography>{text}</Typography>
            <svg
                width="44"
                height="24"
                viewBox="0 0 44 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="2"
                    y="2"
                    width="40"
                    height="20"
                    rx="10"
                    fill="transparent"
                    stroke={isOn ? colors.orange : colors.black}
                    strokeWidth="2"
                    style={{ opacity: disabled ? 0.5 : 1 }}
                />
                <circle
                    cx={isOn ? '32' : '12'}
                    cy="12"
                    r="8"
                    fill={isOn ? colors.orange : colors.black}
                    style={{
                        transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                    }}
                />
            </svg>
        </Box>
    );
};

export default AnimatedToggle;
