import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './locale/en/US.json';
import etTranslations from './locale/et/ET.json';

export default function initI18next() {
  i18next
    // Plug in i18next React extensions.
    .use(initReactI18next)
    .init({
      resources: {
        'en-US': {
          // i18next uses the `translation` namespace by default.
          translation: enTranslations,
        },
        'et-EE': {
          translation: etTranslations,
        },
      },

      // Set the default language to Estonian.
      lng: 'et-EE',

      // Disable i18next's default escaping, which prevents XSS
      // attacks. React already takes care of this.
      interpolation: {
        escapeValue: false,
      },
    });
}
