import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// mui
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Box, CircularProgress, Stack } from '@mui/material';

// ui
import Room from './Room';
import NewRoomButton from './NewRoomButton';
import { colors } from 'theming/colors';
import Tag from 'components/base/Tag';
import SolutionOptions from './SolutionOptions';
import NewRoomDialog from 'components/specific/project/NewRoomDialog';
import SolutionOptionsMenu from 'components/specific/project/SolutionOptionsMenu';
import DeleteSolutionDialog from 'components/specific/project/DeleteSolutionDialog';
import ModifySolutionDialog from 'components/specific/project/ModifySolutionDialog';

// store
import {
    selectIsLoading,
    selectDesignCodeProject,
} from 'features/projects/state/projectState';

// types
import { VispakRoomStatusConst } from 'types/StatusTypes';

export interface SolutionProps {
    key: string;
    index: number;
    array: SpacialSolution[]; // not soft deleted
    allSolutions: SpacialSolution[] | undefined; // all solutions including soft deleted
    id: string;
    name: string;
    rooms: Room[] | undefined;
    expanded?: boolean;
    currentProject?: VisualOffering;
}

const Solution = (props: SolutionProps) => {
    const { t } = useTranslation();

    // local state

    const [solutionOptionsMenuId, setSolutionOptionsMenuId] = useState('');
    const [solutionOptionsMenuAnchorEl, setSolutionOptionsMenuAnchorEl] =
        useState<null | HTMLElement>(null);

    const [isDeleteSolutionDialogOpen, setIsDeleteSolutionDialogOpen] =
        useState(false);
    const [isAddNewRoomDialogOpen, setIsAddNewRoomDialogOpen] = useState(false);
    const [isModifySolutionDialogOpen, setIsModifySolutionDialogOpen] =
        useState(false);

    // properties

    const designCodeProject = useSelector(selectDesignCodeProject);

    // if regular user session, filter out deleted rooms
    // if design code session, filter out deleted rooms and rooms with no public status
    const notSoftDeletedRooms: Room[] | undefined = props.rooms?.filter(
        (room) => {
            const isRoomPublic = designCodeProject
                ? room.status === VispakRoomStatusConst.PUBLIC
                : true;
            return !room.deleted && isRoomPublic;
        }
    );

    const isRoomLimit =
        !!notSoftDeletedRooms && notSoftDeletedRooms?.length >= 10;
    const nrOfRoomsInCurrentSolution = props.rooms?.length
        ? props.rooms.filter((room) => !room.deleted).length
        : 0;

    const isLoading = useSelector(selectIsLoading);

    const isFirstIndex = props.index === 0;
    const isLastIndex = props.index === props.array.length - 1;

    // features

    const solutionShouldBeExpanded = () => {
        if (!props.allSolutions) return false;
        const nonDeletedSolutions = props.allSolutions.filter(
            (s: SpacialSolution) => !s.deleted
        );
        const firstNonDeletedSolution = nonDeletedSolutions[0];
        return firstNonDeletedSolution?.id === props.id;
    };

    const handleSolutionMenuClose = () => {
        setSolutionOptionsMenuAnchorEl(null);
    };
    const openSolutionOptionsMenu = (event: any, itemId: string) => {
        setSolutionOptionsMenuAnchorEl(event.currentTarget);
        setSolutionOptionsMenuId(itemId);
    };

    const handleNewRoomClick = () => {
        setIsAddNewRoomDialogOpen(true);
    };

    const handleNewRoomModalClose = () => {
        setIsAddNewRoomDialogOpen(false);
    };

    return (
        <Paper
            key={props.id}
            style={style.solutionContainer}
            sx={{
                marginTop: isFirstIndex ? '2rem' : '1rem',
                marginBottom: isLastIndex ? '3rem' : '1rem',
            }} // sx is for dynamic styling
        >
            <Accordion
                defaultExpanded={solutionShouldBeExpanded()}
                sx={{ boxShadow: 'none', borderRadius: '0px' }}
            >
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                                flexDirection: 'row-reverse',
                                '& .MuiAccordionSummary-expandIconWrapper': {
                                    transform: 'rotate(-90deg)',
                                },
                                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded':
                                    {
                                        color: colors.orange,
                                        transform: 'rotate(0deg)',
                                    },
                                '& .MuiAccordionSummary-content': {
                                    marginLeft: 1,
                                },
                                fontWeight: 600,
                            }}
                        >
                            {props.name}
                        </AccordionSummary>
                        <Tag color="#dddddd" sx={{ fontWeight: 700 }}>
                            {nrOfRoomsInCurrentSolution + ' '}
                            {t('plurals.rooms', {
                                count: nrOfRoomsInCurrentSolution,
                            })}
                        </Tag>
                    </Stack>
                    <SolutionOptions
                        disabled={isLoading || !!designCodeProject}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                            openSolutionOptionsMenu(event, props.id)
                        }
                    />
                </Stack>

                <AccordionDetails>
                    {isLoading && (
                        <Box component={'div'} sx={style.loadingContainer}>
                            <CircularProgress size={32} />
                        </Box>
                    )}
                    <Grid container gap={2}>
                        {notSoftDeletedRooms?.map((room: Room) => {
                            if (!room?.id) return null;
                            const variationsWithoutSoftDelete =
                                room.variations?.filter(
                                    (variation) => !variation.deleted
                                );
                            return (
                                <Grid key={`room-${room.id}`}>
                                    <Room
                                        key={room.id}
                                        id={room.id}
                                        name={room.name}
                                        comments={room.comments}
                                        variations={variationsWithoutSoftDelete}
                                        status={room.status}
                                        roomImageUrl=""
                                        solutionId={props.id}
                                    />
                                </Grid>
                            );
                        })}
                        {/* If design code session || isLoading || isRoomLimit - hide button */}
                        {!isLoading && !isRoomLimit && !designCodeProject && (
                            <NewRoomButton
                                disabled={isRoomLimit || isLoading}
                                onClick={handleNewRoomClick}
                            />
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>

            {/* Modals */}
            <SolutionOptionsMenu
                itemID={solutionOptionsMenuId}
                className="threePoints"
                open={Boolean(solutionOptionsMenuAnchorEl)}
                onClose={handleSolutionMenuClose}
                anchorEl={solutionOptionsMenuAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                setIsDeleteSolutionDialogOpen={() =>
                    setIsDeleteSolutionDialogOpen(true)
                }
                setIsModifySolutionDialogOpen={() =>
                    setIsModifySolutionDialogOpen(true)
                }
            />
            <DeleteSolutionDialog
                solutionId={props.id}
                isDeleteSolutionDialogOpen={isDeleteSolutionDialogOpen}
                onCloseDeleteSolutionDialog={() => {
                    setIsDeleteSolutionDialogOpen(false);
                }}
            />
            <ModifySolutionDialog
                solutionId={props.id}
                isModifySolutionDialogOpen={isModifySolutionDialogOpen}
                onCloseModifySolutionDialog={() => {
                    setIsModifySolutionDialogOpen(false);
                }}
            />
            <NewRoomDialog
                solutionId={props.id}
                isOpen={isAddNewRoomDialogOpen}
                onClose={handleNewRoomModalClose}
            />
        </Paper>
    );
};

export default Solution;

const style: any = {
    solutionContainer: {
        boxShadow: 'none',
        borderRadius: '0px',
    },
    loadingContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 10,
    },
};
