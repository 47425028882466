import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AUTH_COOKIE_NAME } from 'config';
import { appPath } from 'routes';

// ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Divider, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    CompareOutlined,
    ListOutlined,
    EmailOutlined,
} from '@mui/icons-material';
import Button from 'components/base/Button';
import Solution from 'components/specific/project/Solution';
import NewSolutionDialog from 'components/specific/project/NewSolutionDialog';
import { colors } from 'theming/colors';
import StatusTag from 'components/StatusTag';
import OfferMenu from 'features/projects/components/OfferMenu';
import BreadcrumbNavigation from 'components/BreadcrumbNavigation';

// store
import {
    selectProjects,
    selectSelectedProject,
    setGlobalModalOfferSend,
    fetchProjectById,
    selectIsLoading,
    selectDesignCodeProject,
} from 'features/projects/state/projectState';
import { selectUserDetails } from 'features/authentication/state/authState';

// services
import { CookieService } from 'services/cookie/CookieService';

// helpers
import { visualOfferHasAnyPublicRooms } from 'utils';

// types
import {
    VispakOfferStatus,
    VispakOfferStatusConst,
    VispakRoomStatusConst,
} from 'types/StatusTypes';
import { AppDispatch } from 'store';

export interface ProjectViewProps {
    isVisitor?: boolean;
}

const ProjectView = (props: ProjectViewProps) => {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    // local state

    const [newSolutionModalOpen, setNewSolutionModalOpen] = useState(false);
    const [activeProjectId, setActiveProjectId] = useState('');
    const [menuAnchorElProjectMenu, setMenuAnchorElProjectMenu] =
        useState<null | HTMLElement>(null);

    // properties

    // if set it's a design code session (store is populated with designCodeProject)
    const designCodeProject = useSelector(selectDesignCodeProject);

    const projects = useSelector(selectProjects);
    const selectedProjectFromStore = useSelector(selectSelectedProject);
    const userDetails = useSelector(selectUserDetails);
    const isLoading = useSelector(selectIsLoading);
    const authCookie = CookieService.getCookieByName(AUTH_COOKIE_NAME);

    // changing/deleting project is updated in 'state.projects' only
    const selectedProject =
        (selectedProjectFromStore &&
            projects.docs.find(
                (project) => project.id === selectedProjectFromStore.id
            )) ||
        designCodeProject; // select design code project if no selected project

    const offerStatus = selectedProject?.status as VispakOfferStatus;

    // if regular user session, filter out deleted solutions
    // if design code session, filter out deleted solutions
    // and also solutions with no public rooms present
    const notSoftDeletedSolutions: SpacialSolution[] | undefined =
        !designCodeProject
            ? selectedProject?.spacialSolutions?.filter(
                  (solution) => !solution.deleted
              )
            : designCodeProject?.spacialSolutions?.filter((solution) => {
                  // Filter out deleted solutions
                  if (solution.deleted) return false;

                  // Filter out solutions with no public rooms
                  const hasPublicRooms = solution.rooms?.some(
                      (room) =>
                          !room.deleted &&
                          room.status === VispakRoomStatusConst.PUBLIC
                  );

                  return hasPublicRooms ?? false;
              }) ?? [];

    const isSolutionLimit =
        notSoftDeletedSolutions && notSoftDeletedSolutions.length >= 5;

    const currentVisualOffering = projects.docs.find(
        (project) => project.id === selectedProject?.id
    );

    const isPublic = offerStatus === VispakOfferStatusConst.PUBLIC;

    // features

    const handleNewSolution = () => {
        setNewSolutionModalOpen(true);
    };

    const handleRequestOffer = () => {
        // TODO
    };

    const handleCompare = () => {
        navigate(appPath.comparison);
    };

    const handleProductList = () => {
        navigate(appPath.productList);
    };

    const handlePresentOffer = () => {
        // for shared component <SendOfferDialog />
        if (currentVisualOffering)
            dispatch(
                setGlobalModalOfferSend({
                    open: true,
                    id: currentVisualOffering.id,
                })
            );
    };

    // refresh or token expiry
    const redirectIfNoId = useCallback(async () => {
        if (authCookie) {
            if (!userDetails?.id) {
                // no user details - possibly refresh
                navigate(appPath.projects);
            }
        } else {
            // no auth cookie & no design code project
            if (!designCodeProject) {
                navigate(appPath.login);
            }
        }
    }, [authCookie, userDetails, navigate, designCodeProject]);

    const openProjectOptionsMenu = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        const selectedProjectId = selectedProject?.id;
        if (selectedProjectId) {
            setMenuAnchorElProjectMenu(event.currentTarget);
            setActiveProjectId(selectedProjectId);
        }
    };

    // side effects

    useEffect(() => {
        redirectIfNoId();
    }, [redirectIfNoId, userDetails, designCodeProject]);

    useEffect(() => {
        // fetch currently selected project data
        if (selectedProject?.id) {
            dispatch(fetchProjectById(selectedProject.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const rootDiv = document.getElementById('root');
        if (rootDiv) {
            rootDiv.style.backgroundColor = colors.lGrey;
        }

        return () => {
            if (rootDiv) {
                rootDiv.style.backgroundColor = '';
            }
        };
    }, []);

    return (
        <>
            <Divider />
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                paddingRight={'5rem'}
                sx={{ backgroundColor: colors.white }}
            >
                <BreadcrumbNavigation />
                <Stack direction={'row'} alignItems={'center'} gap={2}>
                    <Stack direction={'row'} alignItems={'center'} gap={'2px'}>
                        <Typography
                            sx={{ fontSize: '12px', color: colors.fGrey }}
                        >
                            {t('views.projectList.thAddress').toUpperCase() +
                                ':'}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '13px',
                                color: colors.black,
                                fontWeight: 600,
                            }}
                        >
                            {selectedProject?.odooLead?.address}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} gap={'2px'}>
                        <Typography
                            sx={{ fontSize: '12px', color: colors.fGrey }}
                        >
                            {t('views.projectList.thDesigner').toUpperCase() +
                                ':'}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '13px',
                                color: colors.black,
                                fontWeight: 600,
                            }}
                        >
                            {selectedProject?.author.name}
                        </Typography>
                    </Stack>

                    <Stack direction={'row'} alignItems={'center'} gap={'2px'}>
                        <Typography
                            sx={{ fontSize: '12px', color: colors.fGrey }}
                        >
                            {t('views.projectList.code').toUpperCase() + ':'}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '13px',
                                color: colors.black,
                                fontWeight: 600,
                            }}
                        >
                            {selectedProject?.designCode}
                        </Typography>
                    </Stack>

                    <Stack direction={'row'} alignItems={'center'} gap={'2px'}>
                        <StatusTag status={offerStatus} />
                    </Stack>

                    <Stack direction={'row'} alignItems={'center'} gap={'2px'}>
                        <IconButton
                            onClick={openProjectOptionsMenu}
                            aria-label={t(`edit`)}
                            disabled={isLoading || !!designCodeProject}
                        >
                            <MoreVertIcon className="VisualOffering-more-details" />
                        </IconButton>
                    </Stack>
                </Stack>
            </Stack>
            <Divider />

            <Box component={'div'} sx={style.parentBox}>
                <Box component={'div'} sx={style.actionRow}>
                    <h2 style={style.header}>{selectedProject?.name}</h2>
                    <Grid
                        container
                        display="flex"
                        margin="0"
                        justifyContent="flex-end"
                        gap={2}
                        sx={{ marginLeft: 'auto' }}
                    >
                        <IconButton
                            onClick={handleCompare}
                            disabled={isLoading}
                            sx={getIconButtonStyle(isLoading)}
                        >
                            <CompareOutlined />
                            <Typography sx={style.headerButtonText}>
                                {t(`views.project.compare`)}
                            </Typography>
                        </IconButton>
                        <IconButton
                            onClick={handleProductList}
                            disabled={isLoading || !!designCodeProject}
                            sx={getIconButtonStyle(isLoading)}
                        >
                            <ListOutlined />
                            <Typography sx={style.headerButtonText}>
                                {t(`views.project.productList`)}
                            </Typography>
                        </IconButton>
                        {props.isVisitor ? (
                            <Button
                                onClick={handleRequestOffer}
                                variant="outlined"
                                color="secondary"
                                disabled={isLoading}
                            >
                                + {t(`views.project.requestOffer`)}
                            </Button>
                        ) : (
                            // Send offer button
                            <IconButton
                                onClick={handlePresentOffer}
                                disabled={
                                    isLoading ||
                                    !visualOfferHasAnyPublicRooms(
                                        currentVisualOffering
                                    ) ||
                                    !!designCodeProject
                                }
                                sx={getIconButtonStyle(isLoading)}
                            >
                                <EmailOutlined />
                                <Typography sx={style.headerButtonText}>
                                    {t(`views.project.presentOffer`)}
                                </Typography>
                            </IconButton>
                        )}
                        <Button
                            disabled={
                                isLoading ||
                                isSolutionLimit ||
                                !!designCodeProject
                            }
                            onClick={handleNewSolution}
                        >
                            + {t(`views.project.newSolution`)}
                        </Button>
                    </Grid>
                </Box>
                {notSoftDeletedSolutions?.map(
                    (solution: SpacialSolution, index, array) => {
                        return !solution.deleted ? (
                            <Solution
                                key={solution.id}
                                index={index}
                                array={array}
                                allSolutions={selectedProject?.spacialSolutions}
                                id={solution.id}
                                name={solution.name}
                                rooms={solution?.rooms}
                                currentProject={selectedProject}
                            />
                        ) : null;
                    }
                )}
            </Box>

            {/* Modals */}
            <NewSolutionDialog
                open={newSolutionModalOpen}
                onClose={() => setNewSolutionModalOpen(false)}
            />
            <OfferMenu
                onCloseOfferMenu={() => setMenuAnchorElProjectMenu(null)}
                menuAnchorElProjectMenu={menuAnchorElProjectMenu}
                activeProjectId={activeProjectId}
            />
        </>
    );
};

export default ProjectView;

const style: any = {
    parentBox: {
        paddingTop: '2rem',
        paddingLeft: '5rem',
        paddingRight: '5rem',
        display: 'flex',
        height: 'auto',
        flexDirection: 'column',
        backgroundColor: colors.lGrey,
    },
    actionRow: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
    },
    header: {
        margin: 0,
        display: 'inline',
    },
    headerButtonText: {
        marginLeft: '0.5rem',
        fontSize: '1rem',
        color: colors.black,
    },
};

const getIconButtonStyle = (isDisabled: boolean) => ({
    opacity: isDisabled ? 0.2 : 1,
    color: colors.black,
    '&:hover': {
        backgroundColor: 'transparent',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
    },
    pointerEvents: isDisabled ? 'none' : 'auto',
});
