import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { generatePlateFromProduct } from 'utils';
import { MAX_NR_OF_PLATES_ON_MOODBOARD } from 'config';

// mui
import {
    Stack,
    Box,
    InputAdornment,
    Input,
    Grid,
    Button,
    CircularProgress,
} from '@mui/material';
import { Search } from '@mui/icons-material';

// ui
import MaterialPickerFilter from './MaterialPickerFilter';
import ProductImage from './ProductImage';

// store
import {
    selectPIMIsLoading,
    selectPIMProducts,
    selectPIMFilterCollections,
    selectPIMFilterGroups,
    selectPIMFilterManufacturers,
    selectPIMFilterSubGroups,
    selectPIMFilterAllFeatures,
    fetchFilterCollections,
    fetchFilterGroups,
    fetchFilterManufacturers,
    fetchFilterSubGroups,
    fetchFilterAllFeatures,
    fetchProducts,
    fetchProductsBySearchTerm,
} from 'features/pim/pimSlice';
import { AppDispatch } from 'store';
import { selectPlates, updatePlates } from 'features/moodboard/moodboardState';

// types
import { PlateType } from 'features/moodboard/types';
import { ProductFeatureName } from 'features/productList/ProductListView';

// Feature constants

const COLOR_FEATURE_ID = '663e98bfb873d44b1833337c';
const COLOR_FEATURE_NAME = ProductFeatureName.COLOR;

const PLATE_FORMAT_FEATURE_ID = '667ed2b99fb35b74101cc755';
const PLATE_FORMAT_FEATURE_NAME = ProductFeatureName.PLATE_FORMAT;

const THICKNESS_FEATURE_ID = '6643292362d1eaf993fe169c';
const THICKNESS_FEATURE_NAME = ProductFeatureName.THICKNESS;

export enum FiltersConst {
    COLLECTION = 'collection',
    GROUP = 'group',
    MANUFACTURER = 'manufacturer',
    SUBGROUP = 'subgroup',
    FEATURES = 'features',
    FEATURE_VALUES = 'featureValues',
}

interface MaterialPickerProps {
    isClosed: boolean;
    setPanelClose: (value: boolean) => void;
}

const MaterialPicker = (props: MaterialPickerProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const rightPanelRef = useRef<HTMLDivElement>(null);

    // local state

    const [filteredProducts, setFilteredProducts] = useState<PIMProduct[]>([]);
    const [selectedFilterCollections, setSelectedFilterCollections] = useState<
        string[]
    >([]);
    const [selectedFilterGroups, setSelectedFilterGroups] = useState<string[]>(
        []
    );
    const [selectedFilterManufacturers, setSelectedFilterManufacturers] =
        useState<string[]>([]);
    const [selectedFilterSubGroups, setSelectedFilterSubGroups] = useState<
        string[]
    >([]);

    // to filter out and show only filter items for (collections, groups, subgroups)
    // where there is a relation to the PIM group (UI category)
    const [autoCheckedGroupId, setAutoCheckedGroupId] = useState<string | null>(
        null
    );

    // TODO: not used atm but needed for BE features filtering
    // currently local filtering is used instead for feature filters
    const [selectedFilterFeatures, setSelectedFilterFeatures] = useState<
        string[]
    >([]);

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [allProducts, setAllProducts] = useState<PIMProduct[]>([]);
    const [selectedFeatureValues, setSelectedFeatureValues] = useState<
        string[]
    >([]);

    // properties

    const productsPerPage = 50;
    const filterCollections = useSelector(selectPIMFilterCollections);
    const filterGroups = useSelector(selectPIMFilterGroups);
    const filterManufacturers = useSelector(selectPIMFilterManufacturers);
    const filterSubGroups = useSelector(selectPIMFilterSubGroups);
    const filterAllFeatures: PIMFilterFeature[] = useSelector(
        selectPIMFilterAllFeatures
    );
    const products: PIMProduct[] = useSelector(selectPIMProducts)?.docs;
    const totalPages = useSelector(selectPIMProducts)?.totalPages;
    const plates = useSelector(selectPlates);
    const isLoading = useSelector(selectPIMIsLoading);

    // features

    const getFeatureValues = (feature: PIMFilterFeature): string[] => {
        if (feature.valueLimits) {
            return feature.valueLimits.split(',').map((value) => value.trim());
        }
        return [];
    };

    const onProductClick = (product: PIMProduct) => {
        props.setPanelClose(true);

        if (plates.length >= MAX_NR_OF_PLATES_ON_MOODBOARD) {
            alert(t('moodboard.platesLimitExceeded'));
            return;
        }
        addNewProductPlate(product);
    };

    // add new product to moodboard
    const addNewProductPlate = (product: PIMProduct) => {
        const plate: PlateType = generatePlateFromProduct(product);

        dispatch(updatePlates([...plates, plate]));
    };

    const applyFilters = useCallback(
        (
            newSelectedCollections: string[],
            newSelectedGroups: string[],
            newSelectedManufacturers: string[],
            newSelectedSubGroups: string[],
            newSelectedFeatures: string[],
            newSelectedFeatureValues: string[],
            currentSearchTerm: string
        ) => {
            if (allProducts.length === 0) return;

            const searchTermLower = currentSearchTerm.toLowerCase();

            const newFilteredProducts = allProducts
                // returns true to keep the product and false to filter it out
                .filter((product: PIMProduct) => {
                    // Search term filter
                    if (
                        !product.name?.toLowerCase().includes(searchTermLower)
                    ) {
                        return false;
                    }

                    // Collection filter
                    if (
                        newSelectedCollections.length > 0 &&
                        !newSelectedCollections.includes(
                            product?.category?.value?.id
                        )
                    ) {
                        return false;
                    }

                    // Group filter
                    if (
                        newSelectedGroups.length > 0 &&
                        !newSelectedGroups.includes(
                            product?.category?.value?.group?.value?.id
                        )
                    ) {
                        return false;
                    }

                    // Manufacturer filter
                    if (
                        newSelectedManufacturers.length > 0 &&
                        !newSelectedManufacturers.includes(
                            product?.category?.value?.factory?.value?.id
                        )
                    ) {
                        return false;
                    }

                    // Subgroup filter
                    // NB! product.category.value.subGroup is an array
                    // and it should be used over product.subGroup.value
                    if (
                        newSelectedSubGroups.length > 0 &&
                        !product?.category?.value?.subGroup?.some(
                            (subGroup: PIMProductSubGroup) =>
                                newSelectedSubGroups.includes(subGroup.value.id)
                        )
                    ) {
                        return false;
                    }

                    // Features filter (for mass feature filtering)
                    // if (
                    //     newSelectedFeatures.length > 0 &&
                    //     !product.featuresArray?.some(feature =>
                    //         newSelectedFeatures.includes(feature.feature?.value?.id)
                    //     )
                    // ) {
                    //     return false;
                    // }

                    // Feature values filter (colors, plate format, thickness)
                    if (newSelectedFeatureValues.length > 0) {
                        // Group selected values by feature type
                        const selectedColorValues =
                            newSelectedFeatureValues.filter((val) => {
                                const colorFeature = filterAllFeatures?.find(
                                    (f) =>
                                        f?.name
                                            ?.toLowerCase()
                                            .includes(
                                                COLOR_FEATURE_NAME.toLowerCase()
                                            ) &&
                                        f?.valueLimits
                                            ?.toLowerCase()
                                            .includes(val?.toLowerCase())
                                );
                                return colorFeature !== undefined;
                            });

                        const selectedFormatValues =
                            newSelectedFeatureValues.filter((val) => {
                                const formatFeature = filterAllFeatures?.find(
                                    (f) =>
                                        f?.name
                                            ?.toLowerCase()
                                            .includes(
                                                PLATE_FORMAT_FEATURE_NAME.toLowerCase()
                                            ) &&
                                        f?.valueLimits
                                            ?.toLowerCase()
                                            .includes(val?.toLowerCase())
                                );
                                return formatFeature !== undefined;
                            });

                        const selectedThicknessValues =
                            newSelectedFeatureValues.filter((val) => {
                                const thicknessFeature =
                                    filterAllFeatures?.find(
                                        (f) =>
                                            f?.name?.toLowerCase() ===
                                                THICKNESS_FEATURE_NAME.toLowerCase() &&
                                            f?.valueLimits
                                                ?.toLowerCase()
                                                .includes(val?.toLowerCase())
                                    );
                                return thicknessFeature !== undefined;
                            });

                        // Check if product matches selected feature values
                        if (selectedColorValues.length > 0) {
                            const colorFeature = product?.featuresArray?.find(
                                (feature) =>
                                    feature?.feature?.value?.name
                                        ?.toLowerCase()
                                        ?.includes(
                                            COLOR_FEATURE_NAME.toLowerCase()
                                        )
                            );
                            if (!colorFeature?.featureValue) return false;

                            const productColorValues =
                                colorFeature.featureValue
                                    ?.split(',')
                                    ?.map((val) => val?.trim()?.toLowerCase())
                                    ?.filter(Boolean) || [];

                            if (
                                !selectedColorValues.some((val) =>
                                    productColorValues.includes(
                                        val?.toLowerCase()
                                    )
                                )
                            ) {
                                return false;
                            }
                        }

                        if (selectedFormatValues.length > 0) {
                            const formatFeature = product?.featuresArray?.find(
                                (feature) =>
                                    feature?.feature?.value?.name
                                        ?.toLowerCase()
                                        ?.includes(
                                            PLATE_FORMAT_FEATURE_NAME.toLowerCase()
                                        )
                            );
                            if (!formatFeature?.featureValue) return false;

                            const productFormatValues =
                                formatFeature.featureValue
                                    ?.split(',')
                                    ?.map((val) => val?.trim()?.toLowerCase())
                                    ?.filter(Boolean) || [];

                            if (
                                !selectedFormatValues.some((val) =>
                                    productFormatValues.includes(
                                        val?.toLowerCase()
                                    )
                                )
                            ) {
                                return false;
                            }
                        }

                        if (selectedThicknessValues.length > 0) {
                            const thicknessFeature =
                                product?.featuresArray?.find(
                                    (feature) =>
                                        feature?.feature?.value?.name?.toLowerCase() ===
                                        THICKNESS_FEATURE_NAME.toLowerCase()
                                );
                            if (!thicknessFeature?.featureValue) return false;

                            const productThicknessValues =
                                thicknessFeature.featureValue
                                    ?.split(',')
                                    ?.map((val) => val?.trim()?.toLowerCase())
                                    ?.filter(Boolean) || [];

                            if (
                                !selectedThicknessValues.some((val) =>
                                    productThicknessValues.includes(
                                        val?.toLowerCase()
                                    )
                                )
                            ) {
                                return false;
                            }
                        }
                    }

                    return true;
                })
                .sort((a, b) => a.name.localeCompare(b.name));

            // debugging: leave on for testing
            console.log('_______Filtered products______');
            console.log(newFilteredProducts);
            setFilteredProducts(newFilteredProducts);
        },
        [allProducts, filterAllFeatures]
    );

    const debouncedApplyFilters = useMemo(
        () =>
            debounce(
                (
                    newSelectedCollections: string[],
                    newSelectedGroups: string[],
                    newSelectedManufacturers: string[],
                    newSelectedSubGroups: string[],
                    newSelectedFeatures: string[],
                    newSelectedFeatureValues: string[],
                    currentSearchTerm: string
                ) => {
                    applyFilters(
                        newSelectedCollections,
                        newSelectedGroups,
                        newSelectedManufacturers,
                        newSelectedSubGroups,
                        newSelectedFeatures,
                        newSelectedFeatureValues,
                        currentSearchTerm
                    );
                },
                300 // delay
            ),
        [applyFilters]
    );

    const onGeneralFilterCheckBoxClick = (
        item: PIMFilterGroup | PIMFilterCollection | PIMFilterFeature,
        checked: boolean,
        filterType: FiltersConst,
        featureValue?: string
    ) => {
        let newSelectedCollections = [...selectedFilterCollections];
        let newSelectedGroups = [...selectedFilterGroups];
        let newSelectedManufacturers = [...selectedFilterManufacturers];
        let newSelectedSubGroups = [...selectedFilterSubGroups];
        let newSelectedFeatures = [...selectedFilterFeatures];
        let newSelectedFeatureValues = [...selectedFeatureValues];

        switch (filterType) {
            // PIM category - UI Collection
            case FiltersConst.COLLECTION:
                setFilteredProducts([]);

                newSelectedCollections = checked
                    ? [...selectedFilterCollections, item.id]
                    : selectedFilterCollections.filter((id) => id !== item.id);

                // if selecting a collection filter our not related filters
                if (checked) {
                    const collection = filterCollections?.find(
                        (c) => c.id === item.id
                    );

                    // get related group filter data from selected collection
                    const groupId = collection?.group?.value?.id || null;

                    // render only groups, subgroups & collections which have
                    // relation to the selected filter PIM group
                    setAutoCheckedGroupId(groupId);
                } else {
                    // if no more collections selected
                    if (!newSelectedCollections.length) {
                        // clear auto filtering
                        setAutoCheckedGroupId(null);

                        // clear checkboxes
                        setSelectedFilterGroups([]);
                        setSelectedFilterSubGroups([]);
                    }
                }

                setSelectedFilterCollections(newSelectedCollections);
                break;

            // PIM group - UI Category
            case FiltersConst.GROUP:
                setFilteredProducts([]);

                newSelectedGroups = checked
                    ? [...selectedFilterGroups, item.id]
                    : selectedFilterGroups.filter((id) => id !== item.id);

                if (checked) {
                    const group = filterGroups?.find((g) => g.id === item.id);
                    const groupId = group?.id || null;

                    setAutoCheckedGroupId(groupId);
                } else {
                    if (!newSelectedGroups.length) {
                        // clear auto filtering
                        setAutoCheckedGroupId(null);

                        // clear checkboxes
                        setSelectedFilterCollections([]);
                        setSelectedFilterSubGroups([]);
                    }
                }

                setSelectedFilterGroups(newSelectedGroups);
                break;

            case FiltersConst.MANUFACTURER:
                newSelectedManufacturers = checked
                    ? [...selectedFilterManufacturers, item.id]
                    : selectedFilterManufacturers.filter(
                          (id) => id !== item.id
                      );
                setSelectedFilterManufacturers(newSelectedManufacturers);
                break;

            case FiltersConst.FEATURES:
                const isFeatureValueFilter =
                    (item?.name?.toLowerCase()?.includes(COLOR_FEATURE_NAME) ||
                        item?.name
                            ?.toLowerCase()
                            ?.includes(PLATE_FORMAT_FEATURE_NAME) ||
                        item?.name?.toLowerCase() ===
                            THICKNESS_FEATURE_NAME.toLowerCase()) &&
                    featureValue;

                if (isFeatureValueFilter) {
                    newSelectedFeatureValues = checked
                        ? [...selectedFeatureValues, featureValue]
                        : selectedFeatureValues.filter(
                              (v) => v !== featureValue
                          );
                    setSelectedFeatureValues(newSelectedFeatureValues);
                    // for mass feature filtering by other filters
                    // besides the features defined in isFeatureValueFilter
                } else {
                    newSelectedFeatures = checked
                        ? [...selectedFilterFeatures, item.id]
                        : selectedFilterFeatures.filter((id) => id !== item.id);
                    setSelectedFilterFeatures(newSelectedFeatures);
                }
                break;

            // PIM subgroup - UI Subcategory
            case FiltersConst.SUBGROUP:
                setFilteredProducts([]);

                newSelectedSubGroups = checked
                    ? [...selectedFilterSubGroups, item.id]
                    : selectedFilterSubGroups.filter((id) => id !== item.id);

                if (checked) {
                    const subgroup = filterSubGroups?.find(
                        (g) => g.id === item.id
                    );
                    const groupId = subgroup?.group?.value?.id || null;

                    setAutoCheckedGroupId(groupId);
                } else {
                    if (!newSelectedSubGroups.length) {
                        // clear auto filtering
                        setAutoCheckedGroupId(null);

                        // clear checkboxes
                        setSelectedFilterCollections([]);
                        setSelectedFilterGroups([]);
                    }
                }

                setSelectedFilterSubGroups(newSelectedSubGroups);
                break;
        }

        debouncedSearch(
            searchTerm,
            productsPerPage,
            newSelectedCollections,
            newSelectedGroups,
            newSelectedManufacturers,
            newSelectedSubGroups,
            newSelectedFeatures
        );
    };

    const fetchProductsByTerm = useCallback(
        async (
            limit: number,
            term: string,
            collections: string[],
            groups: string[],
            manufacturers: string[],
            subGroups: string[],
            features: string[] // BE filtering (not used atm)
        ) => {
            dispatch(
                fetchProductsBySearchTerm({
                    limit,
                    page: 1,
                    depth: 3,
                    searchTerm: term,
                    collections,
                    groups,
                    manufacturers,
                    subGroups,
                    features,
                })
            ).then(() => {
                setPage(1);
            });
        },
        [dispatch]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce(
            (
                term: string,
                limit: number = productsPerPage,
                collections: string[] = selectedFilterCollections,
                groups: string[] = selectedFilterGroups,
                manufacturers: string[] = selectedFilterManufacturers,
                subGroups: string[] = selectedFilterSubGroups,
                features: string[] = selectedFilterFeatures
            ) => {
                fetchProductsByTerm(
                    limit,
                    term,
                    collections,
                    groups,
                    manufacturers,
                    subGroups,
                    features // BE filtering (not used atm)
                );
            },
            300 // delay
        ),
        []
    );

    const onSearchChange = (
        ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newSearchTerm = ev.currentTarget.value;
        setSearchTerm(newSearchTerm);
        debouncedSearch(newSearchTerm);
    };

    const loadMoreProducts = useCallback(() => {
        if (!isLoading && page < totalPages) {
            dispatch(
                fetchProductsBySearchTerm({
                    limit: productsPerPage,
                    page: page + 1,
                    depth: 2,
                    searchTerm,
                    collections: selectedFilterCollections,
                    groups: selectedFilterGroups,
                    manufacturers: selectedFilterManufacturers,
                    subGroups: selectedFilterSubGroups,
                    features: selectedFilterFeatures,
                })
            ).then(() => {
                setPage((prevPage) => prevPage + 1);

                // Scroll to top after loading more products
                rightPanelRef.current?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            });
        }
    }, [
        dispatch,
        isLoading,
        page,
        totalPages,
        searchTerm,
        selectedFilterCollections,
        selectedFilterGroups,
        selectedFilterManufacturers,
        selectedFilterSubGroups,
        selectedFilterFeatures,
        rightPanelRef,
    ]);

    const resetFilters = () => {
        setSelectedFilterCollections([]);
        setSelectedFilterGroups([]);
        setSelectedFilterManufacturers([]);
        setSelectedFilterSubGroups([]);
        setSelectedFilterFeatures([]);
        setSelectedFeatureValues([]);
        setSearchTerm('');
        setPage(1);
        setFilteredProducts([]);
        setAllProducts([]);

        // clear auto filtering
        setAutoCheckedGroupId(null);

        // fetch new products
        dispatch(fetchProducts({ limit: productsPerPage, page: 1, depth: 2 }));
    };

    // side effects

    useEffect(() => {
        // depth 2 to go as deep as
        // product.category?.value?.{factory/group}?.value?.id
        dispatch(fetchProducts({ limit: productsPerPage, page: 1, depth: 2 }));

        // PIM categories (UI collections)
        dispatch(fetchFilterCollections({ limit: 200, depth: 1 })); // 154 in total nov.24
        // PIM groups (UI categories)
        dispatch(fetchFilterGroups({ limit: 50 })); // 16 in total nov.24
        // PIM subgroups (UI sub-categories)
        dispatch(fetchFilterSubGroups({ limit: 100 })); // 54 in total nov.24

        dispatch(fetchFilterManufacturers({ limit: 100 })); // 82 in total nov.24
        dispatch(fetchFilterAllFeatures({ limit: 100 })); // 53 in total nov.24
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO: NB! Possible performance improvement option
    // instead of local allProducts state, use redux selector
    // with filtering and processing
    useEffect(() => {
        if (products) {
            setAllProducts((prevProducts) => {
                const uniqueProducts = products.filter(
                    (product) => !prevProducts.some((p) => p.id === product.id)
                );
                return [...prevProducts, ...uniqueProducts];
            });
        }
    }, [products]);

    useEffect(() => {
        if (allProducts.length > 0) {
            debouncedApplyFilters(
                selectedFilterCollections,
                selectedFilterGroups,
                selectedFilterManufacturers,
                selectedFilterSubGroups,
                selectedFilterFeatures,
                selectedFeatureValues,
                searchTerm
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allProducts, selectedFeatureValues]);

    useEffect(() => {
        // Cleanup ongoing debounced functions on unmount
        return () => {
            debouncedApplyFilters.cancel();
            debouncedSearch.cancel();
        };
    }, [debouncedApplyFilters, debouncedSearch]);

    return (
        <Stack direction="row">
            <Stack
                sx={style.leftPanel}
                direction="column"
                className="vispak-scrollbar"
            >
                {/* Input search and Reset button */}
                <Box component={'div'} sx={style.search}>
                    <Input
                        id="input-with-icon-adornment"
                        startAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                        placeholder={t(`materialPicker.placeholder`)}
                        onChange={(ev) => onSearchChange(ev)}
                        value={searchTerm}
                        fullWidth
                    />
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={resetFilters}
                        size="small"
                        sx={{ marginTop: '1rem', fontSize: '0.8rem' }}
                    >
                        {t(`materialPicker.resetFilters`)}
                    </Button>
                </Box>
                {/* PIM Group filters - UI name is Category */}
                <Box component={'div'} sx={style.filterGroups}>
                    <MaterialPickerFilter
                        filterName={t(`materialPicker.group`)}
                        filterArray={[...filterGroups]
                            .filter(
                                (c) =>
                                    !autoCheckedGroupId ||
                                    c.id === autoCheckedGroupId
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))}
                        selectedFilterGroups={selectedFilterGroups}
                        onCheckBoxClick={(item, checked) =>
                            onGeneralFilterCheckBoxClick(
                                item,
                                checked,
                                FiltersConst.GROUP
                            )
                        }
                        filterType={FiltersConst.GROUP}
                    />
                </Box>
                {/* PIM Subgroup filters - UI name is Subcategory */}
                <Box component={'div'} sx={style.filterSubGroups}>
                    <MaterialPickerFilter
                        filterName={t(`materialPicker.subgroup`)}
                        filterArray={[...filterSubGroups]
                            .filter(
                                (c) =>
                                    !autoCheckedGroupId ||
                                    c.group?.value?.id === autoCheckedGroupId
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))}
                        selectedFilterGroups={selectedFilterSubGroups}
                        onCheckBoxClick={(item, checked) =>
                            onGeneralFilterCheckBoxClick(
                                item,
                                checked,
                                FiltersConst.SUBGROUP
                            )
                        }
                        filterType={FiltersConst.SUBGROUP}
                    />
                </Box>
                {/* Manufacturer filters */}
                <Box component={'div'} sx={style.filterManufacturers}>
                    <MaterialPickerFilter
                        filterName={t(`materialPicker.manufacturer`)}
                        filterArray={[...filterManufacturers].sort((a, b) =>
                            a.name.localeCompare(b.name)
                        )}
                        selectedFilterGroups={selectedFilterManufacturers}
                        onCheckBoxClick={(item, checked) =>
                            onGeneralFilterCheckBoxClick(
                                item,
                                checked,
                                FiltersConst.MANUFACTURER
                            )
                        }
                    />
                </Box>
                {/* PIM Category filters - UI name is Collection */}
                <Box component={'div'} sx={style.filterCollections}>
                    <MaterialPickerFilter
                        filterName={t(`materialPicker.collection`)}
                        filterArray={[...filterCollections]
                            .filter(
                                (c: PIMFilterCollection) =>
                                    !autoCheckedGroupId ||
                                    c.group?.value?.id === autoCheckedGroupId
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))}
                        selectedFilterGroups={selectedFilterCollections}
                        onCheckBoxClick={(item, checked) =>
                            onGeneralFilterCheckBoxClick(
                                item,
                                checked,
                                FiltersConst.COLLECTION
                            )
                        }
                        filterType={FiltersConst.COLLECTION}
                    />
                </Box>
                {/* Features filter with sorted order */}
                <Box component={'div'} sx={style.filterFeatures}>
                    {[...filterAllFeatures]
                        .sort((a, b) => {
                            const getPriority = (feature: PIMFilterFeature) => {
                                const name = feature.name.toLowerCase();
                                if (
                                    name.includes(
                                        COLOR_FEATURE_NAME.toLowerCase()
                                    )
                                )
                                    return 1;
                                if (
                                    name.includes(
                                        PLATE_FORMAT_FEATURE_NAME.toLowerCase()
                                    )
                                )
                                    return 2;
                                if (
                                    name ===
                                    THICKNESS_FEATURE_NAME.toLowerCase()
                                )
                                    return 3;
                                return 4; // Default priority for other features
                            };
                            return getPriority(a) - getPriority(b);
                        })
                        .map((feature) => {
                            const isColorFeature = feature.name
                                .toLowerCase()
                                .includes(COLOR_FEATURE_NAME.toLowerCase());
                            const isFormatFeature = feature.name
                                .toLowerCase()
                                .includes(
                                    PLATE_FORMAT_FEATURE_NAME.toLowerCase()
                                );
                            const isThicknessFeature =
                                feature.name.toLowerCase() ===
                                THICKNESS_FEATURE_NAME.toLowerCase();

                            const isFilterableFeature =
                                isColorFeature ||
                                isFormatFeature ||
                                isThicknessFeature;
                            const featureValues = isFilterableFeature
                                ? getFeatureValues(feature)
                                : undefined;

                            return (
                                isFilterableFeature && (
                                    <MaterialPickerFilter
                                        key={feature.id}
                                        filterName={feature.name}
                                        filterArray={[feature]}
                                        selectedFilterGroups={
                                            selectedFilterFeatures
                                        }
                                        selectedFeatureValues={
                                            selectedFeatureValues
                                        }
                                        featureValues={featureValues}
                                        onCheckBoxClick={(
                                            item,
                                            checked,
                                            featureValue
                                        ) =>
                                            onGeneralFilterCheckBoxClick(
                                                item,
                                                checked,
                                                FiltersConst.FEATURES,
                                                featureValue
                                            )
                                        }
                                    />
                                )
                            );
                        })}
                </Box>
            </Stack>
            <Stack
                component="div"
                ref={rightPanelRef}
                sx={style.rightPanel}
                direction="column"
                className="vispak-scrollbar"
            >
                <Grid container>
                    <Grid item xs={12}>
                        {filteredProducts?.map((product) => {
                            return (
                                <ProductImage
                                    key={`${product.id}-${product.name}`}
                                    product={product}
                                    onProductClick={onProductClick}
                                />
                            );
                        })}
                    </Grid>
                </Grid>
                {isLoading && (
                    <Box component={'div'} sx={style.loadingContainer}>
                        <CircularProgress size={24} />
                    </Box>
                )}
                {!isLoading && page < totalPages && (
                    <Button
                        onClick={loadMoreProducts}
                        sx={style.loadMoreButton}
                    >
                        {t(`materialPicker.loadMore`)}
                    </Button>
                )}
            </Stack>
        </Stack>
    );
};

export default MaterialPicker;

const style = {
    leftPanel: {
        marginLeft: '2rem',
        paddingRight: '1rem',
        marginTop: '2rem',
        width: '20rem',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 'calc(94vh)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    rightPanel: {
        marginLeft: '2rem',
        marginTop: '2rem',
        width: '30rem',
        maxHeight: '90vh',
        overflowY: 'auto',
    },
    search: {
        marginBottom: '0rem',
    },
    filterCollections: {},
    filterGroups: {},
    filterManufacturers: {},
    filterSubGroups: {},
    filterFeatures: {},
    loadMoreButton: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
    },
};
