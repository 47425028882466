import React from 'react';
import { useTranslation } from 'react-i18next';

// ui
import Paper from '@mui/material/Paper';
import { colors } from '../../../theming/colors';
import { Button, Stack, Typography } from '@mui/material';
import { GridAddIcon } from '@mui/x-data-grid';

export interface NewRoomButtonProps {
    disabled: boolean;
    onClick: () => void;
}

const NewRoomButton = (props: NewRoomButtonProps) => {
    const i18n = useTranslation();

    // properties

    const addRoomText = i18n.t('views.project.addRoom');

    // features

    const handleNewRoomClick = () => {
        props.onClick();
    };

    return (
        <Button
            sx={style.buttonContainer}
            onClick={handleNewRoomClick}
            disabled={props.disabled}
            TouchRippleProps={{ style: { color: colors.fGrey } }}
        >
            <Paper sx={style.paper}>
                <Stack
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <GridAddIcon sx={{ fontSize: '1.5rem' }} />
                    <div style={{ height: '1rem' }} />
                    <Typography
                        sx={{
                            textDecoration: 'underline',
                            fontSize: '0.875rem',
                        }}
                    >
                        {addRoomText}
                    </Typography>
                </Stack>
            </Paper>
        </Button>
    );
};

export default NewRoomButton;

const style: any = {
    buttonContainer: {
        width: '268px',
        aspectRatio: '6 / 5',
        margin: '0rem',
        padding: '0',
    },
    paper: {
        height: '100%',
        width: '100%',
        boxShadow: 'none',
        backgroundColor: colors.white,
        borderWidth: '2px',
        borderStyle: 'dashed',
        borderColor: colors.lGrey,
    },
};
