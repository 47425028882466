import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Account from '../Account';
import LanguageSelector from '../LanguageSelector';
import { useSelector } from 'react-redux';
import { selectUserDetails } from '../../features/authentication/state/authState';
import { useTranslation } from 'react-i18next';
import { colors } from 'theming/colors';
import { appPath } from 'routes';
import { useLocation, useParams } from 'react-router-dom';
import { selectDesignCodeProject } from 'features/projects/state/projectState';

export interface TopBarProps {
    height?: any;
    imageSource: string;
    imageAlt: string;
    imageStyle?: any;
    username?: string;
    logoutText: string;
    settingsText: string;
    languageCode: string;
    languages: any;
}

const TopBar = (props: TopBarProps) => {
    const isDesignCodeProject = useSelector(selectDesignCodeProject);
    const user = useSelector(selectUserDetails);
    const { t: translate } = useTranslation();
    const location = useLocation();
    const { roomId } = useParams();

    const isRoomPath =
        !!roomId &&
        location?.pathname.includes(appPath.room.replace(':roomId', roomId));

    const isComparisonPath = location?.pathname.includes(appPath.comparison);

    if (isRoomPath || isComparisonPath) return null;

    return (
        <Grid2
            display="flex"
            flexDirection="row"
            sx={{ height: props.height, ...style.topbar }}
        >
            <img
                style={{ ...style.image, ...props.imageStyle }}
                src={props.imageSource}
                alt={props.imageAlt}
                height="90px"
            ></img>
            <Grid2 xs display="flex" flexDirection="row-reverse">
                <Account
                    username={
                        !isDesignCodeProject
                            ? user?.name
                            : translate('designCode')
                    }
                    logoutText={translate('logout')}
                    settingsText={translate('settings')}
                    designCodeProject={isDesignCodeProject}
                />
                <LanguageSelector
                    languageCode={props.languageCode}
                    languages={props.languages}
                />
            </Grid2>
        </Grid2>
    );
};

export default TopBar;

const style: any = {
    topbar: {
        paddingLeft: '5rem',
        paddingRight: '5rem',
        height: '4rem',
        borderWidth: '1px',
        backgroundColor: colors.white,
    },
    image: {
        width: 'auto',
        maxHeight: '100%',
        marginLeft: '-0.6rem',
    },
};
