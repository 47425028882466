import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { LinkProps as MuiLinkProps } from '@mui/material/Link';

export interface LinkProps extends MuiLinkProps {
    to: string;
}

const Link = (props: LinkProps) => {
    const theme = useTheme();

    return (
        <RouterLink to={props.to}>
            <span
                style={{
                    ...style,
                    ...props.sx,
                    color: `${theme.extra.text.color.secondary}`,
                }}
            >
                {props.children}
            </span>
        </RouterLink>
    );
};

export default Link;

const style: any = {
    textDecoration: 'underline',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'left',
};
