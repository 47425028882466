import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface LocalizationState {
    currentLanguage: string;
}

const initialState: LocalizationState = {
    currentLanguage: 'et-EE',
};

const localizationSlice = createSlice({
    name: 'localization',
    initialState,
    reducers: {
        setCurrentLanguage(state, action)
        {
            state.currentLanguage = action.payload;
        }
    },
});

// actions

export const {
    setCurrentLanguage,
} = localizationSlice.actions;

// selectors
export const selectCurrentLanguage = (state: RootState) => state.localization.currentLanguage;


export default localizationSlice.reducer;
