import { SxProps } from '@mui/material';
import TagText from './text/TagText';

export interface TagProps {
    children?: any;
    color?: string;
    textColor?: string;
    sx?: SxProps;
}

const Tag = (props: TagProps) => {
    return (
        <span
            style={{
                ...style,
                ...props.sx,
                backgroundColor: `${props.color}`,
            }}
        >
            <TagText color={`${props.textColor}`}>{props.children}</TagText>
        </span>
    );
};

export default Tag;

const style: any = {
    padding: '2px 8px 2px 8px',
    borderRadius: '2px',
};
