import { useTranslation } from 'react-i18next';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export interface RowOptionsProps extends IconButtonProps {}

const RowOptions = (props: RowOptionsProps) => {
    const { t } = useTranslation();
    return (
        <IconButton {...props} aria-label={t(`edit`)} >
            <MoreVertIcon className='VisualOffering-more-details'/>
        </IconButton>
    );
};

export default RowOptions;
