import axios, { AxiosResponse } from 'axios';
import { API_URL } from '../config';
import Cookies from 'js-cookie';
import { AUTH_COOKIE_NAME } from 'config';
import { MoodboardState } from 'features/moodboard/moodboardState';
import qs from 'qs';
import { CookieService } from './cookie/CookieService';

// TODO: transform these service functions into a class

// SPACIAL SOLUTION services

export const createSpacialSolution = async (
    name: string
): Promise<SpacialSolution> => {
    try {
        const response: AxiosResponse<CRUDSpacialSolutionResponse> =
            await axios.post(
                `${API_URL}/api/spacialSolutions`,
                { name },
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`,
                    },
                }
            );
        return response.data.doc;
    } catch (error) {
        throw new Error('Spacial solution creation failed');
    }
};

export const updateSpacialSolution = async (
    id: string,
    updateData: Partial<SpacialSolution>
): Promise<SpacialSolution> => {
    try {
        const response: AxiosResponse<CRUDSpacialSolutionResponse> =
            await axios.patch(
                `${API_URL}/api/spacialSolutions/${id}`,
                updateData,
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`,
                    },
                    params: { depth: 3 },
                }
            );
        return response.data.doc;
    } catch (error) {
        throw new Error('Spacial solution update failed');
    }
};

export const updateSpacialSolutionRooms = async (
    id: string,
    updateData: string[]
): Promise<SpacialSolution> => {
    try {
        const response: AxiosResponse<CRUDSpacialSolutionResponse> =
            await axios.patch(
                `${API_URL}/api/spacialSolutions/${id}`,
                { rooms: updateData },
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`,
                    },
                }
            );
        return response.data.doc;
    } catch (error) {
        throw new Error('Spacial solution update failed');
    }
};

// ROOM services

export const createRoom = async (name: string): Promise<Room> => {
    try {
        const response: AxiosResponse<CRUDRoomResponse> = await axios.post(
            `${API_URL}/api/rooms`,
            { name },
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                },
            }
        );
        return response.data.doc;
    } catch (error) {
        throw new Error('Room creation failed');
    }
};

export const deleteRoom = async (id: string): Promise<Room> => {
    try {
        const response: AxiosResponse<Room> = await axios.delete(
            `${API_URL}/api/rooms/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw new Error('Room deletion failed');
    }
};

export const updateRoom = async (
    id: string,
    updateData: Partial<Room>
): Promise<Room> => {
    try {
        const response: AxiosResponse<CRUDRoomResponse> = await axios.patch(
            `${API_URL}/api/rooms/${id}`,
            updateData,
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                },
            }
        );
        return response.data.doc;
    } catch (error) {
        throw new Error('Room update failed');
    }
};

export const updateRoomVariations = async (
    id: string,
    updateData: string[]
): Promise<Room> => {
    try {
        const response: AxiosResponse<CRUDRoomResponse> = await axios.patch(
            `${API_URL}/api/rooms/${id}`,
            { variations: updateData },
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                },
            }
        );
        return response.data.doc;
    } catch (error) {
        throw new Error('Room update failed');
    }
};

// VARIATION services

export const createVariation = async (
    name: string,
    isTemplate: boolean,
    data: Partial<MoodboardState>,
    products: string[] // PIM product ids
): Promise<Variation> => {
    try {
        const response: AxiosResponse<CRUDVariationResponse> = await axios.post(
            `${API_URL}/api/variations`,
            {
                name,
                isTemplate,
                data: JSON.stringify(data),
                ...(products?.length && { products }),
            },
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                },
            }
        );
        return response.data.doc;
    } catch (error) {
        throw new Error('Variation creation failed');
    }
};

// update variation, soft delete variation, or adding snapshots to variation
export const updateVariation = async (
    id: string,
    updateData:
        | Partial<Variation>
        | { deleted: boolean }
        | { snapshots: string[] }
): Promise<Variation> => {
    try {
        const response: AxiosResponse<CRUDVariationResponse> =
            await axios.patch(`${API_URL}/api/variations/${id}`, updateData, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                },
            });

        return response.data.doc;
    } catch (error) {
        throw new Error('Variation update failed');
    }
};

// [templates] get variations where isTemplate equals true
export const getTemplatesByLimit = async (
    limit: number
): Promise<CRUDVariationsResponse> => {
    // Authorization
    const config = {
        headers: {
            Authorization: `Bearer ${CookieService.getCookieByName(
                AUTH_COOKIE_NAME
            )}`,
        },
    };

    try {
        const where = { isTemplate: { equals: true } };
        const params = {
            limit,
            where,
        };

        const queryString = qs.stringify(params, { encode: false });

        const response: AxiosResponse<CRUDVariationsResponse> = await axios.get(
            `${API_URL}/api/variations?${queryString}`,
            config
        );

        return response.data;
    } catch (error) {
        throw new Error('Templates fetch failed');
    }
};
