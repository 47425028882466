// https://stackoverflow.com/questions/40227401/const-enum-in-typescript
export enum VispakOfferStatusConst {
    IN_PROGRESS = 'inProgress',
    PUBLIC = 'public',
    ARCHIVED = 'archived',
    // Only offer has 'status = deleted | public | ..'
    // children have 'deleted = true | false'
    DELETED = 'deleted',
}

export enum VispakRoomStatusConst {
    IN_PROGRESS = 'inProgress',
    PUBLIC = 'public',
}

// Associated with MUI theme keys
export enum VispakOfferStatusTagConst {
    IN_PROGRESS = 'inProgress',
    PUBLISHED = 'published',
    ARCHIVED = 'archived',
    DEFAULT = 'default',
}

export type VispakOfferStatusTag =
    | VispakOfferStatusTagConst.IN_PROGRESS
    | VispakOfferStatusTagConst.PUBLISHED
    | VispakOfferStatusTagConst.ARCHIVED;

export type VispakOfferStatus =
    | VispakOfferStatusConst.IN_PROGRESS
    | VispakOfferStatusConst.PUBLIC
    | VispakOfferStatusConst.ARCHIVED
    | VispakOfferStatusConst.DELETED;

export type VispakRoomStatus =
    | VispakRoomStatusConst.IN_PROGRESS
    | VispakRoomStatusConst.PUBLIC;
