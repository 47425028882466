import Cookies from 'js-cookie';

export class CookieService {
    public static removeCookieByName(name: string): void {
        const authCookie = Cookies.get(name);

        if (authCookie) {
            Cookies.remove(name);
        } else {
            console.error("Cookie not found! Can't remove.");
        }
    }

    public static getCookieByName(name: string): string | null {
        const cookieValue = Cookies.get(name) || null;
        if (cookieValue) {
            return cookieValue;
        } else {
            // console.warn('Warning: no cookie found.');
            return null;
        }
    }

    public static setCookieByName(
        name: string,
        value: string,
        expiry: Cookies.CookieAttributes | undefined = {}
    ): string | null {
        return Cookies.set(name, value, expiry) || null;
    }
}
