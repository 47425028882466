import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// ui
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from 'components/base/Button';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

// store
import {
    selectProjects,
    selectSelectedProject,
    selectIsLoading,
    updateSpacialSolutionThunk,
} from 'features/projects/state/projectState';

// types
import { AppDispatch } from 'store';

export interface DeleteSolutionDialogProps {
    solutionId: string;
    isDeleteSolutionDialogOpen: boolean;
    onCloseDeleteSolutionDialog: () => void;
}

const DeleteSolutionDialog = (props: DeleteSolutionDialogProps) => {
    // hooks

    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();

    // properties

    const isLoadingProjectState = useSelector(selectIsLoading);
    const projects = useSelector(selectProjects);
    const selectedProject = useSelector(selectSelectedProject);
    const foundModifiableProject = projects.docs.find(
        (project) => project.id === selectedProject?.id
    );
    const foundDeletableSolution =
        foundModifiableProject?.spacialSolutions?.find(
            (solution: SpacialSolution) => solution.id === props.solutionId
        );

    // features

    const handleDeleteSolution = async () => {
        const updateData: Partial<SpacialSolution> = { deleted: true };
        await dispatch(
            updateSpacialSolutionThunk({ id: props.solutionId, updateData })
        ).catch((error) => {
            console.error('Error deleting solution in UI:', error);
        });

        props.onCloseDeleteSolutionDialog();
    };

    return (
        <Dialog
            open={props.isDeleteSolutionDialogOpen}
            onClose={props.onCloseDeleteSolutionDialog}
        >
            {/* Right top close button */}
            <IconButton
                aria-label="close"
                onClick={props.onCloseDeleteSolutionDialog}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.main,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Title */}
            <Box component="div" sx={styles.titleBox}>
                <DialogTitle>
                    {t(`component.deleteSolutionDialog.title`)}
                </DialogTitle>
            </Box>

            {/* Content */}
            <DialogContent>
                <Box component={'div'}>{foundDeletableSolution?.name}</Box>
            </DialogContent>

            {/* Actions */}
            <DialogActions sx={styles.dialogActions}>
                <Button
                    sx={styles.buttonCancel}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => props.onCloseDeleteSolutionDialog()}
                >
                    {t(`cancel`)}
                </Button>
                <Button
                    sx={styles.buttonSubmit}
                    variant={'contained'}
                    type="submit"
                    onClick={handleDeleteSolution}
                    disabled={isLoadingProjectState}
                >
                    {isLoadingProjectState ? (
                        <CircularProgress size={20} />
                    ) : (
                        t(`submit`)
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteSolutionDialog;

const styles = {
    titleBox: {
        marginRight: '2rem',
    },
    dialogActions: {
        justifyContent: 'flex-end',
        gap: '0.4rem',
        margin: '0px 0.5rem 0.5rem 0px',
    },
    buttonCancel: {
        height: '2.8rem',
    },
    buttonSubmit: {
        height: '2.8rem',
    },
};
