import { useTranslation } from 'react-i18next';

const ErrorFallback = ({ error }: { error: Error }) => {
    const { t } = useTranslation();
    return (
        <div style={{ padding: '20px' }}>
            <h2>{t('component.error.moodboardRenderingError')}</h2>
            <p>{t('component.error.contactSupport')}</p>
            <h3>{t('component.error.errorDetails')}:</h3>
            <pre>{error.message}</pre>
        </div>
    );
};

export default ErrorFallback;
