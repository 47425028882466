import { useTheme } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/material/styles';

export interface BodyProps {
    children?: any;
    bold?: boolean;
    sx?: SxProps<Theme>;
}

const Body = (props: BodyProps) => {
    const theme = useTheme();

    return (
        <span
            style={{
                ...style,
                ...props.sx,
                color: `${theme.extra.text.color.primary}`,
                fontWeight: !props.bold ? '400' : '700',
            }}
        >
            {props.children}
        </span>
    );
};

export default Body;

const style: any = {
    fontSize: '16px',
    lineHeight: '26px',
    textAlign: 'left',
};
