import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { appPath } from 'routes';

// mui
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

// ui
import Button from 'components/base/Button';

// store
import {
    deleteProject,
    selectProjects,
    selectIsLoading,
} from 'features/projects/state/projectState';

// types
import { AppDispatch } from 'store';

export interface ModifyProjectDialogProps {
    projectId: string;
    isDeleteProjectDialogOpen: boolean;
    onCloseDeleteProjectDialog: () => void;
}

const DeleteProjectDialog = (props: ModifyProjectDialogProps) => {
    // hooks

    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    // local state

    const [projectName, setProjectName] = useState('');

    // properties

    const isLoadingProjectState = useSelector(selectIsLoading);
    const projects = useSelector(selectProjects);
    const foundModifiableProject = projects.docs.find(
        (project) => project.id === props.projectId
    );

    // features

    const handleDeleteProject = async () => {
        const isOffersPath = location?.pathname === appPath.projects;
        const isSingleOfferPath = location?.pathname.includes(
            appPath.project.replace(':projectId', props.projectId)
        );

        if (isOffersPath) {
            await dispatch(deleteProject({ id: props.projectId })).catch(
                (error) => {
                    console.error(
                        'Error deleting Visual Offering in UI:',
                        error
                    );
                }
            );
            props.onCloseDeleteProjectDialog();
        }

        // await for deletion to complete before new action
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        if (isSingleOfferPath) {
            await dispatch(deleteProject({ id: props.projectId })).catch(
                (error) => {
                    console.error(
                        'Error deleting Visual Offering in UI:',
                        error
                    );
                }
            );
            props.onCloseDeleteProjectDialog();
            navigate(appPath.projects);
        }
    };

    // side effects

    useEffect(() => {
        if (foundModifiableProject) {
            setProjectName(foundModifiableProject.name);
        }
    }, [foundModifiableProject]);

    return (
        <Dialog
            open={props.isDeleteProjectDialogOpen}
            onClose={props.onCloseDeleteProjectDialog}
        >
            {/* Right top close button */}
            <IconButton
                aria-label="close"
                onClick={props.onCloseDeleteProjectDialog}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.main,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Title */}
            <Box component="div" sx={styles.titleBox}>
                <DialogTitle>
                    {t(`component.deleteProjectDialog.title`)}
                </DialogTitle>
            </Box>

            {/* Content */}
            <DialogContent>
                <Box component={'div'}>{projectName}</Box>
            </DialogContent>

            {/* Actions */}
            <DialogActions sx={styles.dialogActions}>
                <Button
                    sx={styles.buttonCancel}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => props.onCloseDeleteProjectDialog()}
                >
                    {t(`cancel`)}
                </Button>
                <Button
                    sx={styles.buttonSubmit}
                    variant={'contained'}
                    type="submit"
                    onClick={handleDeleteProject}
                    disabled={
                        projectName === '' ||
                        !foundModifiableProject ||
                        isLoadingProjectState
                    }
                >
                    {isLoadingProjectState ? (
                        <CircularProgress size={20} />
                    ) : (
                        t(`submit`)
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteProjectDialog;

const styles = {
    titleBox: {
        marginRight: '2rem',
    },
    dialogActions: {
        justifyContent: 'flex-end',
        gap: '0.4rem',
        margin: '0px 0.5rem 0.5rem 0px',
    },
    buttonCancel: {
        height: '2.8rem',
    },
    buttonSubmit: {
        height: '2.8rem',
    },
};
