import { useTheme } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/material/styles';

export interface BodyProps {
    children?: any;
    color?: string;
    sx?: SxProps<Theme>;
}

const TagText = (props: BodyProps) => {
    const theme = useTheme();

    return (
        <span
            style={{
                ...style,
                ...props.sx,
                alignSelf: "center",
                color: `${props.color ? props.color : theme.extra.text.color.primary}`,
            }}
        >
            {props.children}
        </span>
    );
};

export default TagText;

const style: any = {
    fontSize: '11px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    textAlign: 'left',
};
