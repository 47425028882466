import React from 'react';
import { PLACEHOLDER_IMAGE_URL } from 'config';

// mui
import { Box, Typography, Checkbox } from '@mui/material';

// ui
import { colors } from 'theming/colors';

// types
import { CategorizationTypeConst, ProductFeatureName } from './ProductListView';

interface ProductItemDetailsProps {
    product: PIMProduct;
    index: number;
    array: PIMProduct[];
    translate: (key: string) => string;
    categorizationType?: {
        id:
            | CategorizationTypeConst.BY_ROOM
            | CategorizationTypeConst.BY_PRODUCT;
        name: string;
    };
}

const ProductItemDetails = ({
    product,
    index,
    array,
    translate,
    categorizationType,
}: ProductItemDetailsProps) => {
    // go through all image groups and get the first one that exists
    const productImageUrl =
        product?.designImageSlider?.[0]?.designImage?.sizes?.thumbnail?.url ||
        product?.designImageSlider?.[0]?.designImage?.sizes?.medium?.url ||
        product?.designImageSlider?.[0]?.designImage?.url ||
        product?.interiorImageSlider?.[0]?.interiorImage?.sizes?.thumbnail
            ?.url ||
        product?.interiorImageSlider?.[0]?.interiorImage?.sizes?.medium?.url ||
        product?.interiorImageSlider?.[0]?.interiorImage?.url ||
        product?.referenceAlbumSlider?.[0]?.referenceAlbums?.sizes?.thumbnail
            ?.url ||
        product?.referenceAlbumSlider?.[0]?.referenceAlbums?.sizes?.medium
            ?.url ||
        product?.referenceAlbumSlider?.[0]?.referenceAlbums?.url ||
        '';

    const isCategorizationByRoom =
        categorizationType?.id === CategorizationTypeConst.BY_ROOM;

    const isCategorizationByProduct =
        categorizationType?.id === CategorizationTypeConst.BY_PRODUCT;

    // Product details

    const productName = product?.name;
    const productCollection = product?.category?.value?.name;
    const productSubcategory = product?.subGroup?.value?.name;
    const productDimensions = product?.featuresArray?.find((feature) =>
        feature?.featureName
            ?.toLowerCase()
            ?.includes(ProductFeatureName.PLATE_FORMAT)
    )?.featureValue;
    const productThickness = product?.featuresArray?.find((feature) =>
        feature?.featureName
            ?.toLowerCase()
            ?.includes(ProductFeatureName.THICKNESS)
    )?.featureValue;
    const productMaterialRefinement = product?.featuresArray?.find((feature) =>
        feature?.featureName
            ?.toLowerCase()
            ?.includes(ProductFeatureName.REFINEMENT)
    )?.featureValue;
    const productCarbonFootprint = product?.featuresArray?.find((feature) =>
        feature?.featureName?.toLowerCase()?.includes(ProductFeatureName.CO2)
    )?.featureValue;
    const productProductCode = product?.externalId;
    const productManufacturer = product?.category?.value?.factory?.value?.name;
    const productManufacturerUrl = product?.category?.value?.urlOnFactorySite;
    const productUsP = product?.category?.value?.uniqueProperties;
    const productPrice =
        product?.price &&
        Number.parseFloat(product.price.toString()).toFixed(2);
    const productDescription = product?.category?.value?.desc;

    return (
        <Box
            component="div"
            key={product.id}
            sx={{
                ...styles.productItemDetailsBox,
                borderBottom:
                    index === array.length - 1
                        ? 'none'
                        : '1px solid ' + colors.borderGrey,
            }}
        >
            {/* Product item details left side */}
            <Box component="div" sx={styles.productItemDetails}>
                {/* Manufacturer */}
                {isCategorizationByProduct && productManufacturer && (
                    <Box component="div" sx={styles.productItemDetailsRow}>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemLeft}
                        >
                            <Typography sx={styles.productItemDetailName}>
                                {translate('views.productList.manufacturer')}
                            </Typography>
                        </Box>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemRight}
                        >
                            <Typography
                                sx={{
                                    ...styles.productItemDetailValue,
                                    ...styles.productItemBold,
                                }}
                            >
                                {productManufacturer}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* Product name */}
                <Box component="div" sx={styles.productItemDetailsRow}>
                    <Box
                        component="div"
                        sx={styles.productItemDetailsRowItemLeft}
                    >
                        <Typography sx={styles.productItemDetailName}>
                            {translate('views.productList.productName')}
                        </Typography>
                    </Box>
                    <Box
                        component="div"
                        sx={styles.productItemDetailsRowItemRight}
                    >
                        <Typography
                            sx={{
                                ...styles.productItemDetailValue,
                                ...(isCategorizationByRoom &&
                                    styles.productItemBold),
                            }}
                        >
                            {productName}
                        </Typography>
                    </Box>
                </Box>

                {/* Product collection - PIM category */}
                <Box component="div" sx={styles.productItemDetailsRow}>
                    <Box
                        component="div"
                        sx={styles.productItemDetailsRowItemLeft}
                    >
                        <Typography sx={styles.productItemDetailName}>
                            {translate('views.productList.collection')}
                        </Typography>
                    </Box>
                    <Box
                        component="div"
                        sx={styles.productItemDetailsRowItemRight}
                    >
                        <Typography sx={styles.productItemDetailValue}>
                            {productCollection}
                        </Typography>
                    </Box>
                </Box>

                {/* Product subcategory - PIM subgroup */}
                <Box component="div" sx={styles.productItemDetailsRow}>
                    <Box
                        component="div"
                        sx={styles.productItemDetailsRowItemLeft}
                    >
                        <Typography sx={styles.productItemDetailName}>
                            {translate('views.productList.subcategory')}
                        </Typography>
                    </Box>
                    <Box
                        component="div"
                        sx={styles.productItemDetailsRowItemRight}
                    >
                        <Typography sx={styles.productItemDetailValue}>
                            {productSubcategory}
                        </Typography>
                    </Box>
                </Box>

                {/* Product dimensions - format */}
                {productDimensions && (
                    <Box component="div" sx={styles.productItemDetailsRow}>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemLeft}
                        >
                            <Typography sx={styles.productItemDetailName}>
                                {translate('views.productList.dimensionFormat')}
                            </Typography>
                        </Box>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemRight}
                        >
                            <Typography sx={styles.productItemDetailValue}>
                                {productDimensions} {'(cm)'}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* Product thickness */}
                {productThickness && (
                    <Box component="div" sx={styles.productItemDetailsRow}>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemLeft}
                        >
                            <Typography sx={styles.productItemDetailName}>
                                {translate('views.productList.thickness')}
                            </Typography>
                        </Box>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemRight}
                        >
                            <Typography sx={styles.productItemDetailValue}>
                                {productThickness}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* Material refinement - PIM pinnaviimistlus */}
                {productMaterialRefinement && (
                    <Box component="div" sx={styles.productItemDetailsRow}>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemLeft}
                        >
                            <Typography sx={styles.productItemDetailName}>
                                {translate('views.productList.refinement')}
                            </Typography>
                        </Box>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemRight}
                        >
                            <Typography sx={styles.productItemDetailValue}>
                                {productMaterialRefinement}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* Carbon footprint - PIM CO2 jalajälg */}
                {productCarbonFootprint && (
                    <Box component="div" sx={styles.productItemDetailsRow}>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemLeft}
                        >
                            <Typography sx={styles.productItemDetailName}>
                                {translate('views.productList.carbonFootprint')}
                            </Typography>
                        </Box>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemRight}
                        >
                            <Typography sx={styles.productItemDetailValue}>
                                {productCarbonFootprint}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* Product code - PIM external ID */}
                {productProductCode && (
                    <Box component="div" sx={styles.productItemDetailsRow}>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemLeft}
                        >
                            <Typography sx={styles.productItemDetailName}>
                                {translate('views.productList.productCode')}
                            </Typography>
                        </Box>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemRight}
                        >
                            <Typography sx={styles.productItemDetailValue}>
                                {productProductCode}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* Manufacturer */}
                {isCategorizationByRoom && productManufacturer && (
                    <Box component="div" sx={styles.productItemDetailsRow}>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemLeft}
                        >
                            <Typography sx={styles.productItemDetailName}>
                                {translate('views.productList.manufacturer')}
                            </Typography>
                        </Box>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemRight}
                        >
                            <Typography sx={styles.productItemDetailValue}>
                                {productManufacturer}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* Manufacturer URL */}
                {productManufacturerUrl && (
                    <Box component="div" sx={styles.productItemDetailsRow}>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemLeft}
                        >
                            <Typography sx={styles.productItemDetailName}>
                                {translate('views.productList.manufacturerUrl')}
                            </Typography>
                        </Box>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemRight}
                        >
                            {productManufacturerUrl && (
                                <Typography sx={styles.productItemDetailValue}>
                                    <a
                                        href={productManufacturerUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            color: '#1976d2',
                                            textDecoration: 'none',
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {productManufacturerUrl}
                                    </a>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                )}

                {/* USP - PIM unique properties */}
                {productUsP && (
                    <Box component="div" sx={styles.productItemDetailsRow}>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemLeft}
                        >
                            <Typography sx={styles.productItemDetailName}>
                                {translate('views.productList.usp')}
                            </Typography>
                        </Box>
                        <Box
                            component="div"
                            sx={{
                                ...styles.productItemDetailsRowItemRight,
                            }}
                        >
                            <Typography sx={styles.productItemDetailValue}>
                                {productUsP}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* Price - €/m2 */}
                {productPrice && (
                    <Box component="div" sx={styles.productItemDetailsRow}>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemLeft}
                        >
                            <Typography sx={styles.productItemDetailName}>
                                {translate('views.productList.price')}
                            </Typography>
                        </Box>
                        <Box
                            component="div"
                            sx={styles.productItemDetailsRowItemRight}
                        >
                            <Typography sx={styles.productItemDetailValue}>
                                {productPrice} {'(€/m2)'}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* Product price separate */}
                <Box component="div" sx={styles.productItemPriceBox}>
                    <Typography sx={styles.productItemPriceValue}>
                        {productPrice} {'€'}
                    </Typography>
                    <Typography sx={styles.productItemPriceUnit}>
                        {'/m2'}
                    </Typography>
                </Box>

                {/* Product item description */}
                {productDescription && (
                    <Box component="div" sx={styles.productItemDescriptionBox}>
                        <Box
                            component="div"
                            sx={styles.productItemDescriptionTitle}
                        >
                            {translate('views.productList.description')}
                        </Box>
                        <Box
                            component="div"
                            sx={styles.productItemDescriptionText}
                        >
                            {product?.category?.value?.desc}
                        </Box>

                        {/* Product item checkbox for ordering demo product */}
                        <Box component="div" sx={styles.productItemCheckbox}>
                            <Checkbox
                                sx={{
                                    marginLeft: '-10px',
                                }}
                            />
                            <Typography>
                                {translate(
                                    'views.productList.requestDemoProductCheckbox'
                                )}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>

            {/* Product item details right side - image */}
            <Box component="div" sx={styles.productItemImageBox}>
                <img
                    src={productImageUrl || PLACEHOLDER_IMAGE_URL}
                    alt={'thumbnail'}
                    style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '2%',
                        objectFit: 'cover',
                    }}
                />
            </Box>
        </Box>
    );
};

export default ProductItemDetails;

const styles = {
    // -- Product item section details --
    productItemDetailsBox: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        margin: '1rem 0',
    },
    // Left side of product details before the product image
    productItemDetails: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',

        flexGrow: '1',
    },
    productItemDetailsRow: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',

        width: '100%',
        padding: '0.1rem 0',
    },
    productItemDetailsRowItemLeft: {
        flexBasis: { xs: '40%', md: '30%', lg: '30%', xl: '20%' },
    },
    productItemDetailsRowItemRight: {
        flexBasis: { xs: '60%', md: '70%', lg: '70%', xl: '80%' },
    },
    productItemDetailName: {
        fontSize: '1rem',
        textTransform: 'uppercase',
    },
    productItemDetailValue: {
        lineHeight: '1.5',
        fontSize: '1rem',
        whiteSpace: 'pre-line',
        maxWidth: { xs: '20rem', md: '25rem', lg: '30rem', xl: '50rem' },
        wordBreak: 'break-word',
    },
    productItemBold: {
        fontWeight: '600',
    },
    // Right side image of the product details
    productItemImageBox: {
        width: '20rem',
        height: '20rem',
        margin: '0rem 0 2rem 0',
    },
    // Product item price
    productItemPriceBox: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'flex-start',

        margin: '1rem 0 0 0',
    },
    productItemPriceValue: {
        fontSize: '1.5rem',
        fontWeight: '600',
        color: colors.orange,
    },
    productItemPriceUnit: {
        fontSize: '1rem',
        marginLeft: '0.5rem',
        color: colors.fGrey,
    },

    // -- Product item section description (footer) --
    productItemDescriptionBox: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',

        gap: '0.5rem',
        margin: '1rem 0',
        paddingBottom: '1rem',
        maxWidth: { xs: '20rem', md: '20rem', lg: '40rem', xl: '50rem' },
    },
    productItemDescriptionTitle: {
        fontSize: '1rem',
        fontWeight: '600',
    },
    productItemDescriptionText: {
        fontSize: '1rem',
        lineHeight: '1.5',

        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
    },

    // -- Product item checkbox --
    productItemCheckbox: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
};
